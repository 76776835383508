import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import "../Footer.scss";

function SocialLinks({ heading }) {
  const { t } = useTranslation();
  const { socialMedia } = useSelector((state) => state.menu);
  return (
    <Card.Body>
      <Card.Title as="h4" className="footer-heading">
        {t(heading)}
      </Card.Title>
      <Card.Body className="social-link-wrapper">
        {socialMedia?.map((item, key) => (
          <React.Fragment key={key}>
            <a
              href={item.customUrl}
              className="social-link m-1"
              title={t(item.title)}
              key={key}
              target={item.urlTarget}>
              <FontAwesomeIcon
                icon={[
                  item.socialMediaIcon.split(" ")[0],
                  item.socialMediaIcon.split(" ")[1],
                ]}
                className="social-icon"
              />
            </a>
          </React.Fragment>
        ))}
      </Card.Body>
    </Card.Body>
  );
}

SocialLinks.propTypes = {
  heading: PropTypes.string.isRequired,
  linkItem: PropTypes.array,
};
export default SocialLinks;

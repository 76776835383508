import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./authReducer";
import registrationReducer from "./registrationReducer";
import { alert } from "./alertReducer";
import updateMyPassword from "./updateMyPasswordReducer";
import updateMyProfile from "./updateMyProfileReducer";
import getCurrentUser from "./getCurrentUserReducer";
import emailPreferences from "./emailPreferencesReducer";
import investorProfile from "./investorProfileReducer";
import resetPassword from "./resetPasswordReducer";
import siteSettings from "./siteSettingsReducer";
import sliders from "./sliderReducer";
import languages from "./languagesReducer";
import recaptcha from "./recaptchaReducer";
import menu from "./menu/menuReducer";
import campaign from "./createCampaign/createCampaignReducer";
import contentPage from "./contentPage/contentPageReducer";
import meta from "./meta/metaReducer";
import userProfile from "./userProfile/userProfileReducer";
import comment from "./comments/commentsReducer";
import activateAccount from "./activateAccount/activateAccountReducer";
import revenue from "./revenue/revenueReducer";
import myInvestments from "./investments/investmentsReducer";
import campaignDashboard from "./campaignDashboard/campaignDashboardReducer";
import payment from "./payment/paymentReducer";
import wallet from "./wallet/walletReducer";
import taxonomy from "./taxonomy";
import mangoPay from "./mangopay/mangoPayReducer";

const rootPersistConfig = {
	key: "root",
	storage: storage,
};

const rootReducer = combineReducers({
	auth: authReducer,
	userRegistration: registrationReducer,
	alert,
	updateMyPassword,
	updateMyProfile: updateMyProfile,
	getCurrentUser: getCurrentUser,
	emailPreferences: emailPreferences,
	investorProfile: investorProfile,
	resetPassword: resetPassword,
	siteSettings,
	sliders,
	languages,
	recaptcha,
	menu,
	campaign,
	contentPage,
	meta,
	userProfile,
	comment,
	activateAccount,
	revenue,
	myInvestments,
	campaignDashboard,
	payment,
	wallet,
	taxonomy,
	mangoPay,
});

export default persistReducer(rootPersistConfig, rootReducer);

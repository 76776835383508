import axios from "../../config/AxiosConfig";

export const getUserProfileFetcher = url =>
	axios.get(url).then(response => response.data.data.data);

export const getUserProfileInvestmentsFetcher = url =>
	axios.get(url).then(response => response.data.data.data);
	
export const getUserProfileCampaignsFetcher = url =>
	axios.get(url).then(response => response.data.data.data);

import { put, call } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
	USER_EMAIL_PREFERNECES_API, // remove this
	USER_EMAIL_PREFERNECES_API_UPDATE // remove this
} from "../actions/apiCollections";
import {
	getUserEmailPreferencesStart, // remove this
	getUserEmailPreferencesFail, // remove this
	getUserEmailPreferencesSuccess, // remove this
	createEmailPreferencesSuccess, // remove this
	createEmailPreferencesFail, // remove this
	updateEmailPreferencesSuccess, // remove this
	updateEmailPreferencesFail, // remove this
	isSpinning
} from "../actions/index";
import { getLanguageCode } from "../../helpers/helpers";

// remove this
export function* getUserEmailPreferencesSaga() {
	yield put(getUserEmailPreferencesStart());
	yield put(isSpinning(true));
	try {
		const response = yield axios.get(
			`${USER_EMAIL_PREFERNECES_API}?lng=${getLanguageCode()}`
		);
		yield put(getUserEmailPreferencesSuccess(response.data.data.data));
		yield put(isSpinning(false));
	} catch (error) {
		yield put(getUserEmailPreferencesFail(error?.response?.data));
	}
}
// remove this
export function* createEmailPreferencesSaga(action) {
	yield put(getUserEmailPreferencesStart());
	yield put(isSpinning(true));
	try {
		const response = axios.post(
			`${USER_EMAIL_PREFERNECES_API}?lng=${getLanguageCode()}`,
			action.userData
		);
		yield put(createEmailPreferencesSuccess(response.data));
		yield call(getUserEmailPreferencesSaga);
		yield put(isSpinning(false));
	} catch (error) {
		yield put(createEmailPreferencesFail(error));
	}
}
// remove this
export function* updateEmailPreferencesSaga(action) {
	try {
		const response = yield axios.patch(
			`${USER_EMAIL_PREFERNECES_API_UPDATE}${action.id}?lng=${getLanguageCode()}`,
			action.userData
		);
		yield put(updateEmailPreferencesSuccess(response.data.data.data));
	} catch (error) {
		yield put(updateEmailPreferencesFail(error.response.status));
	}
}

import { put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
	resetFail,
	resetStart,
	resetSuccess,
	forgotPasswordFail,
	forgotPasswordStart,
	forgotPasswordSuccess,
	isSpinning
} from "../actions/resetPasswordAction";
import { RESET_PASSWORD, FORGOT_PASSWORD } from "../actions/apiCollections";
import { getLanguageCode } from "../../helpers/helpers";

export function* resetPasswordSaga(action) {
	yield put(resetStart());
	yield put(isSpinning(true));
	try {
		const response = yield axios.post(RESET_PASSWORD, action.email, {
			params: { lng: getLanguageCode() }
		});
		yield put(resetSuccess(response.data.message));
		yield put(isSpinning(false));
		yield put(forgotPasswordStart());
	} catch (error) {
		yield put(resetFail(error.response.data.message));
		yield put(isSpinning(false));
	}
}

export function* forgotPasswordSaga(action) {
	yield put(forgotPasswordStart());
	yield put(isSpinning(true));
	try {
		const response = yield axios.patch(
			`${FORGOT_PASSWORD}${action.passwordData.token}?lng=${getLanguageCode()}`,
			action.passwordData
		);
		yield put(forgotPasswordSuccess(response.data.status));
		yield put(isSpinning(false));
	} catch (error) {
		yield put(forgotPasswordFail(error.response.data.message));
		yield put(isSpinning(false));
	}
}

import * as actionTypes from "./actionTypes";

export const getTaxonomy = () => {
	return {
		type: actionTypes.GET_TAXONOMY
	};
};

export const getTaxonomySuccess = response => {
	return {
		type: actionTypes.GET_TAXONOMY_SUCCESS,
		response
	};
};

export const getTaxonomyFail = response => {
	return {
		type: actionTypes.GET_TAXONOMY_FAIL,
		response
	};
};

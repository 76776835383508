/**
 * Commons
 */

export const CAMPAIGNS_START = "CAMPAIGNS_START";
export const CLEAR_EQUITY = "CLEAR_EQUITY";
export const CAMPAIGNS_FAIL = "CAMPAIGNS_FAIL";
export const USER_CAMPAIGNS_LIMIT_FAIL = "USER_CAMPAIGNS_LIMIT_FAIL";
export const CLEAR_USER_CAMPAIGNS_LIMIT_MESSAGE =
	"CLEAR_USER_CAMPAIGNS_LIMIT_MESSAGE";
export const CREATE_CAMPAIGN_TYPE = "CREATE_CAMPAIGN_TYPE";
export const CREATE_CAMPAIGN_TYPE_SUCCESS = "CREATE_CAMPAIGN_TYPE_SUCCESS";
export const GET_TYPE = "GET_TYPE";
export const GET_TYPE_SUCCESS = "GET_TYPE_SUCCESS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_INDUSTRIES = "GET_INDUSTRIES";
export const GET_INDUSTRIES_SUCCESS = "GET_INDUSTRIES_SUCCESS";
export const GET_TEAM_MEMBER_TYPE = "GET_TEAM_MEMBER_TYPE";
export const GET_TEAM_MEMBER_TYPE_SUCCESS = "GET_TEAM_MEMBER_TYPE_SUCCESS";
export const SUCCESS_COMMON = "SUCCESS_COMMON";
export const IS_LOADING = "[CAMPAIGN] Loading";
export const UPLOAD_CONTRACT_COPY = "[Campaign] Upload Contract Copy";
export const UPLOAD_CONTRACT_COPY_SUCCESS =
	"[Campaign] Upload Contract Copy Success";
export const UPLOAD_CONTRACT_COPY_FAIL = "[Campaign] Upload Contract Copy Fail";
export const GET_FOLLOWERS_CAMPAIGN = "[Campaign] Get Users who saved campaign";
export const GET_FOLLOWERS_CAMPAIGN_SUCCESS =
	"[Campaign] Get Users who saved campaign success";
export const GET_USER_SAVE_CAMPAIGNID_SUCCESS =
	"[Campaign] Get user save campaignId success";
/**
 * Team Member
 */
export const CLEAR_TEAM_MEMBER = "CLEAR_TEAM_MEMBER";
export const CREATE_TEAM_MEMBER = "CREATE_TEAM_MEMBER";
export const CREATE_TEAM_MEMBER_SUCCESS = "CREATE_TEAM_MEMBER_SUCCESS";
export const GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS";
export const GET_TEAM_MEMBERS_SUCCESS = "GET_TEAM_MEMBERS_SUCCESS";
export const DELETE_TEAM_MEMBER = "DELETE_TEAM_MEMBER";
export const GET_TEAM_MEMBER = "GET_TEAM_MEMBER";
export const GET_TEAM_MEMBER_SUCCESS = "GET_TEAM_MEMBER_SUCCESS";
export const UPDATE_TEAM_MEMBER = "UPDATE_TEAM_MEMBER";
export const RESEND_EMAIL = "RESEND_EMAIL";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";

/**
 * Basic Information Company
 */

export const CREATE_BASIC_COMPANY_INFORMATION =
	"CREATE_BASIC_COMPANY_INFORMATION";
export const GET_BASIC_COMPANY_INFORMATION = "GET_BASIC_COMPANY_INFORMATION";
export const GET_BASIC_COMPANY_INFORMATION_SUCCESS =
	"GET_BASIC_COMPANY_INFORMATION_SUCCESS";
export const UPDATE_COMPANY_INFORMATION = "UPDATE_COMPANY_INFORMATION";

/**
 * Fund Raising Detail
 */

export const GET_CAMPAIGN_STAGE = "GET_CAMPAIGN_STAGE";
export const GET_CAMPAIGN_STAGE_SUCCESS = "GET_CAMPAIGN_STAGE_SUCCESS";
export const GET_INVESTING_ROUND = "GET_INVESTING_ROUND";
export const GET_INVESTING_ROUND_SUCCESS = "GET_INVESTING_ROUND_SUCCESS";
export const GET_INVESTOR = "GET_INVESTOR";
export const GET_INVESTOR_SUCCESS = "GET_INVESTOR_SUCCESS";
export const CREATE_FUNDRAISING = "CREATE_FUNDRAISING";
export const UPDATE_FUNDRAISING = "UPDATE_FUNDRAISING";
export const GET_FUNDRAISING = "GET_FUNDRAISING";
export const GET_FUNDRAISING_SUCCESS = "GET_FUNDRAISING_SUCCESS";

/**
 * Deal Types
 */

export const GET_DEAL_TYPES = "GET_DEAL_TYPES";
export const GET_DEAL_TYPES_SUCCESS = "GET_DEAL_TYPES_SUCCESS";
export const GET_DEAL_TYPE = "GET_DEAL_TYPE";
export const GET_DEAL_TYPE_SUCCESS = "GET_DEAL_TYPE_SUCCESS";

/**
 * List of Campaigns
 */

export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";
export const SEARCH_CAMPAIGNS = "[SEARCH COMPONENT] SEARCH CAMPAIGNS";
export const SEARCH_CAMPAIGNS_SUCCESS =
	"[SEARCH COMPONENT] SEARCH CAMPAIGNS SUCCESS";
export const SEARCH_SUCCESS_CAMPAIGNS =
	"[SEARCH COMPONENT] SEARCH_SUCCESS_CAMPAIGNS";
export const REQUEST_PAYOUT = "[CAMPAIGN] Request Payout";
export const REQUEST_PAYOUT_SUCCESS = "[CAMPAIGN] Request Payout Success";
/**
 * DELETE CAMPAIGN
 */

export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";

/**
 * TERMS
 */

export const CREATE_TERM = "CREATE_TERM";
export const UPDATE_TERM = "UPDATE_TERM";
export const GET_TERM = "GET_TERM";
export const GET_TERM_SUCCESS = "GET_TERM_SUCCESS";

/**
 * STORY
 */
export const CREATE_STORY = "CREATE_STORY";
export const UPDATE_STORY = "UPDATE_STORY";
export const GET_STORY = "GET_STORY";
export const GET_STORY_SUCCESS = "GET_STORY_SUCCESS";

/**
 * EXTRAS
 */
export const CREATE_EXTRAS = "CREATE_EXTRAS";
export const UPDATE_EXTRAS = "UPDATE_EXTRAS";
export const GET_EXTRAS = "GET_EXTRAS";
export const GET_EXTRAS_SUCCESS = "GET_EXTRAS_SUCCESS";
export const CLEAR_CREATE_CAMPAIGN_DATA = "CLEAR_CREATE_CAMPAIGN_DATA";
export const CLEAR_INVESTMENT_PREAPROVAL_DATA =
	"CLEAR_INVESTMENT_PREAPROVAL_DATA";

export const CREATE_EXTRAS_IMAGE = "CREATE_EXTRAS_IMAGE";
export const UPDATE_EXTRAS_IMAGE = "UPDATE_EXTRAS_IMAGE";
export const DELETE_EXTRAS_IMAGE = "DELETE_EXTRAS_IMAGE";
export const GET_EXTRAS_IMAGE = "GET_EXTRAS_IMAGE";
export const GET_EXTRAS_IMAGE_SUCCESS = "GET_EXTRAS_IMAGE_SUCCESS";
export const GET_EXTRAS_IMAGES = "GET_EXTRAS_IMAGES";
export const GET_EXTRAS_IMAGES_SUCCESS = "GET_EXTRAS_IMAGES_SUCCESS";
export const CLEAR_EXTRAS_IMAGE = "CLEAR_EXTRAS_IMAGE";

export const CREATE_EXTRAS_DOCUMENT = "CREATE_EXTRAS_DOCUMENT";
export const UPDATE_EXTRAS_DOCUMENT = "UPDATE_EXTRAS_DOCUMENT";
export const DELETE_EXTRAS_DOCUMENT = "DELETE_EXTRAS_DOCUMENT";
export const GET_EXTRAS_DOCUMENT = "GET_EXTRAS_DOCUMENT";
export const GET_EXTRAS_DOCUMENT_SUCCESS = "GET_EXTRAS_DOCUMENTS_SUCCESS";
export const GET_EXTRAS_DOCUMENTS = "GET_EXTRAS_DOCUMENTS";
export const GET_EXTRAS_DOCUMENTS_SUCCESS = "GET_EXTRAS_DOCUMENTS_SUCCESS";
export const CLEAR_EXTRAS_DOCUMEN = "CLEAR_EXTRAS_DOCUMEN";

export const CREATE_EXTRAS_VIDEO = "CREATE_EXTRAS_VIDEO";
export const UPDATE_EXTRAS_VIDEO = "UPDATE_EXTRAS_VIDEO";
export const DELETE_EXTRAS_VIDEO = "DELETE_EXTRAS_VIDEO";
export const GET_EXTRAS_VIDEO = "GET_EXTRAS_VIDEO";
export const GET_EXTRAS_VIDEO_SUCCESS = "GET_EXTRAS_VIDEOS_SUCCESS";
export const GET_EXTRAS_VIDEOS = "GET_EXTRAS_VIDEOS";
export const GET_EXTRAS_VIDEOS_SUCCESS = "GET_EXTRAS_VIDEO_SUCCESS";
export const CLEAR_EXTRAS_VIDEO = "CLEAR_EXTRAS_VIDEO";

/**
 * STEP 6 Funding
 */

export const GET_FUNDING = "GET_FUNDING";
export const GET_FUNDING_SUCCESS = "GET_FUNDING_SUCCESS";
export const CREATE_FUNDING = "CREATE_FUNDING";
export const UPDATE_FUNDING = "UPDATE_FUNDING";

// COMMON

export const CREATE_EQUITY = "CREATE_EQUITY";
export const CREATE_EQUITY_SUCCESS = "CREATE_EQUITY_SUCCESS";
export const GET_EQUITIES = "GET_EQUITIES";
export const GET_EQUITIES_START = "GET_EQUITIES_START";
export const GET_EQUITIES_SUCCESS = "GET_EQUITIES_SUCCESS";
export const GET_EQUTY = "GET_EQUITY";
export const GET_EQUITY_SUCCESS = "GET_EQUITY_SUCCESS";
export const GET_TAKEAWAY_ESTIMATE_SUCCESS = "GET_TAKEAWAY_ESTIMATE_SUCCESS";
export const GET_FAQS = "GET_FAQS";
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
export const GET_CUSTOM_FAQS_SUCCESS = "GET_CUSTOM_FAQS_SUCCESS";
export const GET_CAMPAIGN_DASHBOARD = "GET_CAMPAIGN_DASHBOARD";
export const GET_CAMPAIGN_DASHBOARD_SUCCESS = "GET_CAMPAIGN_DASHBOARD_SUCCESS";
export const CREATE_FAQs = "CREATE_FAQS";
export const GET_ANSWER = "GET_ANSWER";
export const GET_ANSWER_SUCCESS = "GET_ANSWER_SUCCESS";
export const GET_EQUITY_UPDATES = "GET_EQUITY_UPDATES";
export const RESET_EQUITY_UPDATE = "RESET_EQUITY_UPDATE";
export const GET_EQUITY_UPDATES_SUCCESS = "GET_EQUITY_UPDATES_SUCCESS";
export const CREATE_EQUITY_UPDATE = "CREATE_EQUITY_UPDATE";
export const PATCH_EQUITY_UPDATE = "PATCH_EQUITY_UPDATE";
export const DELETE_EQUITY_UPDATE = "DELETE_EQUITY_UPDATE";
export const GET_EQUITY_UPDATE = "GET_EQUITY_UPDATE";
export const GET_EQUITY_UPDATE_SUCCESS = "GET_EQUITY_UPDATE_SUCCESS";
export const GET_PUBLIC_EQUITIES = "GET_PUBLIC_EQUITIES";
export const GET_PUBLIC_EQUITIES_SUCCESS = "GET_PUBLIC_EQUITIES_SUCCESS";
export const SET_SPINNER = "SET_SPINNER";
export const GET_PUBLIC_SUCCESS_EQUITIES = "GET_PUBLIC_SUCCESS_EQUITIES";
export const GET_PUBLIC_SUCCESS_EQUITIES_SUCCESS =
	"GET_PUBLIC_SUCCESS_EQUITIES_SUCCESS";
export const GET_CAMPAIGN_COMPLETED = "GET_CAMPAIGN_COMPLETED";
export const GET_CAMPAIGN_COMPLETED_SUCCESS = "GET_CAMPAIGN_COMPLETED_SUCCESS";
export const GET_CAMPAIGN_PAGE = "GET_CAMPAIGN_PAGE";
export const GET_CAMPAIGN_PAGE_SUCCESS = "GET_CAMPAIGN_PAGE_SUCCESS";
export const GET_PUBLIC_UPDATES = "GET_PUBLIC_UPDATES";
export const GET_PUBLIC_UPDATES_SUCCESS = "GET_PUBLIC_UPDATES_SUCCESS";
export const GET_PUBLIC_DOCUMENTS = "GET_PUBLIC_DOCUMENTS";
export const GET_PUBLIC_DOCUMENTS_SUCCESS = "GET_PUBLIC_DOCUMENTS_SUCCESS";
export const GET_PUBLIC_FAQS = "GET_PUBLIC_FAQS";
export const GET_PUBLIC_FAQS_SUCCESS = "GET_PUBLIC_FAQS_SUCCESS";
export const SAVE_CAMPAIGN = "SAVE_CAMPAIGN";
export const UNSAVE_CAMPAIGN = "UNSAVE_CAMPAIGN";
export const SAVE_CAMPAIGN_SUCCESS = "SAVE_CAMPAIGN_SUCCESS";
export const GET_SAVE_CAMPAIGN = "GET_SAVE_CAMPAIGN";
export const GET_SAVE_CAMPAIGN_SUCCESS = "GET_SAVE_CAMPAIGN_SUCCESS";
export const GET_SAVED_CAMPAIGN = "GET_SAVED_CAMPAIGN";
export const GET_SAVED_CAMPAIGN_SUCCESS = "GET_SAVED_CAMPAIGN_SUCCESS";
export const CREATE_INVESTMENT = "CREATE_INVESTMENT";
export const CREATE_INVESTMENT_SUCCESS = "CREATE_INVESTMENT_SUCCESS";
export const UPDATE_INVESTMENT_SUCCESS = "UPDATE_INVESTMENT_SUCCESS";
export const GET_INVESTMENT_CONTRACT = "GET_INVESTMENT_CONTRACT";
export const GET_INVESTMENT_CONTRACT_SUCCESS =
	"GET_INVESTMENT_CONTRACT_SUCCESS";
export const GET_SIGNED_CONTRACT = "GET_SIGNED_CONTRACT";
export const GET_SIGNED_CONTRACT_SUCCESS = "GET_SIGNED_CONTRACT_SUCCESS";
export const GET_OFFLINE = "GET_OFFLINE";
export const GET_OFFLINE_SUCCESS = "GET_OFFLINE_SUCCESS";
export const GET_STRIPE = "GET_STRIPE";
export const GET_STRIPE_SUCCESS = "GET_STRIPE_SUCCESS";
export const CREATE_OFFLINE_PAYMENT = "CREATE_OFFLINE_PAYMENT";
export const CREATE_OFFLINE_PAYMENT_SUCCESS = "CREATE_OFFLINE_PAYMENT_SUCCESS";
export const GET_CURRENT_INVEST =
	"[INVEST NOW COMPONENT] Get Current Investment";
export const GET_CURRENT_INVEST_SUCCESS =
	"[INVEST NOW COMPONENT] Get Current Investment Success";

export const GET_CURRENT_INVEST_BY_CAMPAIGNID =
	"[INVEST NOW COMPONENT] Get Current Investment By Campaignid";
export const GET_CURRENT_INVEST_BY_CAMPAIGNID_SUCCESS =
	"[INVEST NOW COMPONENT] Get Current Investment By Campaignid Success";
export const CLEAR_CURRENT_INVEST_BY_CAMPAIGNID =
	"[INVEST NOW COMPONENT] Clear Current Investment By Campaignid";

export const UPDATE_INVESTMENT = "[INVEST NOW COMPONENT] Update Investment";
export const GET_RECIEPT = "[RECIEPT COMPONENT] Get Receipt";
export const GET_RECIEPT_SUCCESS = "[RECIEPT COMPONENT] Get Receipt Success";
export const CLEAR_STRIPE_RESPONSE =
	"[INVEST NOW COMPONENT] Clear Stripe Response";
export const CLEAR_EQUITY_GOOGLE_ANALYTIC_CODE =
	"Clear Equity Google Analytic Code";
export const EQUITY_GOOGLE_ANALYTIC_CODE = "Equity Google Analytic Code";
export const STRIPE_PAYMENT = "[INVEST NOW COMPONENT] Stripe Payment Initiate";
export const STRIPE_PAYMENT_SUCCESS =
	"[INVEST NOW COMPONENT] Stripe Payment Success";
export const STRIPE_PAYMENT_FAIL = "[INVEST NOW COMPONENT] Stripe Payment Fail";
export const GET_STRIPE_RESPONSE = "[INVEST NOW COMPONENT] Get Stripe Response";
export const GET_STRIPE_RESPONSE_SUCCESS =
	"[INVEST NOW COMPONENT] Get Stripe Response Success";
export const STRIPE_UPDATE = "[INVEST NOW COMPONENT] Stripe Update";
export const STRIPE_UPDATE_SUCCESS =
	"[INVEST NOW COMPONENT] Stripe Update Success";
export const GET_TOTAL_RESERVARTIONS =
	"[CAMPAIGN COMPONENT] Get Total Reservations";
export const GET_TOTAL_RESERVARTIONS_SUCCESS =
	"[CAMPAIGN COMPONENT] Get Total Reservations Success";
export const GET_INVESTMENTS = "[CAMPAIGN COMPONENT] Get Investments";
export const GET_INVESTMENTS_SUCCESS =
	"[CAMPAIGN COMPONENT] Get Investments Success";
export const GET_REPAYMENT = "[CAMPAIGN] Get Repayment";
export const GET_REPAYMENT_SUCCESS = "[CAMPAIGN] Get Repayment Success";
export const IS_SPINNING = "[CAMPAIGN] IS SPINNING";
export const CREATE_REPAYMENT = "[CAMPAIGN] Create Repayment";
export const UPDATE_REPAYMENT = "[CAMPAIGN] Update Repayment";
export const CREATE_REPAYMENT_STRIPE = "[CAMPAIGN] Create Repayment Stripe";
export const UPDATE_REPAYMENT_STRIPE = "[CAMPAIGN] Update Repayment Stripe";
export const GET_SINGLE_REPAYMENT = "[CAMPAIGN] Get Single Repayment";
export const GET_SINGLE_REPAYMENT_SUCCESS =
	"[CAMPAIGN] Get Single Repayment Success";
export const REPAYMENT_STRIPE = "[CAMPAIGN] Repayment Stripe";
export const REPAYMENT_STRIPE_SUCCESS = "[CAMPAIGN] Repayment Stripe Success";

/**
 * LEAD INVESTOR
 */

export const GET_LEAD_INVESTOR = "[CAMPAIGN] Get Lead Investor";
export const GET_LEAD_INVESTOR_SUCCESS = "[CAMPAIGN] Get Lead Investor Success";
export const GET_LEAD_INVESTORS = "[CAMPAIGN] Get Lead Investors";
export const GET_LEAD_INVESTORS_SUCCESS =
	"[CAMPAIGN] Get Lead Investors Success";
export const CREATE_LEAD_INVESTOR = "[CAMPAIGN] Create Lead Investor";
export const UPDATE_LEAD_INVESTOR = "[CAMPAIGN] Update Lead Investor";
export const DELETE_LEAD_INVESTOR = "[CAMPAIGN] Delete Lead Investor";
export const CLEAR_LEAD_INVESTOR = "[CAMPAIGN] Clear Lead Investor";

export const GET_USER_PROFILE_CAMPAIGNS_LIST =
	"[USER PROFILE] GET_USER_PROFILE_CAMPAIGNS_LIST";
export const GET_USER_PROFILE_INVESTORS_LIST =
	"[USER PROFILE] GET_USER_PROFILE_INVESTORS_LIST";
export const GET_USER_PROFILE_CAMPAIGNS_SUCCESS =
	"[USER PROFILE] GET_USER_PROFILE_CAMPAIGNS_SUCCESS";
export const GET_USER_PROFILE_INVESTORS_SUCCESS =
	"[USER PROFILE] GET_USER_PROFILE_INVESTORS_SUCCESS";

export const GET_SHUFTI_PRO_KYC = "GET_SHUFTI_PRO_KYC";
export const GET_SHUFTI_PRO_KYC_SUCCESS = "GET_SHUFTI_PRO_KYC_SUCCESS";
// start remove
export const CREATE_PARTY_NORTH_KYC = "CREATE_PARTY_NORTH_KYC";
export const CREATE_PARTY_NORTH_KYC_SUCCESS = "CREATE_PARTY_NORTH_KYC_SUCCESS";
export const CLEAR_NORTH_KYC_FILELD = "CLEAR_NORTH_KYC_FILELD";
export const UPDATE_PARTY_NORTH_KYC = "UPDATE_PARTY_NORTH_KYC";
export const UPDATE_PARTY_NORTH_KYC_SUCCESS = "UPDATE_PARTY_NORTH_KYC_SUCCESS";
export const UPLOAD_PARTY_DOCUMENT = "UPLOAD_PARTY_DOCUMENT";
export const UPLOAD_PARTY_DOCUMENT_SUCCESS = "UPLOAD_PARTY_DOCUMENT_SUCCESS";
export const GET_NORTH_KYC_STATUS = "GET_NORTH_KYC_STATUS";
export const GET_NORTH_KYC_STATUS_SUCCESS = "GET_NORTH_KYC_STATUS_SUCCESS";
export const UPDATE_NORTH_KYC_STATUS = "UPDATE_NORTH_KYC_STATUS";
export const UPDATE_NORTH_KYC_STATUS_SUCCESS =
	"UPDATE_NORTH_KYC_STATUS_SUCCESS";
export const NORTH_KYC_FAIL = "NORTH_KYC_FAIL";
// end remove
export const GET_PAYPAL_SETTING = "GET_PAYPAL_SETTING";
export const GET_PAYPAL_SETTING_SUCCESS = "GET_PAYPAL_SETTING_SUCCESS";
export const GET_PAYPAL_GENERATE_TOKEN = "GET_PAYPAL_GENERATE_TOKEN";
export const GET_PAYPAL_GENERATE_TOKEN_SUCCESS =
	"GET_PAYPAL_GENERATE_TOKEN_SUCCESS";
export const GET_PAYPAL_CONFIRM_PAYMENT = "GET_PAYPAL_CONFIRM_PAYMENT";
export const GET_PAYPAL_CONFIRM_PAYMENT_SUCCESS =
	"GET_PAYPAL_CONFIRM_PAYMENT_SUCCESS";
export const CLEAR_PAYPAL_CONFIRM_PAYMENT_RESPONSE =
	"CLEAR_PAYPAL_CONFIRM_PAYMENT_RESPONSE";

export const CLEAR_STRIPE_ACH_CONNECTION_URL =
	"CLEAR_STRIPE_ACH_CONNECTION_URL";
export const GET_STRIPE_ACH_CONNECTION_URL = "GET_STRIPE_ACH_CONNECTION_URL";
export const GET_STRIPE_ACH_CONNECTION_URL_SUCCESS =
	"GET_STRIPE_ACH_CONNECTION_URL_SUCCESS";

export const POST_STRIPE_ACH_CONNECT = "POST_STRIPE_ACH_CONNECT";
export const POST_STRIPE_ACH_CONNECT_SUCCESS =
	"POST_STRIPE_ACH_CONNECT_SUCCESS";

export const GET_STRIPE_ACH_ALL_USER_ACCOUNTS =
	"GET_STRIPE_ACH_ALL_USER_ACCOUNTS";
export const GET_STRIPE_ACH_ALL_USER_ACCOUNTS_SUCCESS =
	"GET_STRIPE_ACH_ALL_USER_ACCOUNTS_SUCCESS";

export const CLEAR_STRIPE_ACH_CONNECT_SUCCESS_MSG =
	"CLEAR_STRIPE_ACH_CONNECT_SUCCESS_MSG";
export const STRIPE_ACH_DISCONNECT_ACCOUNT = "STRIPE_ACH_DISCONNECT_ACCOUNT";

/// ACH
export const GET_ACH_SETTING = "GET_ACH_SETTING";
export const GET_ACH_SETTING_SUCCESS = "GET_ACH_SETTING_SUCCESS";

export const CLEAR_STRIPE_ACH_PAYMENT_TOKEN = "CLEAR_STRIPE_ACH_PAYMENT_TOKEN";
export const GET_STRIPE_ACH_PAYMENT_TOKEN = "GET_STRIPE_ACH_PAYMENT_TOKEN";
export const GET_STRIPE_ACH_PAYMENT_TOKEN_SUCCESS =
	"GET_STRIPE_ACH_PAYMENT_TOKEN_SUCCESS";

export const CLEAR_STRIPE_ACH_PAYMENT_CONFIMATION =
	"CLEAR_STRIPE_ACH_PAYMENT_CONFIMATION";
export const POST_STRIPE_ACH_PAYMENT_CONFIMATION =
	"POST_STRIPE_ACH_PAYMENT_CONFIMATION";
export const POST_STRIPE_ACH_PAYMENT_CONFIMATION_SUCCESS =
	"POST_STRIPE_ACH_PAYMENT_CONFIMATION_SUCCESS";

export const CLEAR_STRIPE_ACH_RE_PAYMENT_CONFIMATION =
	"CLEAR_STRIPE_ACH_RE_PAYMENT_CONFIMATION";
export const POST_STRIPE_ACH_RE_PAYMENT_CONFIMATION =
	"POST_STRIPE_ACH_RE_PAYMENT_CONFIMATION";
export const POST_STRIPE_ACH_RE_PAYMENT_CONFIMATION_SUCCESS =
	"POST_STRIPE_ACH_RE_PAYMENT_CONFIMATION_SUCCESS";

export const CHECK_CAMPAIGN_STRIPE_ACH_RE_PAYMENT =
	"CHECK_CAMPAIGN_STRIPE_ACH_RE_PAYMENT";
export const CHECK_CAMPAIGN_STRIPE_ACH_RE_PAYMENT_SUCCESS =
	"CHECK_CAMPAIGN_STRIPE_ACH_RE_PAYMENT_SUCCESS";

export const WISH_LOADING = "[CAMPAIGN] Wishlist Loading";

export const WALLET_INVESTMENT_COMPLETE = "WALLET_INVESTMENT_COMPLETE";
export const WALLET_INVESTMENT_COMPLETE_SUCCESS =
	"WALLET_INVESTMENT_COMPLETE_SUCCESS";
export const WALLET_INVESTMENT_PAYMENT = "WALLET_INVESTMENT_PAYMENT";
export const WALLET_INVESTMENT_PAYMENT_SUCCESS =
	"WALLET_INVESTMENT_PAYMENT_SUCCESS";

import axios from "../../config/AxiosConfig";
import useSWR from "swr";

import { GET_WALLET_DETAILS_API } from "../../store/actions/apiCollections";

export const walletTransactionFetcher = (url) =>
	axios.get(url).then((response) => response.data.data.data);

export const walletDetailsFetcher = (url) =>
	axios.get(url).then((response) => response.data.data.data);

export const withdrawFromWallet = (url, { arg }) =>
	axios.post(url, arg).then((response) => response.data.data.data);

export const walletTopup = (url, { arg }) =>
	axios.post(url, arg).then((response) => response.data.data.data);

export const useWalletDetailsFetcher = (currencyId) => {
	const {
		data: walletDetails,
		isValidating,
		mutate,
	} = useSWR(
		currencyId ? `${GET_WALLET_DETAILS_API}?&currencyId=${currencyId}` : "",
		walletDetailsFetcher,
		{
			keepPreviousData: true,
			revalidateOnFocus: false,
		}
	);

	return { walletDetails, isValidating, mutate };
};

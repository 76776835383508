import axios from "../../config/AxiosConfig";
import { getLanguageId, getLanguageCode } from "../../helpers";
import useSWR from "swr";

import { INVESTMENT_API } from "../../store/actions/apiCollections";
import { getCookie } from "../../helpers/cookieHelper";

const queryParams = {
	params: {
		...(getLanguageCode() != null && {
			lng: getLanguageCode(),
		}),
	},
};

export const investmentContractFetcher = (url) =>
	axios
		.get(`${url}/investment-contract?language=${getLanguageId()}`)
		.then((response) => response.data.data.data[0]);

export const investmentSignedContractFetcher = (url, { arg }) =>
	axios.post(url, arg, queryParams).then((response) => response.data);

export const currentInvestmentFetcher = (url) =>
	axios.get(url).then((response) => response.data.data.data);

export const campaignDetailFetcher = (url) =>
	axios.get(url).then((response) => response.data.data.data);

export const createInvestmentFetcher = (url, { arg }) =>
	axios.post(url, arg).then((response) => response.data.data.data);

export const checkProcessInvestmentStatusFetcher = (url, arg) => {
	return axios
		.get(url, {
			params: {
				campaignId: arg.arg.campaignId,
				userId: arg.arg.userId,
			},
		})
		.then((response) => response.data.data.data);
};

export const updateInvestmentFetcher = (url, { arg }) =>
	axios.patch(url, arg).then((response) => response.data.data.data);

export const processWalletInvestmentFetcher = (url, { arg }) =>
	axios.patch(url, arg).then((response) => response.data.data.data);

export const useCurrentInvestmentFetcher = () => {
	const { data, isValidating } = useSWR(
		getCookie("investorId")
			? `${INVESTMENT_API}${getCookie("investorId")}`
			: "",
		currentInvestmentFetcher,
		{
			keepPreviousData: true,
			revalidateOnFocus: false,
		}
	);

	return { data, isValidating };
};

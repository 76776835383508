import useSWR from "swr";
import useSWRMutation from "swr/mutation";

import axios from "../../../config/AxiosConfig";
import { getLanguageCode } from "../../../helpers";
import {
	GET_NORTH_KYC_STATUS_API,
	UPDATE_NORTH_KYC_STATUS_API,
	UPLOAD_PARTY_DOCUMENT_API,
	NORTH_KYC_API,
	NORTH_KYC_UPDATE_PARTY_API,
	DWOLLA_KYC_UPDATE_CUSTOMER_API,
	DWOLLA_KYC_API,
	GET_DWOLLA_KYC_STATUS_API,
} from "../../../store/actions/apiCollections";

const queryParams = {
	params: {
		...(getLanguageCode() != null && {
			lng: getLanguageCode(),
		}),
	},
};
/* Fetchers */
export const getNorthKYCStatusFetcher = (url) =>
	axios.get(url, queryParams).then((response) => response.data.data.data);

export const updateNorthKycStatusFetcher = (url, { arg }) =>
	axios.put(url, arg, queryParams).then((response) => response.data.data.data);

export const uploadPartyDocumentFetcher = (url, { arg }) =>
	axios.put(url, arg, queryParams).then((response) => response.data.data.data);

export const createUpdatePartyFetcher = (url, { arg }) =>
	arg?.partyId
		? axios
				.post(url, arg, queryParams)
				.then((response) => response.data.data.data)
		: axios
				.put(url, arg, queryParams)
				.then((response) => response.data.data.data);

export const getDwollaStatesFetcher = (url) =>
	axios.get(url, queryParams).then((response) => response.data.message);

export const getDwollaBusinessClassificationFetcher = (url) =>
	axios.get(url, queryParams).then((response) => response.data.message);

export const getDwollaCustomerStatusFetcher = (url) =>
	axios.get(url, queryParams).then((response) => response.data.message[0]);

export const createUpdateDwollaCustomerFetcher = (url, { arg }) =>
	arg?.partyId
		? axios
				.patch(url, arg, queryParams)
				.then((response) => response.data.message)
		: axios
				.post(url, arg, queryParams)
				.then((response) => response.data.message);

/* Custom hooks */
export function useGetNorthKYCStatus() {
	const {
		data: northKYCStatusData,
		isLoading: northKYCStatusLoading,
		mutate: northKYCStatusMutate,
	} = useSWR(`${GET_NORTH_KYC_STATUS_API}`, getNorthKYCStatusFetcher, {
		keepPreviousData: true,
	});

	return {
		northKYCStatusData,
		northKYCStatusLoading,
		northKYCStatusMutate,
	};
}

export function useUpdateNorthKycStatus() {
	const {
		data: updateNorthKYCStatusData,
		trigger: updateNorthKYCStatusTrigger,
		isMutating: updateNorthKYCStatusMutating,
		error: updateNorthKYCStatusError,
	} = useSWRMutation(
		`${UPDATE_NORTH_KYC_STATUS_API}`,
		updateNorthKycStatusFetcher
	);
	return {
		updateNorthKYCStatusData,
		updateNorthKYCStatusTrigger,
		updateNorthKYCStatusMutating,
		updateNorthKYCStatusError,
	};
}

export function useUploadPartyDocument() {
	const {
		data: uploadPartyDocumentData,
		trigger: uploadPartyDocumentTrigger,
		isMutating: uploadPartyDocumentMutating,
		error: uploadPartyDocumentError,
	} = useSWRMutation(
		`${UPLOAD_PARTY_DOCUMENT_API}`,
		uploadPartyDocumentFetcher
	);
	return {
		uploadPartyDocumentData,
		uploadPartyDocumentTrigger,
		uploadPartyDocumentMutating,
		uploadPartyDocumentError,
	};
}

export function useCreateUpdateParty(partyId) {
	const {
		data: createUpdatePartyData,
		trigger: createUpdatePartyTrigger,
		isMutating: createUpdatePartyMutating,
		error: createUpdatePartyError,
	} = useSWRMutation(
		partyId ? NORTH_KYC_UPDATE_PARTY_API : NORTH_KYC_API,
		createUpdatePartyFetcher
	);
	return {
		createUpdatePartyData,
		createUpdatePartyTrigger,
		createUpdatePartyMutating,
		createUpdatePartyError,
	};
}

export function useGetDwollaCustomerStatus() {
	const {
		data: dwollaCustomerData,
		isLoading: dwollaCustomerLoading,
		mutate: dwollaCustomerMutate,
	} = useSWR(`${GET_DWOLLA_KYC_STATUS_API}`, getDwollaCustomerStatusFetcher, {
		keepPreviousData: true,
	});

	return {
		dwollaCustomerData,
		dwollaCustomerLoading,
		dwollaCustomerMutate,
	};
}

export function useCreateUpdateDwollaCustomer(partyId) {
	const {
		data: createUpdateDwollaCustomerData,
		trigger: createUpdateDwollaCustomerTrigger,
		isMutating: createUpdateDwollaCustomerMutating,
		error: createUpdateDwollaCustomerError,
	} = useSWRMutation(
		partyId ? DWOLLA_KYC_UPDATE_CUSTOMER_API : DWOLLA_KYC_API,
		createUpdateDwollaCustomerFetcher
	);
	return {
		createUpdateDwollaCustomerData,
		createUpdateDwollaCustomerTrigger,
		createUpdateDwollaCustomerMutating,
		createUpdateDwollaCustomerError,
	};
}

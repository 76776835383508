/* eslint-disable array-callback-return */
import {
	FIELD_LIST_STEP_1,
	FIELD_LIST_STEP_2_FUNDRAISING,
	FIELD_LIST_STEP_2_EQUITY_TERM,
	FIELD_LIST_STEP_2_DEBT_TERM,
	FIELD_LIST_STEP_2_REAL_ESTATE,
	FIELD_LIST_STEP_3,
	FIELD_LIST_STEP_5,
	FIELD_LIST_STEP_6,
	FIELD_LIST_STEP_6_TRUE,
	FIELD_LIST_STEP_6_FALSE,
	FIELD_LIST_STEP_6_ACH_ID_TRUE,
	FIELD_LIST_STEP_6_ACH_ID_FALSE,
	FIELD_LIST_STEP_6_PLAID_DWOLLA_TRUE,
	FIELD_LIST_STEP_6_PLAID_DWOLLA_FALSE,
} from "../constants";

export const getMissingFieldList = (
	equity,
	projectScriptType,
	achModule,
	plaidDwollaModule,
	answers,
	faqs,
	campaignSettings
) => {
	let isAnyFieldMissing = false;

	const step1MissingFieldList = getStep1MissingFieldList(equity?.companyId);
	if (step1MissingFieldList?.map((field) => field.complete).includes(false)) {
		isAnyFieldMissing = true;
	}

	const step2MissingFieldList = getStep2MissingFieldList(
		equity,
		projectScriptType
	);
	if (step2MissingFieldList?.map((field) => field.complete).includes(false)) {
		isAnyFieldMissing = true;
	}

	const step3MissingFieldList = getStep3MissingFieldList(equity);
	if (step3MissingFieldList?.map((field) => field.complete).includes(false)) {
		isAnyFieldMissing = true;
	}
	const step4MissingFieldList = getStep4MissingFieldList(answers, faqs);
	if (step4MissingFieldList?.map((field) => field.complete).includes(false)) {
		isAnyFieldMissing = true;
	}
	const step5MissingFieldList = getStep5MissingFieldList(
		equity,
		campaignSettings
	);
	if (step5MissingFieldList?.map((field) => field.complete).includes(false)) {
		isAnyFieldMissing = true;
	}

	/* const step6MissingFieldList =
		plaidDwollaModule === "yes"
			? []
			: getStep6MissingFieldList(equity, achModule); */
	const step6MissingFieldList = getStep6MissingFieldList(
		equity,
		achModule,
		plaidDwollaModule
	);

	if (step6MissingFieldList?.map((field) => field?.complete).includes(false)) {
		isAnyFieldMissing = true;
	}

	return {
		isAnyFieldMissing,
		step1MissingFieldList,
		step2MissingFieldList,
		step3MissingFieldList,
		step4MissingFieldList,
		step5MissingFieldList,
		step6MissingFieldList,
	};
};

const getStep1MissingFieldList = (companyInfo) => {
	const missingFieldsStep1 = FIELD_LIST_STEP_1;

	missingFieldsStep1.map((field) => {
		field.complete = companyInfo[field?.name] ? true : false;
	});

	return missingFieldsStep1;
};

const getStep2MissingFieldList = (equity, projectScriptType) => {
	const missingFieldsStep2 =
		projectScriptType === 1
			? FIELD_LIST_STEP_2_REAL_ESTATE.concat(FIELD_LIST_STEP_2_FUNDRAISING)
			: FIELD_LIST_STEP_2_FUNDRAISING;

	if (equity?.termsSlug === "debt") {
		missingFieldsStep2.concat(FIELD_LIST_STEP_2_DEBT_TERM);
	} else {
		missingFieldsStep2.concat(FIELD_LIST_STEP_2_EQUITY_TERM);
	}

	missingFieldsStep2.map((field) => {
		field.complete = equity[field?.name] ? true : false;
	});

	return missingFieldsStep2;
};

const getStep3MissingFieldList = (equity) => {
	const missingFieldsStep3 = FIELD_LIST_STEP_3;

	missingFieldsStep3.map((field) => {
		field.complete = equity[field?.name] ? true : false;
	});

	return missingFieldsStep3;
};

const getStep4MissingFieldList = (answers, faqs) => {
	const missingFieldsStep4 = [];
	if (answers) {
		faqs?.map((faq) => {
			if (faq?.isRequired) {
				const answerIndex = answers?.findIndex(
					(ans) => ans?.questionID?.id === faq.id
				);
				missingFieldsStep4.push({
					id: `campaignFaqs_${answerIndex}_answer`,
					complete: answers[answerIndex]?.answer ? true : false,
					label: faq?.question,
				});
			}
		});
	} else {
		faqs?.map((faq) => {
			if (faq?.isRequired) {
				missingFieldsStep4.push({
					id: `campaignFaqs_0_answer`,
					complete: false,
					label: faq?.question,
				});
			}
		});
	}
	return missingFieldsStep4;
};

const getStep5MissingFieldList = (equity, campaignSettings) => {
	const missingFieldsStep5 = FIELD_LIST_STEP_5;

	missingFieldsStep5.map((field) => {
		field.complete = equity[field?.name] ? true : false;
	});

	return campaignSettings?.contractCopy === true &&
		campaignSettings?.manageContract === "yes"
		? missingFieldsStep5
		: [];
};

const getStep6MissingFieldList = (equity, achModule, plaidDwollaModule) => {
	const missingFieldsStep6 = FIELD_LIST_STEP_6;
	let count = 0;
	missingFieldsStep6.map((field) => {
		count = count + (equity[field?.name] ? 1 : 0);
		field.complete = equity[field?.name] ? true : false;
	});

	const missingFieldsStep6ACH =
		achModule === "yes"
			? equity?.achAccountId?.accountId
				? missingFieldsStep6.concat(FIELD_LIST_STEP_6_ACH_ID_TRUE)
				: missingFieldsStep6.concat(FIELD_LIST_STEP_6_ACH_ID_FALSE)
			: missingFieldsStep6;

	const missingFieldsStep6PlaidDwollaACH =
		plaidDwollaModule === "yes"
			? equity?.plaidDwollFundingSourceId?.id
				? FIELD_LIST_STEP_6_PLAID_DWOLLA_TRUE
				: FIELD_LIST_STEP_6_PLAID_DWOLLA_FALSE
			: [];

	return plaidDwollaModule === "yes"
		? missingFieldsStep6PlaidDwollaACH
		: !equity?.accountNumber
		? missingFieldsStep6ACH.concat(FIELD_LIST_STEP_6_FALSE)
		: missingFieldsStep6ACH.concat(FIELD_LIST_STEP_6_TRUE);
};

import i18n from "i18next";

export const ASSOCIATED_PERSON_OPTION_LIST = [
	{
		label: i18n.t("global.yes"),
    value: "Yes"
	},
  {
		label: i18n.t("global.no"),
    value: "No"
	}
]

export const DOMICILE_OPTION_LIST = [
	{
		label: i18n.t("northKYC.dropdownOptions.usCitizen"),
    value: "U.S. Citizen"
	},
  {
		label: i18n.t("northKYC.dropdownOptions.usResident"),
    value: "U.S. Resident"
	},
  {
		label: i18n.t("northKYC.dropdownOptions.nonResident"),
    value: "non-resident"
	}
]

export const EMPLOYMENT_STATUS_OPTION_LIST = [
	{
		label: i18n.t("northKYC.dropdownOptions.employed"),
    value: "Employed"
	},
  {
		label: i18n.t("northKYC.dropdownOptions.notEmployed"),
    value: "Not Employed"
	},
  {
		label: i18n.t("northKYC.dropdownOptions.retired"),
    value: "Retired"
	},
  {
		label: i18n.t("northKYC.dropdownOptions.student"),
    value: "Student"
	},
]

export const INVEST_TO_OPTION_LIST = [
	{
		label: i18n.t("northKYC.dropdownOptions.self"),
    value: 0
	},
  {
		label: i18n.t("northKYC.dropdownOptions.other"),
    value: 1
	}
]


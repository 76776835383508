import { put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import { GET_CURRENT_USER_API } from "../actions/apiCollections";
import { getCurrentUserSuccess, getCurrentUserFail } from "../actions/index";
import { getLanguageCode } from "../../helpers/helpers";

export function* getCurrentUserSaga() {
  //yield put(getCurrentUserStart());
  try {
    const response = yield axios.get(
      `${GET_CURRENT_USER_API}?lng=${getLanguageCode()}`
    );
    yield put(getCurrentUserSuccess(response.data.data.data));
    yield localStorage.setItem("userType", response.data.data.data.userType);
  } catch (error) {
    yield put(getCurrentUserFail(error?.response?.data?.message ?? ""));
  }
}

import React, { useContext } from "react";
import { AuthContext } from "./Context";
import { Route, Navigate } from "react-router-dom";
import AppPaths from "../../../../routes/AppPaths";

export function ProvideAuth({ children }) {
	const auth = localStorage.getItem("token");
	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
export const useAuth = () => {
	return useContext(AuthContext);
};

export function PrivateRoute({ children, ...rest }) {
	return (
		<Route
			{...rest}
			/* render={({ location }) =>
				localStorage.getItem("token") ? (
					children
				) : (
					<Navigate
						to={{
							pathname: AppPaths.LOGIN_PATH,
							state: { from: location },
						}}
					/>
				)
			} */
			element={localStorage.getItem("token") ? children : <Navigate to={AppPaths.LOGIN_PATH} replace />}
		/>
	);
}

import axios from "../../config/AxiosConfig";
import useSWR from "swr";

import { getLanguageId } from "../../helpers/helpers";
import {
  CONTENT_PAGE_API,
  LATEST_INVESTMENTS_API,
} from "../../store/actions/apiCollections";

export const contentPageFetcher = (url) =>
  axios
    .get(url, {
      params: {
        ...(getLanguageId() !== null && {
          language: getLanguageId(),
        }),
      },
    })
    .then((response) => response.data.data.data[0]);

export const latestInvestmentsFetcher = (url) =>
  axios.get(url).then((response) => response.data.data.data);

export const useContentPageFetcher = (slug) => {
  const { data, isValidating, error } = useSWR(
    `${CONTENT_PAGE_API}${slug}/front`,
    contentPageFetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    }
  );

  return { data, isValidating, error };
};

export const useLatestInvestmentsFetcher = () => {
  const { data: latestInvestments } = useSWR(
    LATEST_INVESTMENTS_API,
    latestInvestmentsFetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    }
  );

  return { latestInvestments };
};

import { put } from "redux-saga/effects";
import {
	getMyInvestmentsFail,
	getMyInvestmentsStart,
	getMyInvestmentsSuccess,
	getMyInvestmentsGraph,
	getLatestInvestmentsSuccess,
	getInvestmentUniqueCurrenciesSuccess,
	getInvestmentFullDetailSuccess,
	getInvestmentFullDetailFail,
	getInvestmentsChartDataSuccess,
	getInvestmentsColumnChartDataSuccess,
	getInvestmentDocumentDetailSuccess,
	getInvestmentDocumentDetailFail,
	setSpinner
} from "../../actions/investments/investmentsActions";
import {
	MY_INVESTMENTS_API,
	MY_INVESTMENTS_COLUMN_CHART_API,
	MY_INVESTMENTS_GRAPH_API,
	LATEST_INVESTMENTS_API,
	USER_INVESTMENT_UNIQUE_CURRENCIES_API,
	MY_INVESTMENTS_DASHBOARD_API
} from "../../actions/apiCollections";
import axios from "../../../config/AxiosConfig";

// use SWR - remove in future
export function* getMyInvestmentsSaga(action) {
	yield put(getMyInvestmentsStart(action));

	let InvestmentURL;
	let MyInvestmentDashboard;

	if (action.currencyId) {
		InvestmentURL = MY_INVESTMENTS_API + action.currencyId;
		MyInvestmentDashboard = MY_INVESTMENTS_DASHBOARD_API + action.currencyId;
	} else {
		InvestmentURL = MY_INVESTMENTS_API;
		MyInvestmentDashboard = MY_INVESTMENTS_DASHBOARD_API;
	}
	try {
		const dashboardResponse = yield axios.get(MyInvestmentDashboard);
		yield put(getMyInvestmentsGraph(dashboardResponse.data.data.data));

		const response = yield axios.get(InvestmentURL, {
			params: { limit: 10 * action.pageLimit }
		});
		yield put(getMyInvestmentsSuccess(response.data.data.data));
	} catch (error) {
		yield put(getMyInvestmentsFail(error));
	}
}

export function* getLatestInvestmentsSaga() {
	yield put(getMyInvestmentsStart());
	try {
		const response = yield axios.get(LATEST_INVESTMENTS_API);
		yield put(getLatestInvestmentsSuccess(response.data.data.data));
	} catch (error) {
		yield put(getMyInvestmentsFail(error));
	}
}

export function* getInvestmentsChartDataSaga(action) {
	const currencyId = action.currencyId === undefined ? "" : action.currencyId;
	try {
		const response = yield axios.get(
			`${MY_INVESTMENTS_GRAPH_API}/${currencyId}`
		);
		yield put(getInvestmentsChartDataSuccess(response.data.data.data));
	} catch (error) {
		yield put(getMyInvestmentsFail(error));
	}
}

export function* getInvestmentsColumnChartDataSaga(action) {
	const currencyId = action.currencyId === undefined ? "" : action.currencyId;
	try {
		const response = yield axios.get(MY_INVESTMENTS_COLUMN_CHART_API, {
			params: {
				...(currencyId !== "" && {
					currencyId
				}),
				...(action.transactionYear !== "" && {
					transactionYear: action.transactionYear
				})
			}
		});
		yield put(getInvestmentsColumnChartDataSuccess(response.data.data.data));
	} catch (error) {
		yield put(getMyInvestmentsFail(error));
	}
}

export function* getInvestmentUniqueCurrenciesSaga() {
	try {
		const response = yield axios.get(USER_INVESTMENT_UNIQUE_CURRENCIES_API);
		yield put(getInvestmentUniqueCurrenciesSuccess(response.data.data.data));
	} catch (error) {
		yield put(getMyInvestmentsFail(error));
	}
}

export function* getMyInvestmentFullDetailSaga(action) {
	try {
		const response = yield axios.get(
			`${MY_INVESTMENTS_API}${action.id}/get-full-detail`
		);
		yield put(getInvestmentFullDetailSuccess(response.data.data.data));
	} catch (error) {
		yield put(getInvestmentFullDetailFail(error));
	}
}

export function* getMyInvestmentDocumentDetailSaga(action) {
	yield put(setSpinner("documentDetailLoader", true));
	try {
		const response = yield axios.get(
			`${MY_INVESTMENTS_API}${action.id}/get-document-detail`
		);
		yield put(getInvestmentDocumentDetailSuccess(response.data.data.data));
	} catch (error) {
		yield put(setSpinner("documentDetailLoader", false));
		yield put(getInvestmentDocumentDetailFail(error));
	}
}

import * as actionTypes from "../../actions/payment/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
	paymentReceipt: "",
	paymentSuccessMsg: false,
	preapprovalDetails: null,
	stripeResponse: null,
	error: null,
	isPaymentProcessing: false,
};

const setPaymentReceipt = (state, action) => {
	return updateObject(state, {
		...(action.gatewayType === "stripe" && {
			stripeResponse: "",
		}),
		paymentSuccessMsg:
			action.gatewayType === "stripe" || action.gatewayType === "none"
				? false
				: true,
		paymentReceipt: action.receiptData,
		error: null,
		isPaymentProcessing: false,
	});
};

const setPreapprovalDetails = (state, action) => {
	return updateObject(state, {
		preapprovalDetails: action.preapprovalDetails,
		paymentReceipt: "",
		paymentSuccessMsg: "",
		error: null,
	});
};

const clearPreapprovalDetails = (state, action) => {
	return updateObject(state, {
		preapprovalDetails: null,
		paymentSuccessMsg: "",
		stripeResponse: null,
		isPaymentProcessing: false,
		error: null,
	});
};

const resetPaymentData = (state, action) => {
	return updateObject(state, {
		...(action.fieldName === "paymentSuccessMsg" && {
			paymentSuccessMsg: action.value,
		}),
		...(action.fieldName === "error" && {
			error: action.value,
		}),
		...(action.fieldName === "stripeResponse" && {
			stripeResponse: action.value,
		}),
		...(action.fieldName === "isPaymentProcessing" && {
			isPaymentProcessing: action.value,
		}),
	});
};

const setStripePaymentInitialResponse = (state, action) => {
	return updateObject(state, {
		stripeResponse: action.stripeData,
	});
};

const setPaymentErrors = (state, action) => {
	return updateObject(state, {
		error: action.error,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_PAYMENT_RECEIPT:
			return setPaymentReceipt(state, action);
		case actionTypes.SET_PREAPPROVAL_DETAILS:
			return setPreapprovalDetails(state, action);
		case actionTypes.CLEAR_PREAPPROVAL_DETAILS:
			return clearPreapprovalDetails(state, action);
		case actionTypes.SET_STRIPE_PAYMENT_INITIAL_RESPONSE:
			return setStripePaymentInitialResponse(state, action);
		case actionTypes.RESET_PAYMENT_DATA:
			return resetPaymentData(state, action);
		case actionTypes.SET_PAYMENT_ERRORS:
			return setPaymentErrors(state, action);
		default:
			return state;
	}
};
export default reducer;

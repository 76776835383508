import { preload } from "swr";

import {
	EQUITY_API,
	PAYMENT_GATEWAY_API,
	GET_WALLET_DETAILS_API,
	GET_WALLET_TRANSACTIONS_LIST_API,
	CONTENT_PAGE_API,
	STATISTICS_API,
	USER_PROFILE_API,
	USER_PROFILE_INVESTORS_API,
	USER_PROFILE_CAMAPAIGNS_API,
	MY_INVESTMENTS_API,
	MY_INVESTMENTS_DASHBOARD_API,
	MY_INVESTMENTS_GRAPH_API,
	USER_INVESTMENT_UNIQUE_CURRENCIES_API,
	MY_INVESTMENTS_COLUMN_CHART_API,
	USER_EMAIL_PREFERNECES_API,
	INVESTOR_PROFILE,
	COUNTRIES_API,
	GET_CONTRACT_API,
	GET_NORTH_KYC_STATUS_API,
	GET_MANGOPAY_CURRENT_WALLET_API,
	GET_MANGOPAY_TRANSACTIONS_LIST_API,
	GET_MANGOPAY_AUTHORIZED_COUNTRIES_API,
	POST_MANGOPAY_LINKED_BANK_ACCOUNTS_API,
	GET_MANGOPAY_BANK_DETAIL_API,
	EQUITY_SHARE_CERTIFICATE_API,
} from "../store/actions/apiCollections";
import { TermsConditionContentPageSlug } from "../constants";
import {
	dashboardStatisticsFetcher,
	dashboardCampaignFetcher,
} from "../swrHelpers/swrDashboard";
import { paymentGatewayDataFetcher } from "../swrHelpers/swrPayment";
import {
	walletDetailsFetcher,
	walletTransactionFetcher,
} from "../swrHelpers/swrWallet";
import { contentPageFetcher } from "../swrHelpers/swrContentPages";
import { campaignDetailFetcher } from "../swrHelpers/swrInvestment";
import {
	getUserProfileFetcher,
	getUserProfileCampaignsFetcher,
	getUserProfileInvestmentsFetcher,
} from "../swrHelpers/swrProfile";
import {
	myInvestmentFetcher,
	getMyInvestmentsGraphFetcher,
	getInvestmentsChartFetcher,
	getInvestmentUniqueCurrenciesFetcher,
	getInvestmentsColumnChartFetcher,
} from "../swrHelpers/swrMyInvestments";
import { getUserEmailPreferenceFetcher } from "../swrHelpers/swrSettings/emailPreferences";
import {
	getAccreditationContractFetcher,
	getContentPageFetcher,
	getCountriesFetcher,
	getInvestorProfileFetcher,
} from "../swrHelpers/swrSettings/investmentAccount";
import { getNorthKYCStatusFetcher } from "../swrHelpers/swrSettings/northKYC";

import {
	mangoPayDetailsFetcher,
	mangoPayTransactionFetcher,
	mangoPayAthorizedCountriesFetcher,
	getAllmangoPayBanksFetcher,
	mangoPayBankDetailFetcher,
} from "../swrHelpers/swrMangoPay";

import { equityShareCertificateFetcher } from "../swrHelpers/swrShareCertificate";

export const preloadDashboardData = () => {
	preload(STATISTICS_API, dashboardStatisticsFetcher);
	preload(`${EQUITY_API}?limit=6`, dashboardCampaignFetcher);
};

export const preloadPaymentGatewaySettings = () => {
	preload(`${PAYMENT_GATEWAY_API}offline/front`, paymentGatewayDataFetcher);
	preload(`${PAYMENT_GATEWAY_API}stripe/front`, paymentGatewayDataFetcher);
	preload(`${PAYMENT_GATEWAY_API}paypal/front`, paymentGatewayDataFetcher);
	preload(`${PAYMENT_GATEWAY_API}ach/front`, paymentGatewayDataFetcher);
};

export const preloadWalletData = (walletCurrencyId) => {
	if (walletCurrencyId) {
		preload(
			`${GET_WALLET_DETAILS_API}?&currencyId=${walletCurrencyId}`,
			walletDetailsFetcher
		);
		preload(
			`${GET_WALLET_TRANSACTIONS_LIST_API}?limit=10&currencyId=${walletCurrencyId}`,
			walletTransactionFetcher
		);
	}
};

export const preloadInvestmentData = (currencyId, campaignId, walletModule) => {
	if (walletModule === "yes" && currencyId) {
		preload(
			`${GET_WALLET_DETAILS_API}?&currencyId=${currencyId}`,
			walletDetailsFetcher
		);
	}

	preload(
		`${CONTENT_PAGE_API}investment-how-it-works/front`,
		contentPageFetcher
	);
	preload(EQUITY_API + campaignId, campaignDetailFetcher);
};

export const preloadUserProfileData = (userSlug, userId) => {
	userSlug ??
		preload(`${USER_PROFILE_API}${userSlug}/profile`, getUserProfileFetcher, {
			keepPreviousData: true,
		});
	userId ??
		preload(
			`${USER_PROFILE_INVESTORS_API}${userId}?limit=6`,
			getUserProfileInvestmentsFetcher,
			{
				keepPreviousData: true,
			}
		);
	userId ??
		preload(
			`${USER_PROFILE_CAMAPAIGNS_API}${userId}?limit=6`,
			getUserProfileCampaignsFetcher,
			{
				keepPreviousData: true,
			}
		);
};

export const preloadMyinvestmentData = (currencyId) => {
	preload(
		`${MY_INVESTMENTS_API}?limit=${10}${
			currencyId ? `&currencyId=${currencyId}` : ""
		}`,
		myInvestmentFetcher
	);
	preload(
		`${MY_INVESTMENTS_DASHBOARD_API}${
			currencyId ? `?currencyId=${currencyId}` : ""
		}`,
		getMyInvestmentsGraphFetcher
	);
	preload(
		`${MY_INVESTMENTS_GRAPH_API}${
			currencyId ? `?currencyId=${currencyId}` : ""
		}`,
		getInvestmentsChartFetcher
	);
	preload(
		`${USER_INVESTMENT_UNIQUE_CURRENCIES_API}`,
		getInvestmentUniqueCurrenciesFetcher
	);
	preload(
		`${MY_INVESTMENTS_COLUMN_CHART_API}?transactionYear=0${
			currencyId ? `&currencyId=${currencyId}` : ""
		}`,
		getInvestmentsColumnChartFetcher
	);
};

export const preloadProfileSettingData = () => {
	preloadInvestorProfile();
	preloadUserEmailPreferences();
	preloadUserKYCData();
};

export const preloadInvestorProfile = () => {
	preload(`${INVESTOR_PROFILE}`, getInvestorProfileFetcher, {
		keepPreviousData: true,
	});
	preload(
		`${CONTENT_PAGE_API}${TermsConditionContentPageSlug}/front`,
		getContentPageFetcher,
		{
			keepPreviousData: true,
		}
	);
	preload(`${COUNTRIES_API}`, getCountriesFetcher, {
		keepPreviousData: true,
	});
	preload(
		`${GET_CONTRACT_API}/accreditation-contract`,
		getAccreditationContractFetcher,
		{
			keepPreviousData: true,
		}
	);
};

export const preloadUserEmailPreferences = () => {
	preload(USER_EMAIL_PREFERNECES_API, getUserEmailPreferenceFetcher, {
		keepPreviousData: true,
	});
};

export const preloadUserKYCData = () => {
	preload(GET_NORTH_KYC_STATUS_API, getNorthKYCStatusFetcher, {
		keepPreviousData: true,
	});
};

export const preloadAthorizedCountriesData = () => {
	preload(
		GET_MANGOPAY_AUTHORIZED_COUNTRIES_API,
		mangoPayAthorizedCountriesFetcher,
		{
			keepPreviousData: true,
		}
	);
};

export const preloadAllMangoPayBanksData = () => {
	preload(POST_MANGOPAY_LINKED_BANK_ACCOUNTS_API, getAllmangoPayBanksFetcher, {
		keepPreviousData: true,
	});
};

export const preloadMangoPayBankData = (bankId) => {
	preload(
		`${GET_MANGOPAY_BANK_DETAIL_API}/${bankId}`,
		mangoPayBankDetailFetcher,
		{
			keepPreviousData: true,
		}
	);
};

export const preloadMangoPayData = (mangoPayCurrencyId) => {
	if (mangoPayCurrencyId) {
		preload(
			`${GET_MANGOPAY_CURRENT_WALLET_API}?&currency=${mangoPayCurrencyId}`,
			mangoPayDetailsFetcher
		);
		preload(
			`${GET_MANGOPAY_TRANSACTIONS_LIST_API}?currency=${mangoPayCurrencyId}`,
			mangoPayTransactionFetcher
		);
		preload(
			`${GET_MANGOPAY_AUTHORIZED_COUNTRIES_API}`,
			mangoPayAthorizedCountriesFetcher
		);
		preload(
			`${POST_MANGOPAY_LINKED_BANK_ACCOUNTS_API}`,
			getAllmangoPayBanksFetcher
		);
	}
};

// Share Certificate
export const preloadEquityShareCertificateData = () => {
	preload(EQUITY_SHARE_CERTIFICATE_API, equityShareCertificateFetcher, {
		keepPreviousData: true,
	});
};

import * as actionTypes from "../../actions/createCampaign/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
	error: [],
	type: [],
	categories: [],
	industries: [],
	teamMemberTypes: [],
	teamMembers: [],
	addEditTeamMemberLoader: false,
	teamMember: [],
	companyData: [],
	commonData: [],
	campaignStage: [],
	investingRound: [],
	investorType: [],
	dealTypes: [],
	campaigns: [],
	campaign: [],
	fundraising: [],
	loading: true,
	terms: [],
	story: [],
	highlights: [],
	extras: [],
	extrasImages: [],
	extrasImage: [],
	extrasVideos: [],
	extrasVideo: [],
	documents: [],
	doc: [],
	payment: [],
	equity: [],
	campaignSumitted: false,
	equityLoader: false,
	equities: [],
	equitiesSpinner: false,
	browseCompaniesSpinner: false,
	browseSuccessCompaniesSpinner: false,
	faqs: [],
	campaignDetailsFaqLoader: false,
	equityName: null,
	updates: [],
	update: [],
	campaignDashboardUpdateLoader: false,
	campaignDashboardFollowersLoader: false,
	publicEquities: [],
	publicSuccessEquities: [],
	campaignPage: [],
	publicUpdates: [],
	publicDocuments: [],
	publicDocumentsLoader: false,
	publicFaqs: [],
	answers: [],
	faqAnswersLoader: false,
	savedCampaigns: [],
	savedCampaignsLoader: false,
	campaignSaved: false,
	offlineBank: [],
	invest: [],
	reciept: null,
	stripeResponse: null,
	stripeObject: null,
	isLoading: true,
	reservation: null,
	investments: [],
	investmentsLoader: false,
	investmentSuccessMsg: false,
	paymentSuccessMsg: false,
	isWalletInvestmentComplete: false,
	repayment: [],
	repaymentReceipt: [],
	repaymentStripe: [],
	leadInvestors: [],
	addEditLeadInvestorLoader: false,
	singleLeadInvestor: [],
	customFaqs: [],
	takeAwayEstimate: [],
	resendEmail: false,
	globalLoading: true,
	investmentContract: null,
	investmentSignedContract: null,
	investmentSignedContractLoader: false,
	createdInvestment: false,
	completedCampaigns: [],
	stripeReciept: false,
	userSavedCampaigns: [],
	currentInvestmentCampaign: [],
	userSaveCampaignId: null,
	userProfileCampaigns: [],
	userProfileCampaignsLoader: false,
	userProfileCampaignsSpinner: false,
	userProfileInvestments: [],
	userProfileInvestmentsLoader: false,
	userProfileInvestmentsSpinner: false,
	shuftiProDetail: [],
	northCapitalDetails: [],
	northKycCreatePartySuccess: null, //remove this
	northKycDetails: null, //remove this
	northKycUpdateSuccess: null, //remove this
	northKycLoader: false, //remove this
	northKycError: null, //remove this
	northKycPartyErrors: null, //remove this
	northKycPartyWarnings: null, //remove this
	paypalUniqueToken: null,
	paypalSettingResponse: [],
	paypalConfirmPaymentResponse: null,
	userStripeConnectionURL: null,
	stripeConnectionMessage: null,
	ACHAccountList: null,
	achSettings: [],
	achPaymentConfirmToken: null,
	achPaymentConfirmationId: null,
	achRePaymentConfirmationId: null,
	achRePaymentCampaignStatus: null,
	wishLoading: false,
	equityGoogleAnalyticCode: null,
	userCampaignLimitError: null,
	paymentSettingLoader: false
};

const createCampaignStart = (state, action) => {
	return updateObject(state, {
		error: [],
		type: [],
		commonData: [],
		loading: true,
		fundraising: [],
		terms: [],
		extrasImage: [],
		doc: [],
		extrasVideo: [],
		update: [],
		publicUpdates: [],
		campaignSaved: false,
		offlinePayment: [],
		stripeResponse: null,
		paypalSettingResponse: [],
		stripeObject: null,
		isLoading: true,
		reciept: null,
		investments: [],
		investmentSuccessMsg: false,
		paymentSuccessMsg: false,
		repayment: [],
		repaymentDataLoader: false,
		isSpinning: undefined,
		companyData: [],
		campaignStage: [],
		investingRound: [],
		investorType: [],
		dealTypes: [],
		campaigns: [],
		campaign: [],
		story: [],
		highlights: [],
		extras: [],
		documents: [],
		payment: [],
		faqs: [],
		updates: [],
		savedCampaigns: [],
		offlineBank: [],
		invest: [],
		repaymentReceipt: [],
		repaymentStripe: [],
		stripeReciept: null,
		currentInvestmentCampaign: [],
		userSaveCampaignId: null,
		userProfileCampaigns: [], // remove this
		userProfileCampaignsLoader: true, // remove this
		userProfileCampaignsSpinner: true, // remove this
		userProfileInvestments: [], // remove this
		userProfileInvestmentsLoader: true, // remove this
		userProfileInvestmentsSpinner: true, // remove this
		shuftiProDetail: [],
		northCapitalDetails: [],
		paypalUniqueToken: null,
		paypalConfirmPaymentResponse: null,
		userStripeConnectionURL: null,
		stripeConnectionMessage: null,
		ACHAccountList: null,
		achSettings: [],
		achPaymentConfirmToken: null,
		achPaymentConfirmationId: null,
		achRePaymentConfirmationId: null,
		achRePaymentCampaignStatus: null,
		equityGoogleAnalyticCode: null,
		userCampaignLimitError: null,
		resendEmail: false
	});
};

const createCampaignFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		paypalConfirmPaymentResponse: null,
		userProfileCampaignsLoader: false,
		userProfileCampaignsSpinner: false,
		userProfileInvestmentsLoader: false,
		userProfileInvestmentsSpinner: false,
		browseCompaniesSpinner: false,
		browseSuccessCompaniesSpinner: false,
		campaignDetailsFaqLoader: false,
		publicDocumentsLoader: false,
		investmentsLoader: false,
		campaignDashboardUpdateLoader: false,
		campaignDashboardFollowersLoader: false,
		globalLoading: false,
		equityLoader: false,
		repaymentDataLoader: false,
		faqAnswersLoader: false,
		addEditTeamMemberLoader: false,
		addEditLeadInvestorLoader: false,
		investmentSignedContract: null,
		investmentSignedContractLoader: false,
		investmentContract: null,
		isWalletInvestmentComplete: false,
		campaignSumitted: false
	});
};

const clearCreateCampainData = (state, action) => {
	return updateObject(state, {
		teamMembers: [],
		leadInvestors: [],
		extrasImages: [],
		extrasVideos: [],
		documents: []
	});
};

const userCampaignLimitError = (state, action) => {
	return updateObject(state, {
		userCampaignLimitError: action.response
	});
};

const clearUserCampaignLimitErrorMessage = (state, action) => {
	return updateObject(state, {
		userCampaignLimitError: null
	});
};

const resetUpdate = (state, action) => {
	return updateObject(state, {
		update: []
	});
};

const resendEmail = (state, action) => {
	return updateObject(state, {
		resendEmail: action.response
	});
};

const isSpinning = (state, action) => {
	return updateObject(state, {
		isSpinning: action.response
	});
};

const getType = (state, action) => {
	return updateObject(state, {
		type: action.response
	});
};

const getCategories = (state, action) => {
	return updateObject(state, {
		categories: action.response
	});
};

const getIndustries = (state, action) => {
	return updateObject(state, {
		industries: action.response
	});
};

const getTeamMemberTypes = (state, action) => {
	return updateObject(state, {
		teamMemberTypes: action.response
	});
};

const getTeamMembers = (state, action) => {
	return updateObject(state, {
		teamMembers: action.response,
		teamMember: [],
		addEditTeamMemberLoader: false
	});
};

const getTeamMember = (state, action) => {
	return updateObject(state, {
		teamMember: action.response
	});
};

const clearTeamMember = (state, action) => {
	return updateObject(state, {
		teamMember: []
	});
};

const getCompanyData = (state, action) => {
	return updateObject(state, {
		companyData: action.response,
		isLoading: false
	});
};

const successCommon = (state, action) => {
	return updateObject(state, {
		commonData: action.response
	});
};

const getCampaignStage = (state, action) => {
	return updateObject(state, {
		campaignStage: action.response
	});
};

const getInvestingRound = (state, action) => {
	return updateObject(state, {
		investingRound: action.response
	});
};

const getInvestorType = (state, action) => {
	return updateObject(state, {
		investorType: action.response
	});
};

export const getDealTypes = (state, action) => {
	return updateObject(state, {
		dealTypes: action.response
		// equityId: action.response[0].id,
		// debtId: action.response[1].id
	});
};

export const getCampaigns = (state, action) => {
	return updateObject(state, {
		campaigns: action.response
	});
};

export const createCampaignTypeSuccess = (state, action) => {
	return updateObject(state, {
		campaign: action.response,
		loading: false
	});
};

export const getFundraising = (state, action) => {
	return updateObject(state, {
		fundraising: action.response
	});
};
export const getTerms = (state, action) => {
	return updateObject(state, {
		terms: action.response
	});
};

export const getStory = (state, action) => {
	return updateObject(state, {
		story: action.response
	});
};
export const getExtras = (state, action) => {
	return updateObject(state, {
		extras: action.response
	});
};

export const getExtrasImages = (state, action) => {
	return updateObject(state, {
		extrasImages: action.response,
		extrasImage: []
	});
};

export const getExtrasImage = (state, action) => {
	return updateObject(state, {
		extrasImage: action.response
	});
};

export const getExtrasDocuments = (state, action) => {
	return updateObject(state, {
		documents: action.response,
		doc: []
	});
};

export const getExtrasDocument = (state, action) => {
	return updateObject(state, {
		doc: action.response
	});
};

export const getPayment = (state, action) => {
	return updateObject(state, {
		payment: action.response
	});
};

export const getEquity = (state, action) => {
	return updateObject(state, {
		equity: action.response,
		termsId: action.response?.termsId?.id,
		highlights: action.response.highlights,
		equityName: action.response?.termsId?.name,
		error: [],
		isLoading: false,
		investmentSuccessMsg: false,
		campaignSumitted: false
	});
};
export const clearEquity = (state, action) => {
	return updateObject(state, {
		equity: []
	});
};

export const getEquities = (state, action) => {
	return updateObject(state, {
		companyData: [],
		equities: action.response,
		isLoading: false,
		equitiesSpinner: false
	});
};

export const getEquitiesStart = (state, action) => {
	return updateObject(state, {
		equitiesSpinner: true
	});
};

export const getEquityUpdates = (state, action) => {
	return updateObject(state, {
		updates: action.response,
		campaignDashboardUpdateLoader: false
	});
};

export const getFaqs = (state, action) => {
	return updateObject(state, {
		faqs: action.response
	});
};

export const getCustomFaqs = (state, action) => {
	return updateObject(state, {
		customFaqs: action.response,
		faqAnswersLoader: false
	});
};

export const getAnswers = (state, action) => {
	return updateObject(state, {
		answers: action.response
	});
};

export const getEquityUpdate = (state, action) => {
	return updateObject(state, {
		update: action.response
	});
};

export const getPublicEquities = (state, action) => {
	return updateObject(state, {
		publicEquities: action.response,
		browseCompaniesSpinner: false
	});
};

export const getPublicSuccessEquities = (state, action) => {
	return updateObject(state, {
		publicSuccessEquities: action.response,
		browseSuccessCompaniesSpinner: false
	});
};

export const setSpinner = (state, action) => {
	return updateObject(state, {
		...(action.fieldName === "browseCompaniesSpinner" && {
			browseCompaniesSpinner: action.value
		}),
		...(action.fieldName === "browseSuccessCompaniesSpinner" && {
			browseSuccessCompaniesSpinner: action.value
		}),
		...(action.fieldName === "campaignDetailsFaqLoader" && {
			campaignDetailsFaqLoader: action.value
		}),
		...(action.fieldName === "publicDocumentsLoader" && {
			publicDocuments: [],
			publicDocumentsLoader: action.value
		}),
		...(action.fieldName === "investments" && {
			investments: [],
			investmentsLoader: action.value
		}),
		...(action.fieldName === "campaignDashboardUpdateLoader" && {
			updates: [],
			campaignDashboardUpdateLoader: action.value
		}),
		...(action.fieldName === "campaignDashboardFollowersLoader" && {
			userSavedCampaigns: [],
			campaignDashboardFollowersLoader: action.value
		}),
		...(action.fieldName === "equityLoader" && {
			equityLoader: action.value
		}),
		...(action.fieldName === "repaymentDataLoader" && {
			repayment: [],
			repaymentDataLoader: action.value
		}),
		...(action.fieldName === "faqAnswersLoader" && {
			customFaqs: [],
			answers: [],
			faqAnswersLoader: action.value
		}),
		...(action.fieldName === "savedCampaignsLoader" && {
			savedCampaigns: [],
			savedCampaignsLoader: action.value
		}),
		...(action.fieldName === "addEditTeamMemberLoader" && {
			addEditTeamMemberLoader: action.value
		}),
		...(action.fieldName === "addEditLeadInvestorLoader" && {
			addEditLeadInvestorLoader: action.value
		}),
		...(action.fieldName === "investmentSignedContractLoader" && {
			investmentSignedContractLoader: action.value,
			investmentSignedContract: null
		}),
		...(action.fieldName === "paymentSettingLoader" && {
			paymentSettingLoader: action.value
		}),
		...(action.fieldName === "investmentContract" && {
			investmentContract: null
		}),
		...(action.fieldName === "northKycLoader" && {
			northKycLoader: action.value
		}),
		...(action.fieldName === "campaignSumitted" && {
			campaignSumitted: action.value
		})
	});
};

export const getCampaignDetailPage = (state, action) => {
	return updateObject(state, {
		campaignPage: action.response,
		loading: false,
		error: false
	});
};

export const getPublicUpdates = (state, action) => {
	return updateObject(state, {
		publicUpdates: action.response
	});
};

export const getPublicDocuments = (state, action) => {
	return updateObject(state, {
		publicDocuments: action.response,
		publicDocumentsLoader: false
	});
};

export const getPublicFaqs = (state, action) => {
	return updateObject(state, {
		publicFaqs: action.response
	});
};

export const getSavedCampaigns = (state, action) => {
	return updateObject(state, {
		savedCampaigns: action.response,
		savedCampaignsLoader: false,
		wishLoading: false
	});
};

export const saveCampaignSuccess = (state, action) => {
	return updateObject(state, {
		campaignSaved: action.response
	});
};

export const getOfflineBank = (state, action) => {
	return updateObject(state, {
		offlineBank: action.response[0]
	});
};

export const getShuftiPro = (state, action) => {
	return updateObject(state, {
		...(action.response[0].paymentType === "shuftiprokyc" && {
			shuftiProDetail: action.response[0]
		}),
		...(action.response[0].paymentType === "northkyc" && {
			northCapitalDetails: action.response[0]
		})
	});
};
//remove this
export const createPartyNorthKycSuccess = (state, action) => {
	return updateObject(state, {
		northKycCreatePartySuccess: action.response,
		...(action.response.errors && {
			northKycPartyErrors: action.response.errors
		}),
		...(action.response.warnings && {
			northKycPartyWarnings: action.response.warnings
		})
	});
};
//remove this
export const clearNorthKycFiled = (state, action) => {
	return updateObject(state, {
		...(action.fieldName === "northKycPartyErrors" && {
			northKycPartyErrors: null
		}),
		...(action.fieldName === "northKycPartyWarnings" && {
			northKycPartyWarnings: null
		})
	});
};
//remove this
export const updatePartyNorthKycSuccess = (state, action) => {
	return updateObject(state, {
		northKycUpdatePartySuccess: action.response,
		...(action.response.errors && {
			northKycPartyErrors: action.response.errors
		}),
		...(action.response.warnings && {
			northKycPartyWarnings: action.response.warnings
		})
	});
};
//remove this
export const uploadPartyDocumentSuccess = (state, action) => {
	return updateObject(state, {
		uploadPartyDocumentSuccessMsg: action.response
	});
};
//remove this
export const getNorthKycStatusSuccess = (state, action) => {
	return updateObject(state, {
		northKycDetails: action.response,
		northKycError: null,
		northKycCreatePartySuccess: null,
		northKycUpdatePartySuccess: null
	});
};
//remove this
export const updateNorthKycStatusSuccess = (state, action) => {
	return updateObject(state, {
		northKycUpdateSuccess: action.response
	});
};
//remove this
export const northKycFail = (state, action) => {
	return updateObject(state, {
		northKycError: action.response,
		northKycCreatePartySuccess: null,
		northKycUpdatePartySuccess: null,
		northKycDetails: null,
		northKycUpdateSuccess: null,
		northKycLoader: false,
		northKycPartyErrors: null,
		northKycPartyWarnings: null
	});
};

export const getStripe = (state, action) => {
	return updateObject(state, {
		striperes: action.response[0]
	});
};

export const createOfflinePayment = (state, action) => {
	return updateObject(state, {
		offlinePayment: action.response,
		currentInvestmentCampaign: []
	});
};

export const walletInvestmentCompleteSuccess = (state, action) => {
	return updateObject(state, {
		isWalletInvestmentComplete: action.response
	});
};

export const walletInvestmentPaymentSuccess = (state, action) => {
	return updateObject(state, {
		paymentSuccessMsg: true,
		currentInvestmentCampaign: []
	});
};

export const getCurrentInvest = (state, action) => {
	return updateObject(state, {
		invest: action.response
	});
};

export const getCurrentInvestByCampaignidSuccess = (state, action) => {
	return updateObject(state, {
		currentInvestmentCampaign: action.response
	});
};

export const getReceipt = (state, action) => {
	return updateObject(state, {
		reciept: action.response,
		isLoading: false,
		paypalConfirmPaymentResponse: null,
		stripeReciept: action.response.gatewayId?.paymentType === "stripe" && true,
		paymentSuccessMsg: false
	});
};

export const stripeResponse = (state, action) => {
	return updateObject(state, {
		stripeResponse: action.response
	});
};
export const getStripeResponse = (state, action) => {
	return updateObject(state, {
		stripeResponse: action.response
	});
};
export const getCurrentReservation = (state, action) => {
	return updateObject(state, {
		reservation: action.response
	});
};

export const getInvestments = (state, action) => {
	return updateObject(state, {
		investments: action.response,
		investmentsLoader: false
	});
};

const getRepayment = (state, action) => {
	return updateObject(state, {
		repayment: action.response,
		paypalConfirmPaymentResponse: null,
		repaymentDataLoader: false
	});
};

const getRepaymentReceipt = (state, action) => {
	return updateObject(state, {
		repaymentReceipt: action.response
	});
};

const repaymentStripe = (state, action) => {
	return updateObject(state, {
		repaymentStripe: action.response
	});
};

const getLeadInvestors = (state, action) => {
	return updateObject(state, {
		leadInvestors: action.response,
		singleLeadInvestor: [],
		addEditLeadInvestorLoader: false
	});
};

const getSingleLeadInvestor = (state, action) => {
	return updateObject(state, {
		singleLeadInvestor: action.response
	});
};

const clearLeadInvestor = (state, action) => {
	return updateObject(state, {
		singleLeadInvestor: []
	});
};

const getTakeAwayEstimate = (state, action) => {
	return updateObject(state, {
		takeAwayEstimate: action.response
	});
};

const getExtrasVideos = (state, action) => {
	return updateObject(state, {
		extrasVideos: action.response,
		extrasVideo: []
	});
};

const getExtrasVideo = (state, action) => {
	return updateObject(state, {
		extrasVideo: action.response
	});
};

const isLoading = (state, action) => {
	return updateObject(state, {
		globalLoading: action.response
	});
};

const createdInvestment = (state, action) => {
	return updateObject(state, {
		createdInvestment: true,
		isLoading: false,
		investmentSuccessMsg: true
	});
};

const getInvestmentContractSuccess = (state, action) => {
	return updateObject(state, {
		investmentSignedContract: null,
		investmentContract: action.response,
		investmentSignedContractLoader: false
	});
};
const getSignedContractSuccess = (state, action) => {
	return updateObject(state, {
		investmentSignedContract: action.response,
		investmentSignedContractLoader: false
	});
};

const updateInvestment = (state, action) => {
	return updateObject(state, {
		investmentSuccessMsg: true,
		paymentSuccessMsg:
			action.response === "stripe" || action.response === "investment"
				? false
				: true
	});
};

const getCampaignCompletedSuccess = (state, action) => {
	return updateObject(state, {
		completedCampaigns: action.response
	});
};

const stripeRecieptSuccess = (state, action) => {
	return updateObject(state, {
		stripeReciept: action.response,
		isLoading: false
	});
};

const getSavedCampaignUsers = (state, action) => {
	return updateObject(state, {
		userSavedCampaigns: action.response,
		campaignDashboardFollowersLoader: false
	});
};

const clearExtrasDocument = (state, action) => {
	return updateObject(state, {
		doc: []
	});
};

const clearExtrasVideo = (state, action) => {
	return updateObject(state, {
		extrasVideo: []
	});
};

const clearExtrasImage = (state, action) => {
	return updateObject(state, {
		extrasImage: []
	});
};

const clearCurrentInvestByCampaignid = (state, action) => {
	return updateObject(state, {
		currentInvestmentCampaign: []
	});
};

export const userSaveCampaignSuccess = (state, action) => {
	return updateObject(state, {
		userSaveCampaignId: action.response
	});
};

const clearStripeResponse = (state, action) => {
	return updateObject(state, {
		stripeResponse: null
	});
};

const clearEquityGoogleAnalyticCode = (state, action) => {
	return updateObject(state, {
		equityGoogleAnalyticCode: null
	});
};

export const equityGoogleAnalyticCode = (state, action) => {
	return updateObject(state, {
		equityGoogleAnalyticCode: action.response
	});
};
// remove this reducer
const getUserProfileInvestorSuccess = (state, action) => {
	return updateObject(state, {
		userProfileInvestments: action.response,
		userProfileInvestmentsLoader: false,
		userProfileInvestmentsSpinner: false
	});
};
// remove this reducer
const getUserProfileCampaignsSuccess = (state, action) => {
	return updateObject(state, {
		userProfileCampaigns: action.response,
		userProfileCampaignsLoader: false,
		userProfileCampaignsSpinner: false
	});
};

const getPayPalGenerateTokenSuccess = (state, action) => {
	return updateObject(state, {
		paypalUniqueToken: action.response
	});
};

const getPayPalSettingResponse = (state, action) => {
	return updateObject(state, {
		paypalSettingResponse: action.response[0],
		paypalConfirmPaymentResponse: null,
		paymentSuccessMsg: false
	});
};

const getPayPalConfirmPaymentSuccess = (state, action) => {
	return updateObject(state, {
		paypalConfirmPaymentResponse: action.response
	});
};
const clearPaypalConfirmPaymentResponse = (state, action) => {
	return updateObject(state, {
		paypalConfirmPaymentResponse: null
	});
};
const getUserStripeConnectionSuccess = (state, action) => {
	return updateObject(state, {
		userStripeConnectionURL: action.response
	});
};

const clearStripeConnectionUrl = (state, action) => {
	return updateObject(state, {
		userStripeConnectionURL: null
	});
};

const getStripeACHConnectedSuccess = (state, action) => {
	return updateObject(state, {
		stripeConnectionMessage: action.response,
		globalLoading: false
	});
};

const getStripeACHAllUserAccountsSuccess = (state, action) => {
	return updateObject(state, {
		ACHAccountList: action.response,
		globalLoading: false
	});
};

const clearStripeACHConnectedMessage = (state, action) => {
	return updateObject(state, {
		stripeConnectionMessage: null
	});
};

const getACHSettingSuccess = (state, action) => {
	return updateObject(state, {
		achSettings: action.response[0],
		paymentSuccessMsg: false
	});
};

const clearStripeACHPaymentToken = (state, action) => {
	return updateObject(state, {
		achPaymentConfirmToken: null
	});
};

const getStripeACHPaymentTokenSuccess = (state, action) => {
	return updateObject(state, {
		achPaymentConfirmToken: action.response
	});
};

const postStripeACHPaymentConfimationSuccess = (state, action) => {
	return updateObject(state, {
		achPaymentConfirmationId: action.response
	});
};

const clearStripeACHPaymentConfimation = (state, action) => {
	return updateObject(state, {
		achPaymentConfirmationId: null
	});
};

const postStripeACHRePaymentConfimationSuccess = (state, action) => {
	return updateObject(state, {
		achRePaymentConfirmationId: action.response
	});
};

const clearStripeACHRePaymentConfimation = (state, action) => {
	return updateObject(state, {
		achRePaymentConfirmationId: null
	});
};

const checkCampaignStripeAchRepaymentSuccess = (state, action) => {
	return updateObject(state, {
		achRePaymentCampaignStatus: action.response
	});
};

const wishLoading = (state, action) => {
	return updateObject(state, {
		wishLoading: action.response
	});
};

const clearInvestmentPreaprovalData = (state, action) => {
	return updateObject(state, {
		investmentSignedContract: null,
		investmentSuccessMsg: false,
		currentInvestmentCampaign: []
	});
};

//
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CAMPAIGNS_START:
			return createCampaignStart(state, action);
		case actionTypes.CLEAR_CREATE_CAMPAIGN_DATA:
			return clearCreateCampainData(state, action);
		case actionTypes.IS_SPINNING:
			return isSpinning(state, action);
		case actionTypes.CAMPAIGNS_FAIL:
			return createCampaignFail(state, action);
		case actionTypes.GET_TYPE_SUCCESS:
			return getType(state, action);
		case actionTypes.GET_CATEGORIES_SUCCESS:
			return getCategories(state, action);
		case actionTypes.GET_INDUSTRIES_SUCCESS:
			return getIndustries(state, action);
		case actionTypes.GET_TEAM_MEMBER_TYPE_SUCCESS:
			return getTeamMemberTypes(state, action);
		case actionTypes.GET_TEAM_MEMBERS_SUCCESS:
			return getTeamMembers(state, action);
		case actionTypes.GET_TEAM_MEMBER_SUCCESS:
			return getTeamMember(state, action);
		case actionTypes.CLEAR_TEAM_MEMBER:
			return clearTeamMember(state, action);
		case actionTypes.GET_BASIC_COMPANY_INFORMATION_SUCCESS:
			return getCompanyData(state, action);
		case actionTypes.GET_CAMPAIGN_STAGE_SUCCESS:
			return getCampaignStage(state, action);
		case actionTypes.GET_INVESTING_ROUND_SUCCESS:
			return getInvestingRound(state, action);
		case actionTypes.GET_INVESTOR_SUCCESS:
			return getInvestorType(state, action);
		case actionTypes.GET_DEAL_TYPES_SUCCESS:
			return getDealTypes(state, action);
		case actionTypes.GET_CAMPAIGNS_SUCCESS:
			return getCampaigns(state, action);
		case actionTypes.CREATE_CAMPAIGN_TYPE_SUCCESS:
			return createCampaignTypeSuccess(state, action);
		case actionTypes.GET_FUNDRAISING_SUCCESS:
			return getFundraising(state, action);
		case actionTypes.GET_TERM_SUCCESS:
			return getTerms(state, action);
		case actionTypes.GET_STORY_SUCCESS:
			return getStory(state, action);
		case actionTypes.GET_EXTRAS_SUCCESS:
			return getExtras(state, action);
		case actionTypes.GET_EXTRAS_IMAGES_SUCCESS:
			return getExtrasImages(state, action);
		case actionTypes.GET_EXTRAS_IMAGE_SUCCESS:
			return getExtrasImage(state, action);
		case actionTypes.GET_EXTRAS_DOCUMENTS_SUCCESS:
			return getExtrasDocuments(state, action);
		case actionTypes.GET_EXTRAS_DOCUMENT_SUCCESS:
			return getExtrasDocument(state, action);
		case actionTypes.GET_FUNDING_SUCCESS:
			return getPayment(state, action);
		case actionTypes.GET_EQUITIES_START:
			return getEquitiesStart(state, action);
		case actionTypes.GET_EQUITIES_SUCCESS:
			return getEquities(state, action);
		case actionTypes.GET_EQUITY_SUCCESS:
			return getEquity(state, action);
		case actionTypes.GET_FAQS_SUCCESS:
			return getFaqs(state, action);
		case actionTypes.GET_CUSTOM_FAQS_SUCCESS:
			return getCustomFaqs(state, action);
		case actionTypes.GET_ANSWER_SUCCESS:
			return getAnswers(state, action);
		case actionTypes.GET_EQUITY_UPDATES_SUCCESS:
			return getEquityUpdates(state, action);
		case actionTypes.GET_EQUITY_UPDATE_SUCCESS:
			return getEquityUpdate(state, action);
		case actionTypes.GET_PUBLIC_EQUITIES_SUCCESS:
			return getPublicEquities(state, action);
		case actionTypes.GET_PUBLIC_SUCCESS_EQUITIES_SUCCESS:
			return getPublicSuccessEquities(state, action);
		case actionTypes.SET_SPINNER:
			return setSpinner(state, action);
		case actionTypes.GET_CAMPAIGN_PAGE_SUCCESS:
			return getCampaignDetailPage(state, action);
		case actionTypes.GET_PUBLIC_UPDATES_SUCCESS:
			return getPublicUpdates(state, action);
		case actionTypes.GET_PUBLIC_DOCUMENTS_SUCCESS:
			return getPublicDocuments(state, action);
		case actionTypes.GET_PUBLIC_FAQS_SUCCESS:
			return getPublicFaqs(state, action);
		case actionTypes.GET_SAVE_CAMPAIGN_SUCCESS:
			return getSavedCampaigns(state, action);
		case actionTypes.SAVE_CAMPAIGN_SUCCESS:
			return saveCampaignSuccess(state, action);
		case actionTypes.GET_OFFLINE_SUCCESS:
			return getOfflineBank(state, action);
		case actionTypes.GET_SHUFTI_PRO_KYC_SUCCESS:
			return getShuftiPro(state, action);
		//remove this
		case actionTypes.CREATE_PARTY_NORTH_KYC_SUCCESS:
			return createPartyNorthKycSuccess(state, action);
		//remove this
		case actionTypes.CLEAR_NORTH_KYC_FILELD:
			return clearNorthKycFiled(state, action);
		//remove this
		case actionTypes.UPDATE_PARTY_NORTH_KYC_SUCCESS:
			return updatePartyNorthKycSuccess(state, action);
		//remove this
		case actionTypes.UPLOAD_PARTY_DOCUMENT_SUCCESS:
			return uploadPartyDocumentSuccess(state, action);
		//remove this
		case actionTypes.GET_NORTH_KYC_STATUS_SUCCESS:
			return getNorthKycStatusSuccess(state, action);
		//remove this
		case actionTypes.UPDATE_NORTH_KYC_STATUS_SUCCESS:
			return updateNorthKycStatusSuccess(state, action);
		//remove this
		case actionTypes.NORTH_KYC_FAIL:
			return northKycFail(state, action);
		case actionTypes.CREATE_OFFLINE_PAYMENT_SUCCESS:
			return createOfflinePayment(state, action);
		case actionTypes.WALLET_INVESTMENT_COMPLETE_SUCCESS:
			return walletInvestmentCompleteSuccess(state, action);
		case actionTypes.WALLET_INVESTMENT_PAYMENT_SUCCESS:
			return walletInvestmentPaymentSuccess(state, action);
		case actionTypes.GET_CURRENT_INVEST_SUCCESS:
			return getCurrentInvest(state, action);
		case actionTypes.GET_CURRENT_INVEST_BY_CAMPAIGNID_SUCCESS:
			return getCurrentInvestByCampaignidSuccess(state, action);
		case actionTypes.GET_RECIEPT_SUCCESS:
			return getReceipt(state, action);
		case actionTypes.STRIPE_PAYMENT_SUCCESS:
			return stripeResponse(state, action);
		case actionTypes.GET_STRIPE_RESPONSE_SUCCESS:
			return getStripeResponse(state, action);
		case actionTypes.GET_PAYPAL_SETTING_SUCCESS:
			return getPayPalSettingResponse(state, action);
		case actionTypes.GET_TOTAL_RESERVARTIONS_SUCCESS:
			return getCurrentReservation(state, action);
		case actionTypes.GET_INVESTMENTS_SUCCESS:
			return getInvestments(state, action);
		case actionTypes.GET_STRIPE_SUCCESS:
			return getStripe(state, action);
		case actionTypes.GET_REPAYMENT_SUCCESS:
			return getRepayment(state, action);
		case actionTypes.GET_SINGLE_REPAYMENT_SUCCESS:
			return getRepaymentReceipt(state, action);
		case actionTypes.REPAYMENT_STRIPE_SUCCESS:
			return repaymentStripe(state, action);
		case actionTypes.GET_LEAD_INVESTORS_SUCCESS:
			return getLeadInvestors(state, action);
		case actionTypes.GET_LEAD_INVESTOR_SUCCESS:
			return getSingleLeadInvestor(state, action);
		case actionTypes.CLEAR_LEAD_INVESTOR:
			return clearLeadInvestor(state, action);
		case actionTypes.GET_TAKEAWAY_ESTIMATE_SUCCESS:
			return getTakeAwayEstimate(state, action);
		case actionTypes.GET_EXTRAS_VIDEOS_SUCCESS:
			return getExtrasVideos(state, action);
		case actionTypes.GET_EXTRAS_VIDEO_SUCCESS:
			return getExtrasVideo(state, action);
		case actionTypes.RESEND_EMAIL_SUCCESS:
			return resendEmail(state, action);
		case actionTypes.CREATE_INVESTMENT_SUCCESS:
			return createdInvestment(state, action);
		case actionTypes.GET_INVESTMENT_CONTRACT_SUCCESS:
			return getInvestmentContractSuccess(state, action);
		case actionTypes.GET_SIGNED_CONTRACT_SUCCESS:
			return getSignedContractSuccess(state, action);
		case actionTypes.UPDATE_INVESTMENT_SUCCESS:
			return updateInvestment(state, action);
		case actionTypes.GET_CAMPAIGN_COMPLETED_SUCCESS:
			return getCampaignCompletedSuccess(state, action);
		case actionTypes.STRIPE_UPDATE_SUCCESS:
			return stripeRecieptSuccess(state, action);
		case actionTypes.IS_LOADING:
			return isLoading(state, action);
		case actionTypes.SUCCESS_COMMON:
			return successCommon(state, action);
		case actionTypes.CLEAR_EQUITY:
			return clearEquity(state, action);
		case actionTypes.RESET_EQUITY_UPDATE:
			return resetUpdate(state, action);
		case actionTypes.GET_FOLLOWERS_CAMPAIGN_SUCCESS:
			return getSavedCampaignUsers(state, action);
		case actionTypes.CLEAR_EXTRAS_IMAGE:
			return clearExtrasImage(state, action);
		case actionTypes.CLEAR_EXTRAS_DOCUMEN:
			return clearExtrasDocument(state, action);
		case actionTypes.CLEAR_EXTRAS_VIDEO:
			return clearExtrasVideo(state, action);
		case actionTypes.GET_USER_SAVE_CAMPAIGNID_SUCCESS:
			return userSaveCampaignSuccess(state, action);
		case actionTypes.CLEAR_CURRENT_INVEST_BY_CAMPAIGNID:
			return clearCurrentInvestByCampaignid(state, action);
		case actionTypes.CLEAR_STRIPE_RESPONSE:
			return clearStripeResponse(state, action);
		case actionTypes.CLEAR_EQUITY_GOOGLE_ANALYTIC_CODE:
			return clearEquityGoogleAnalyticCode(state, action);
		case actionTypes.EQUITY_GOOGLE_ANALYTIC_CODE:
			return equityGoogleAnalyticCode(state, action);
		case actionTypes.GET_USER_PROFILE_INVESTORS_SUCCESS: // remove this case
			return getUserProfileInvestorSuccess(state, action);
		case actionTypes.GET_USER_PROFILE_CAMPAIGNS_SUCCESS: // remove this case
			return getUserProfileCampaignsSuccess(state, action);
		case actionTypes.GET_PAYPAL_GENERATE_TOKEN_SUCCESS:
			return getPayPalGenerateTokenSuccess(state, action);
		case actionTypes.GET_PAYPAL_CONFIRM_PAYMENT_SUCCESS:
			return getPayPalConfirmPaymentSuccess(state, action);
		case actionTypes.CLEAR_PAYPAL_CONFIRM_PAYMENT_RESPONSE:
			return clearPaypalConfirmPaymentResponse(state, action);
		case actionTypes.GET_STRIPE_ACH_CONNECTION_URL_SUCCESS:
			return getUserStripeConnectionSuccess(state, action);
		case actionTypes.CLEAR_STRIPE_ACH_CONNECTION_URL:
			return clearStripeConnectionUrl(state, action);
		case actionTypes.POST_STRIPE_ACH_CONNECT_SUCCESS:
			return getStripeACHConnectedSuccess(state, action);
		case actionTypes.GET_STRIPE_ACH_ALL_USER_ACCOUNTS_SUCCESS:
			return getStripeACHAllUserAccountsSuccess(state, action);
		case actionTypes.CLEAR_STRIPE_ACH_CONNECT_SUCCESS_MSG:
			return clearStripeACHConnectedMessage(state, action);
		case actionTypes.GET_ACH_SETTING_SUCCESS:
			return getACHSettingSuccess(state, action);
		case actionTypes.GET_STRIPE_ACH_PAYMENT_TOKEN_SUCCESS:
			return getStripeACHPaymentTokenSuccess(state, action);
		case actionTypes.CLEAR_STRIPE_ACH_PAYMENT_TOKEN:
			return clearStripeACHPaymentToken(state, action);
		case actionTypes.POST_STRIPE_ACH_PAYMENT_CONFIMATION_SUCCESS:
			return postStripeACHPaymentConfimationSuccess(state, action);
		case actionTypes.CLEAR_STRIPE_ACH_PAYMENT_CONFIMATION:
			return clearStripeACHPaymentConfimation(state, action);
		case actionTypes.POST_STRIPE_ACH_RE_PAYMENT_CONFIMATION_SUCCESS:
			return postStripeACHRePaymentConfimationSuccess(state, action);
		case actionTypes.CLEAR_STRIPE_ACH_RE_PAYMENT_CONFIMATION:
			return clearStripeACHRePaymentConfimation(state, action);
		case actionTypes.CHECK_CAMPAIGN_STRIPE_ACH_RE_PAYMENT_SUCCESS:
			return checkCampaignStripeAchRepaymentSuccess(state, action);
		case actionTypes.WISH_LOADING:
			return wishLoading(state, action);
		case actionTypes.CLEAR_INVESTMENT_PREAPROVAL_DATA:
			return clearInvestmentPreaprovalData(state, action);
		case actionTypes.USER_CAMPAIGNS_LIMIT_FAIL:
			return userCampaignLimitError(state, action);
		case actionTypes.CLEAR_USER_CAMPAIGNS_LIMIT_MESSAGE:
			return clearUserCampaignLimitErrorMessage(state, action);

		default:
			return state;
	}
};

export default reducer;

export const getURL = () => {
	const url = document.location.href;
	return url.substring(url.lastIndexOf("/") + 1)?.split("?")[0];
};

// LOCAL : http://localhost:3000/equityrealestate/
// AWS DEV URL: https://reactdev.equityfundingscript.com/
// AWS PROD URL: https://reactdemo.equityfundingscript.com/
// AWS REAL ESTATE URL: https://realestatefront.equityfundingscript.com/

export const getBaseURL = () => {
	const url = process.env.REACT_APP_BASE_URL;
	return url;
};

// LOCAL : http://localhost:3000/equityrealestate/
// AWS DEV URL: https://reactdev.equityfundingscript.com/
// AWS PROD URL: https://reactdemo.equityfundingscript.com/
// AWS REAL ESTATE ADMIN URL: https://realestateadmin.equityfundingscript.com/

export const getExternalURL = () => {
	const url = process.env.REACT_APP_EXTERNAL_URL;
	return url;
};

// AWS EQUITY DEV URL: https://d1623jjeo3lld4.cloudfront.net/
// AWS EQUITY DEMO URL: https://d13airn8ndl3pd.cloudfront.net/
// AWS REAL ESTATE S3 URL: https://d1x0dieh83herg.cloudfront.net/

export const getBaseImageURL = () => {
	const url = process.env.REACT_APP_AWS_URL;
	return url;
};

import axios from "../../config/AxiosConfig";
import useSWRMutation from "swr/mutation";
import useSWR from "swr";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import {
	EQUITY_API,
	INVESTMENT_API,
	WALLET_PAYMENT_API,
	PAYMENT_GATEWAY_API,
	PAYPAL_CONFIRM_PAYMENT_API,
	STRIPE_API,
	WALLET_STRIPE_PAYMENT_API,
} from "../../store/actions/apiCollections";
import { getCookie } from "../../helpers/cookieHelper";
import { resetPaymentData } from "../../store/actions";
import { getLanguageCode } from "../../helpers";

const queryParams = {
	params: {
		...(getLanguageCode() != null && {
			lng: getLanguageCode(),
		}),
	},
};

export const paymentGatewayDataFetcher = (url) =>
	axios.get(url).then((response) => response.data.data.data[0]);

export const postWalletPaymentFetcher = (url, { arg }) =>
	axios
		.post(url, arg)
		.then((response) => response.data.data.data)
		.catch((error) => error.response.data);

export const paymentGatewayTokenFetcher = (url) =>
	axios.post(url).then((response) => response.data.data.data);

export const achPaymentTokenFetcher = (url) =>
	axios.get(url).then((response) => response.data.data.data);

export const dwollaPaymentTokenFetcher = (url) =>
	axios.get(url).then((response) => response.data.data.data);

export const dwollaExchangeTokenFetcher = async (url, { arg }) => {
	try {
		return await axios
			.post(url, arg, queryParams)
			.then((response) => response.data.data.data);
	} catch (error) {
		throw error.response.data;
	}
};

export const investmentDataFetcher = (url) =>
	axios.get(url).then((response) => response.data.data.data);

export const patchPaymentFetcher = (url, { arg }) =>
	axios.patch(url, arg).then((response) => response.data.data.data);

export const updateRepayment = (url, { arg }) =>
	axios
		.patch(url + arg.get("repaymentId") + "/process-repayment", arg)
		.then((response) => response.data.data.data);

export const usePaymentGatewayData = (gatewayType) => {
	const { data } = useSWR(
		`${PAYMENT_GATEWAY_API}${gatewayType}/front`,
		paymentGatewayDataFetcher,
		{
			keepPreviousData: true,
		}
	);

	return { data };
};

export const useUpdatePayment = () => {
	const dispatch = useDispatch();
	const {
		data: updatePaymentData,
		trigger: updatePaymentTrigger,
		isMutating: updatePaymentMutating,
	} = useSWRMutation(
		`${INVESTMENT_API}${getCookie("investorId")}`,
		patchPaymentFetcher
	);
	useEffect(() => {
		dispatch(resetPaymentData("isPaymentProcessing", updatePaymentMutating));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatePaymentMutating]);
	return { updatePaymentData, updatePaymentTrigger, updatePaymentMutating };
};

export const usePostWalletPayment = (preapprovalId) => {
	const dispatch = useDispatch();
	const {
		data: walletPaymentData,
		trigger: walletPaymentTrigger,
		isMutating: walletPaymentMutating,
	} = useSWRMutation(
		`${WALLET_PAYMENT_API}/${preapprovalId}`,
		postWalletPaymentFetcher
	);
	useEffect(() => {
		dispatch(resetPaymentData("isPaymentProcessing", walletPaymentMutating));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [walletPaymentMutating]);

	return { walletPaymentData, walletPaymentTrigger, walletPaymentMutating };
};

export const useUpdateRepayment = () => {
	const dispatch = useDispatch();
	const {
		data: updateRepaymentData,
		trigger: updateRepaymentTrigger,
		isMutating: updateRepaymentMutating,
	} = useSWRMutation(EQUITY_API, updateRepayment);

	useEffect(() => {
		dispatch(resetPaymentData("isPaymentProcessing", updateRepaymentMutating));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateRepaymentMutating]);

	return {
		updateRepaymentData,
		updateRepaymentTrigger,
		updateRepaymentMutating,
	};
};

export const usePaypalConfirmPayment = () => {
	const dispatch = useDispatch();
	const {
		data: confirmedPaypalPayment,
		trigger: confirmedPaypalPaymentTrigger,
		isMutating: confirmedPaypalPaymentMutating,
	} = useSWRMutation(`${PAYPAL_CONFIRM_PAYMENT_API}`, postWalletPaymentFetcher);

	useEffect(() => {
		dispatch(
			resetPaymentData("isPaymentProcessing", confirmedPaypalPaymentMutating)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [confirmedPaypalPaymentMutating]);

	return {
		confirmedPaypalPayment,
		confirmedPaypalPaymentTrigger,
		confirmedPaypalPaymentMutating,
	};
};

export const useStripeResponse = (paymentFor) => {
	const dispatch = useDispatch();

	const {
		data: stripeResponse,
		trigger,
		isMutating,
	} = useSWRMutation(
		paymentFor === "topup" ? WALLET_STRIPE_PAYMENT_API : STRIPE_API,
		postWalletPaymentFetcher
	);

	useEffect(() => {
		dispatch(
			resetPaymentData("isPaymentProcessing", !stripeResponse && isMutating)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMutating, stripeResponse]);

	return {
		stripeResponse,
		trigger,
		isMutating,
	};
};

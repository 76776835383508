import { put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import { TAXONOMY_API } from "../actions/apiCollections";
import { getTaxonomyFail, getTaxonomySuccess } from "../actions/index";
import { getLanguageId } from "../../helpers/helpers";

export function* getTaxonomy() {
	try {
		const response = yield axios.get(TAXONOMY_API, {
			params: {
				...(getLanguageId() !== null && {
					language: getLanguageId()
				})
			}
		});
		yield put(getTaxonomySuccess(response.data.data.data));
	} catch (error) {
		yield put(getTaxonomyFail(error.response));
	}
}

/**
 * @description This file contains all the actions for EmailPreferences.js component.
 * 				This file is further divided in 3 parts respectively i.e. GET / POST / PATCH
 * 				Always `register` your action in the current `index.js`
 * @workflow Action->dispatch->Saga (API) -> @returns Action -> Reducer -> Component
 */

import * as actionTypes from "./actionTypes";
/**
 * Get Email Preferences Actions
 */

/**
 * This action will indicate that we are in the start phase of calling the API to get the User Email Preferences
 * It returns an Object the the current action which has been dispatched
 * @returns {Object} Object
 */
// remove this
export const getUserEmailPreferencesStart = () => {
	return {
		type: actionTypes.GET_USER_EMAIL_PREFERNECES_START,
	};
};

/**
 * This actions is dispatched when we get a successful response from our Saga for the same,
 * It returns an Object with an Object with current action and response, further we access the Object's response
 * @usage `respone : state.emailPreferences.response`
 * @param {*} response
 * @returns {*} `Object`
 */
// remove this
export const getUserEmailPreferencesSuccess = (response) => {
	return {
		type: actionTypes.GET_USER_EMAIL_PREFERNECES_SUCCESS,
		response: response,
	};
};

/**
 * This actions is dispatched from saga if there is any issue with API call via axios
 * It returns an Object with an Object with current action and response, further we access the Object's response
 * @param {*} error
 * @returns {*} `Object`
 */
// remove this
export const getUserEmailPreferencesFail = (error) => {
	return {
		type: actionTypes.GET_USER_EMAIL_PREFERNECES_FAIL,
		errors: error,
	};
};
/**
 * This action is dispatched from the Component, in this case `EmailPreferences.js`, it contains an
 * `Object` with form details from the user.
 * @param {*} userData
 * @returns {*} `Object`
 */
// remove this
export const getUserPreferences = (userData) => {
	return {
		type: actionTypes.GET_USER_EMAIL_PREFERNECES,
		userData,
	};
};

/**
 * Create Email Preferences Action
 */

/**
 * This action will indicate that we are in the start phase of calling the API to create the User Email Preferences
 * It returns an Object the the current action which has been dispatched
 * @returns {Object} Object
 */
// remove this
export const createEmailPreferencesStart = () => {
	return {
		type: actionTypes.CREATE_EMAIL_PREFERENCES_START,
	};
};

/**
 * This actions is dispatched when we get a successful response from our Saga for the same,
 * It returns an Object with an Object with current action and response, further we access the Object's response
 * @usage `respone : state.emailPreferences.response`
 * @param {*} response
 * @returns {*} `Object`
 */
// remove this
export const createEmailPreferencesSuccess = (successMessage) => {
	return {
		type: actionTypes.CREATE_EMAIL_PREFERENCES_SUCCESS,
		successMessage,
	};
};
/**
 * This actions is dispatched from saga if there is any issue with API call via axios
 * It returns an Object with an Object with current action and response, further we access the Object's response
 * @param {*} error
 * @returns {*} `Object`
 */
// remove this
export const createEmailPreferencesFail = (error) => {
	return {
		type: actionTypes.CREATE_EMAIL_PREFERENCES_FAIL,
		errors: error,
	};
};

/**
 * This action is dispatched from the Component, in this case `EmailPreferences.js`, it contains an
 * `Object` with form details from the user. When this method is dispatched the user Email Preferences are
 * created for the first time
 * @param {*} userData
 * @returns {*} `Object`
 */
// remove this
export const createEmailPreferences = (userData) => {
	return {
		type: actionTypes.CREATE_EMAIL_PREFERENCES,
		userData: userData,
	};
};

/**
 * Update Email Preferences Action
 */

/**
 * This action will indicate that we are in the start phase of calling the API to update the User Email Preferences
 * It returns an Object the the current action which has been dispatched
 * @returns {Object} Object
 */
// remove this
export const updateEmailPreferencesStart = () => {
	return {
		type: actionTypes.UPDATE_EMAIL_PREFERENCES_START,
	};
};
/**
 * This actions is dispatched when we get a successful response from our Saga for the same,
 * It returns an Object with an Object with current action and response, further we access the Object's response
 * It means our request to update email preferences is successful
 * @usage `respone : state.emailPreferences.response`
 * @param {*} response
 * @returns {*} `Object`
 */
// remove this
export const updateEmailPreferencesSuccess = (response) => {
	return {
		type: actionTypes.UPDATE_EMAIL_PREFERENCES_SUCCESS,
		response,
	};
};

/**
 * This actions is dispatched from saga if there is any issue with API call via axios
 * It returns an Object with an Object with current action and response, further we access the Object's response
 * @param {*} error
 * @returns {*} `Object`
 */
// remove this
export const updateEmailPreferencesFail = (error) => {
	return {
		type: actionTypes.UPDATE_PROFILE_FAIL,
		error: error,
	};
};
/**
 * This action is dispatched from the Component, in this case `EmailPreferences.js`, it contains an
 * `Object` with form details from the user. When this method is dispatched the user Email Preferences are
 * created for the first time
 * @param {*} userData
 * @returns {*} `Object`
 */
// remove this
export const updateEmailPreferences = (id, userData) => {
	return {
		type: actionTypes.UPDATE_EMAIL_PREFERENCES,
		userData: userData,
		id
	};
};

export const isSpinning = (response) => {
	return {
		type: actionTypes.IS_SPINNING,
		response,
	};
};

import axios from "axios";

// AWS DEV URL: https://dev.equityfundingscript.com/api/v1/
// AWS PROD URL : https://demo.equityfundingscript.com/api/v1/
// AWS REAL ESTATE URL : https://realestate.equityfundingscript.com/api/v1/

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/`,
});

instance.interceptors.request.use(function (config) {
	const token = localStorage.getItem("token");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});

export default instance;

import useSWR from "swr";
import axios from "../../config/AxiosConfig";
import { EQUITY_SHARE_CERTIFICATE_API } from "../../store/actions/apiCollections";

export const equityShareCertificateFetcher = (url) =>
	axios.get(url).then((response) => response.data.data.data);

export const useEquityShareCertificateFetcher = () => {
	const { data, isValidating } = useSWR(
		`${EQUITY_SHARE_CERTIFICATE_API}`,
		equityShareCertificateFetcher,
		{
			keepPreviousData: true,
			revalidateOnFocus: true,
		}
	);
	return { data, isValidating };
};

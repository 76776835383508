import { put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
	updateMyPasswordStart, // remove this
	updateMyPasswordFail, // remove this
	updateMyPasswordSuccess // remove this
} from "../actions/updateMyPasswordAction";
import { UPDATE_PASSWORD_API } from "../actions/apiCollections";
import { getLanguageCode } from "../../helpers/helpers";

// remove this
export function* updateMyPasswordSaga(action) {
	yield put(updateMyPasswordStart());
	try {
		const response = yield axios.patch(
			`${UPDATE_PASSWORD_API}?lng=${getLanguageCode()}`,
			action.userData
		);
		yield put(updateMyPasswordSuccess(response.data.status));
		yield localStorage.setItem("token", response.data.token);
	} catch (error) {
		yield put(updateMyPasswordFail(error.response.data.message));
	}
}

import axios from "../../config/AxiosConfig";
import useSWRMutation from "swr/mutation";
import useSWR from "swr";
import {
	GET_MANGOPAY_CURRENT_WALLET_API,
	GET_MANGOPAY_PAYMENT_RECEIPT_API,
	GET_MANGOPAY_AUTHORIZED_COUNTRIES_API,
	POST_MANGOPAY_LINKED_BANK_ACCOUNTS_API,
	GET_MANGOPAY_BANK_DETAIL_API,
	GET_MANGOPAY_DEACTIVATE_BANK_ACCOUNT_API,
	MANGOPAY_PAYOUT_API,
} from "../../store/actions/apiCollections";
import { getLanguageCode } from "../../helpers/helpers";

export const mangoPayTransactionFetcher = (url) =>
	axios.get(url).then((response) => response.data.data.data);

export const mangoPayDetailsFetcher = (url) =>
	axios.get(url).then((response) => response.data.data.data);

export const mangoPayTopup = (url, { arg }) =>
	axios.post(url, arg).then((response) => response.data.data.data);

export const mangoPayTopupReceiptFetcher = (url) =>
	axios.get(url).then((response) => response.data.data.data);

export const useMangoPayDetailsFetcher = (currencyId) => {
	const {
		data: mangoPayDetails,
		isValidating,
		mutate,
	} = useSWR(
		currencyId
			? `${GET_MANGOPAY_CURRENT_WALLET_API}?&currency=${currencyId}`
			: "",
		mangoPayDetailsFetcher,
		{
			keepPreviousData: true,
			revalidateOnFocus: false,
		}
	);

	return { mangoPayDetails, isValidating, mutate };
};

export const useMangoPayReceiptFetcher = (transactionId) => {
	const { data: mangoPayPaymentDetails, isValidating } = useSWR(
		transactionId ? `${GET_MANGOPAY_PAYMENT_RECEIPT_API}/${transactionId}` : "",
		mangoPayTopupReceiptFetcher,
		{
			keepPreviousData: true,
			revalidateOnFocus: false,
		}
	);

	return { mangoPayPaymentDetails, isValidating };
};

// Get All MangoPay Athorized Countries
export const mangoPayAthorizedCountriesFetcher = (url) =>
	axios.get(url).then((response) => response.data.data.data);

export const useMangoPayAthorizedCountriesFetcher = () => {
	const {
		data: mangoPayAthorizedCountries,
		isValidating,
		mutate,
	} = useSWR(
		`${GET_MANGOPAY_AUTHORIZED_COUNTRIES_API}`,
		mangoPayAthorizedCountriesFetcher,
		{
			keepPreviousData: true,
			revalidateOnFocus: false,
		}
	);
	return { mangoPayAthorizedCountries, isValidating, mutate };
};

// MangoPay Add New Bank Account
export const postNewBankAccount = (url, { arg }) =>
	axios
		.post(url, arg, {
			params: {
				lng: getLanguageCode(),
			},
		})
		.then((response) => response.data.data.data)
		.catch((error) => error.response);

export const useMangoPayAddBankDetail = () => {
	const {
		data: addMangopayBankData,
		trigger: addMangopayBankTrigger,
		isMutating: addMangopayBankMutating,
		error: addMangopayBankError,
	} = useSWRMutation(
		`${POST_MANGOPAY_LINKED_BANK_ACCOUNTS_API}`,
		postNewBankAccount
	);

	return {
		addMangopayBankData,
		addMangopayBankTrigger,
		addMangopayBankMutating,
		addMangopayBankError,
	};
};

// MangoPay Payout
export const getAllmangoPayBanksFetcher = (url) =>
	axios
		.get(url, {
			params: {
				lng: getLanguageCode(),
			},
		})
		.then((response) => response.data.data.data);

export const mangoPayBankDetailFetcher = (url) =>
	axios
		.get(url, {
			params: {
				lng: getLanguageCode(),
			},
		})
		.then((response) => response.data.data.data);

export const mangoPayDeactivateBankFetcher = (url) =>
	axios
		.get(url, {
			params: {
				lng: getLanguageCode(),
			},
		})
		.then((response) => response.data);

export const useMangopayallbanksfetcher = () => {
	const { data: mangoPayBanksDetails, isValidating } = useSWR(
		POST_MANGOPAY_LINKED_BANK_ACCOUNTS_API,
		getAllmangoPayBanksFetcher,
		{
			keepPreviousData: true,
			revalidateOnFocus: false,
		}
	);

	return { mangoPayBanksDetails, isValidating };
};

export const useMangoPayBankDetailFetcher = (bankId) => {
	const { data: mangoPayBankDetail, isValidating: isBankDetailValidating } =
		useSWR(
			bankId ? `${GET_MANGOPAY_BANK_DETAIL_API}/${bankId}` : "",
			mangoPayBankDetailFetcher,
			{
				keepPreviousData: true,
				revalidateOnFocus: false,
			}
		);

	return { mangoPayBankDetail, isBankDetailValidating };
};

// MangoPay Bank Account Inactive
export const useMangoPayDeactivateBankFetcher = (bankId) => {
	const {
		data: mangoPayDeactivateStatus,
		isValidating: isDeactivateValidating,
	} = useSWR(
		bankId ? `${GET_MANGOPAY_DEACTIVATE_BANK_ACCOUNT_API}/${bankId}` : "",
		mangoPayDeactivateBankFetcher,
		{
			keepPreviousData: true,
			revalidateOnFocus: false,
		}
	);

	return { mangoPayDeactivateStatus, isDeactivateValidating };
};

// MangoPay Payout
export const postMangoPayPayout = (url, { arg }) =>
	axios
		.post(url, arg, {
			params: {
				lng: getLanguageCode(),
			},
		})
		.then((response) => response.data.data.data)
		.catch((error) => error.response);

export const useMangoPayPayout = () => {
	const {
		data: addMangopayPayoutData,
		trigger: addMangopayPayoutTrigger,
		isMutating: addMangopayPayoutMutating,
		error: addMangopayPayoutError,
	} = useSWRMutation(`${MANGOPAY_PAYOUT_API}`, postMangoPayPayout);

	return {
		addMangopayPayoutData,
		addMangopayPayoutTrigger,
		addMangopayPayoutMutating,
		addMangopayPayoutError,
	};
};

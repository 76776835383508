export const secondaryMarketConstants = {
	ENABLE: "YES",
	DISABLE: "NO",
};

export const secondaryMarketSellRequestStatusConstants = {
	PENDING: "PENDING",
	UNDERREVIEW: "UNDER-REVIEW",
	APPROVED: "APPROVED",
	REJECTED: "REJECTED",
	CLOSED: "CLOSED",
};

export const secondaryMarketSellRequestSellStatusConstants = {
	NOTSTARTED: "NOT-STARTED",
	PARTIALLYSOLD: "PARTIALLY-SOLD",
	SOLD: "SOLD",
};

export const secondaryMarketSellRequestPayoutStatusConstants = {
	PENDING: "PENDING",
	REQUESTED: "REQUESTED",
	APPROVED: "APPROVED",
};

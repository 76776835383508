import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
	taxonomy: [],
	error: null,
	loading: false
};

const taxonomyStart = (state, action) => {
	return updateObject(state, { error: null, loading: true });
};

const taxonomySuccess = (state, action) => {
	return updateObject(state, {
		taxonomy: action.response
	});
};

const taxonomyFail = (state, action) => {
	return updateObject(state, {
		error: action.error
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_TAXONOMY:
			return taxonomyStart(state, action);
		case actionTypes.GET_TAXONOMY_SUCCESS:
			return taxonomySuccess(state, action);
		case actionTypes.GET_TAXONOMY_FAIL:
			return taxonomyFail(state, action);
		default:
			return state;
	}
};

export default reducer;

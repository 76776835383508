import axios from "../../config/AxiosConfig";

export const myInvestmentFetcher = url =>
	axios.get(url).then(response => response.data.data.data);

export const getCardDetailFetcher = url =>
	axios.get(url).then(response => response.data.data.data);

export const getDocumentDetailFetcher = url =>
	axios.get(url).then(response => response.data.data.data);

export const getMyInvestmentsGraphFetcher = url =>
	axios.get(url).then(response => response.data.data.data);

export const getInvestmentsChartFetcher = url =>
	axios.get(url).then(response => response.data.data.data);

export const getInvestmentUniqueCurrenciesFetcher = url =>
	axios.get(url).then(response => response.data.data.data);

export const getInvestmentsColumnChartFetcher = url =>
	axios.get(url).then(response => response.data.data.data);

var CryptoJS = require("crypto-js");

// Below commented code is for create new Key & IV for future.

// var key = CryptoJS.enc.Hex.parse("000102030405060708090a0b0c0d0e0f");
// var iv = CryptoJS.enc.Hex.parse("101112131415161718191a1b1c1d1e1f");

var key = {
	words: CryptoJS.enc.Base64.parse(process.env.REACT_APP_ENC_DEC_KEY).words,
	sigBytes: CryptoJS.enc.Base64.parse(process.env.REACT_APP_ENC_DEC_KEY)
		.sigBytes
};
var iv = {
	words: CryptoJS.enc.Base64.parse(process.env.REACT_APP_ENC_DEC_IV).words,
	sigBytes: CryptoJS.enc.Base64.parse(process.env.REACT_APP_ENC_DEC_IV).sigBytes
};

/* This function is used to encrypt original string. */
export const encryptData = decrypted => {
	const encrypted = CryptoJS.AES.encrypt(decrypted, key, {
		iv
	}).toString();
	return encrypted;
};

/* This function is used to decrypt encrypted string. */
export const decryptedData = encrypted => {
	if (encrypted) {
		/* This line is for convert encrypted string in bytes. */
		const bytes = CryptoJS.AES.decrypt(encrypted.toString(), key, {
			iv
		});

		/* This line is for convert bytes into original string. */
		const decrypted = bytes.toString(CryptoJS.enc.Utf8);

		/* This line return decrypted string if its encrypted else it return same string. */
		return decrypted ? decrypted : encrypted;
	}else{
		return "";
	}
};

import React from "react";
import { Card } from "react-bootstrap";

import "./Loading.scss";

const Loading = () => {
  return (
    <Card.Body className="loader-wrapper">
      <Card.Body className="loader">
        <Card.Body className="loader__bar p-0"></Card.Body>
        <Card.Body className="loader__bar p-0"></Card.Body>
        <Card.Body className="loader__bar p-0"></Card.Body>
        <Card.Body className="loader__bar p-0"></Card.Body>
        <Card.Body className="loader__bar p-0"></Card.Body>
        <Card.Body className="loader__ball p-0"></Card.Body>
      </Card.Body>
    </Card.Body>
  );
};

export default Loading;

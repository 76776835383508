import * as actionTypes from "../../actions/userProfile/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
	user: [], // remove this
	error: null,
	followers: [],
	following: [],
	emailResponse: false,
	notifications: [],
	statistics: [],
	userNotifications: [],
	inviteResponse: null,
	loading: false,
	contactUsResponse: null,
	userAccountDeleteResponse: null,
	isSpinning: false,
};

const userStart = (state, action) => {
	return updateObject(state, {
		user: [], // remove this
		error: null,
		followers: [],
		following: [],
		emailResponse: false,
		inviteResponse: null,
		loading: false,
		contactUsResponse: null,
		userAccountDeleteResponse: null,
		isSpinning: false,
	});
};

const userFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
	});
};
// remove this
const getUser = (state, action) => {
	return updateObject(state, {
		user: action.response,
	});
};

const getFollowers = (state, action) => {
	return updateObject(state, {
		followers: action.response,
	});
};

const getFollowing = (state, action) => {
	return updateObject(state, {
		following: action.response,
	});
};

const verifyEmail = (state, action) => {
	return updateObject(state, {
		emailResponse: action.response,
	});
};

const getUserActivities = (state, action) => {
	return updateObject(state, {
		notifications: action.response,
	});
};

const getUserProfileStatistics = (state, action) => {
	return updateObject(state, {
		statistics: action.response,
	});
};

const getUserInvitationSucess = (state, action) => {
	return updateObject(state, {
		inviteResponse: action.response,
		error: null,
		isSpinning: false,
	});
};

const getUserInvitationFail = (state, action) => {
	return updateObject(state, {
		error: action.response,
		inviteResponse: null,
		isSpinning: false,
	});
};

const userInvitationSendStart = (state, action) => {
	return updateObject(state, {
		error: null,
		inviteResponse: null,
	});
};

const userContactUsSuccess = (state, action) => {
	return updateObject(state, {
		contactUsResponse: action.response,
		error: null,
		isSpinning: false,
	});
};

const userContactUsFail = (state, action) => {
	return updateObject(state, {
		error: action.response,
		contactUsResponse: null,
		isSpinning: false,
	});
};

const userContactUsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		contactUsResponse: null,
	});
};

// Delete Account Request
const userAccountDeleteRequestSuccess = (state, action) => {
	return updateObject(state, {
		userAccountDeleteResponse: action.response,
		error: null,
		isSpinning: false,
	});
};

const userAccountDeleteRequestFail = (state, action) => {
	return updateObject(state, {
		error: action.response,
		userAccountDeleteResponse: null,
		isSpinning: false,
	});
};

const userAccountDeleteRequestStart = (state, action) => {
	return updateObject(state, {
		error: null,
		userAccountDeleteResponse: null,
	});
};

const isSpinning = (state, action) => {
	return updateObject(state, {
		isSpinning: action.response,
	});
};

const getUserNotifications = (state, action) => {
	return updateObject(state, {
		userNotifications: action.response,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.USER_PROFILE_START:
			return userStart(state, action);
		case actionTypes.USER_PROFILE_FAIL:
			return userFail(state, action);
		case actionTypes.GET_PROFILE_SUCCESS: // remove this case
			return getUser(state, action);
		case actionTypes.GET_FOLLOWERS_SUCCESS:
			return getFollowers(state, action);
		case actionTypes.GET_FOLLOWING_SUCCESS:
			return getFollowing(state, action);
		case actionTypes.VERIFY_EMAIL_SUCCESS:
			return verifyEmail(state, action);
		case actionTypes.GET_USER_ACTIVITIES_SUCCESS:
			return getUserActivities(state, action);
		case actionTypes.GET_STATISTICS_SUCCESS:
			return getUserProfileStatistics(state, action);
		case actionTypes.USER_INVITE_START:
			return userInvitationSendStart(state, action);
		case actionTypes.USER_INVITE_SUCCESS:
			return getUserInvitationSucess(state, action);
		case actionTypes.USER_INVITE_FAIL:
			return getUserInvitationFail(state, action);
		case actionTypes.USER_CONTACT_US_START:
			return userContactUsStart(state, action);
		case actionTypes.USER_CONTACT_US_SUCCESS:
			return userContactUsSuccess(state, action);
		case actionTypes.USER_CONTACT_US_FAIL:
			return userContactUsFail(state, action);

		// Delete Account Request
		case actionTypes.USER_ACCOUNT_DELETE_REQUEST_START:
			return userAccountDeleteRequestStart(state, action);
		case actionTypes.USER_ACCOUNT_DELETE_REQUEST_SUCCESS:
			return userAccountDeleteRequestSuccess(state, action);
		case actionTypes.USER_ACCOUNT_DELETE_REQUEST_FAIL:
			return userAccountDeleteRequestFail(state, action);

		case actionTypes.IS_SPINNING:
			return isSpinning(state, action);
		case actionTypes.GET_USER_NOTIFICATIONS_SUCCESS:
			return getUserNotifications(state, action);
		default:
			return state;
	}
};
export default reducer;

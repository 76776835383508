/**
 * A Redux `actionTypes` that the resource reducer responds to.
 * Dispatch these from action creators to change the state of your store.
 */
/**
 * Common
 */

export const IS_SPINNING = "[GLOBAL] IS SPINNING";
export const SHOW_ALERT = "[GLOBAL] SHOW ALERT";

/**
 * Action Types for Auth
 */
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_USER_LOGIN = "AUTH_USER_LOGIN";
export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const CLEAR_ADMIN_LOGIN = "CLEAR_ADMIN_LOGIN";
export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_CHECK_TIMEOUT = "AUTH_CHECK_TIMEOUT";
export const AUTH_CHECK_STATE = "AUTH_CHECK_STATE";
export const CLEAR_AUTH_ERROR_STATE = "CLEAR_AUTH_ERROR_STATE";
export const TWO_FACTOR_AUTH_SUCCESS = "TWO_FACTOR_AUTH_SUCCESS";
export const CLEAR_TWO_FACTOR_LOGIN_ERROR = "CLEAR_TWO_FACTOR_LOGIN_ERROR";
export const CLEAR_TWO_FACTOR_LOGIN_DATA = "CLEAR_TWO_FACTOR_LOGIN_DATA";

/* Action Types for mobile app auth */
export const APP_USER_AUTO_LOGIN = "APP_USER_AUTO_LOGIN";

/* Action Types for mobile app auth */
export const GET_TAXONOMY = "GET_TAXONOMY";
export const GET_TAXONOMY_SUCCESS = "GET_TAXONOMY_SUCCESS";
export const GET_TAXONOMY_FAIL = "GET_TAXONOMY_FAIL";

/**
 * Action Types for User Registration
 */

export const AUTH_REGISTRATION_START = "AUTH_REGISTRATION_START";
export const USER_REGISTRATION = "USER_REGISTRATION";
export const AUTH_REGISTRATION_SUCCESS = "AUTH_REGISTRATION_SUCCESS";
export const AUTH_REGISTRATION_FAIL = "AUTH_REGISTRATION_FAIL";

/**
 * Action Types for Updating Password
 */

export const UPDATE_MY_PASSWORD_START = "UPDATE_MY_PASSWORD_START"; // remove this
export const UPDATE_MY_PASSWORD_SUCCESS = "UPDATE_MY_PASSWORD_SUCCESS"; // remove this
export const UPDATE_MY_PASSWORD_FAIL = "UPDATE_MY_PASSWORD_FAIL"; // remove this
export const UPDATE_MY_PASSWORD = "UPDATE_MY_PASSWORD"; // remove this

/**
 * Action Types for Updating Profile
 */
export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const UPDATE_MY_PROFILE = "UPDATE_MY_PROFILE";

/**
 * Action Types for Fetching User's Profile details
 */
export const GET_CURRENT_USER_START = "GET_CURRENT_USER_START";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_FAIL = "GET_CURRENT_USER_FAIL";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_CURRENT_USER_STATE = "GET_CURRENT_USER_STATE";

/**
 * Action Types for Fetching User's Email Preferences
 */
// remove this
export const GET_USER_EMAIL_PREFERNECES_START =
	"GET_USER_EMAIL_PREFERNECES_START";
export const GET_USER_EMAIL_PREFERNECES_SUCCESS =
	"GET_USER_EMAIL_PREFERNECES_SUCCESS";
export const GET_USER_EMAIL_PREFERNECES_FAIL =
	"GET_USER_EMAIL_PREFERNECES_FAIL";
export const GET_USER_EMAIL_PREFERNECES = "GET_USER_EMAIL_PREFERNECES";

/**
 * Action Types for creating  User's Email Preferences
 */

export const CREATE_EMAIL_PREFERENCES_START = "CREATE_EMAIL_PREFERENCES";
export const CREATE_EMAIL_PREFERENCES_SUCCESS =
	"CREATE_EMAIL_PREFERENCES_SUCCESS";
export const CREATE_EMAIL_PREFERENCES_FAIL = "CREATE_EMAIL_PREFERENCES_FAIL";
export const CREATE_EMAIL_PREFERENCES = "CREATE_EMAIL_PREFERENCES";

/**
 * Action Types for updating user's email preferences
 */
// remove this
export const UPDATE_EMAIL_PREFERENCES_START = "UPDATE_EMAIL_PREFERENCES_START";
export const UPDATE_EMAIL_PREFERENCES_SUCCESS =
	"UPDATE_EMAIL_PREFERENCES_SUCCESS";
export const UPDATE_EMAIL_PREFERENCES_FAIL = "UPDATE_EMAIL_PREFERENCES_FAIL";
export const UPDATE_EMAIL_PREFERENCES = "UPDATE_EMAIL_PREFERENCES";

/**
 * Action Types for fetching user's Investor Profile
 */

export const GET_INVESTOR_PROFILE_START = "GET_INVESTOR_PROFILE_START"; // remove this
export const GET_INVESTOR_PROFILE_SUCCESS = "GET_INVESTOR_PROFILE_SUCCESS"; // remove this
export const GET_INVESTOR_PROFILE_FAIL = "GET_INVESTOR_PROFILE_FAIL"; // remove this
export const GET_INVESTOR_PROFILE = "GET_INVESTOR_PROFILE"; // remove this
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESSS";
/**
 * Action Types for creating user's Investor Profile
 */

export const CREATE_INVESTOR_PROFILE_START = "CREATE_INVESTOR_PROFILE_START"; // remove this
export const CREATE_INVESTOR_PROFILE_SUCCESS =
	"CREATE_INVESTOR_PROFILE_SUCCESS"; // remove this
export const CREATE_INVESTOR_PROFILE_FAIL = "CREATE_INVESTOR_PROFILE_FAIL"; // remove this
export const CREATE_INVESTOR_PROFILE = "CREATE_INVESTOR_PROFILE"; // remove this

export const GET_ACCREDIATION_CONTRACT = "GET_ACCREDIATION_CONTRACT"; // remove this
export const GET_ACCREDIATION_CONTRACT_SUCCESS =
	"GET_ACCREDIATION_CONTRACT_SUCCESS"; // remove this
export const GET_SIGNED_ACCREDIATION_CONTRACT =
	"GET_SIGNED_ACCREDIATION_CONTRACT"; // remove this
export const GET_SIGNED_ACCREDIATION_CONTRACT_SUCCESS =
	"GET_SIGNED_ACCREDIATION_CONTRACT_SUCCESS"; // remove this

/**
 * Actions Types for updating user's Investor Profile
 */

export const UPDATE_INVESTOR_PROFILE_START = "UPDATE_INVESTOR_PROFILE_START"; // remove
export const UPDATE_INVESTOR_PROFILE_SUCCESS =
	"UPDATE_INVESTOR_PROFILE_SUCCESS"; // remove
export const UPDATE_INVESTOR_PROFILE_FAIL = "UPDATE_INVESTOR_PROFILE_FAIL"; // remove
export const UPDATE_INVESTOR_PROFILE = "UPDATE_INVESTOR_PROFILE"; // remove
export const CLEAR_SUCCESS_ALERT = "CLEAR_SUCCESS_ALERT"; // remove

/**
 * Reset Password
 * This send the email
 */

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD = "RESET_PASSWORD";

/**
 * Forgot Password
 */

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const CLEAR_FORGOT_PASSWORD = "CLEAR_FORGOT_PASSWORD";

/**
 * Site Settings
 */

export const SITE_SETTINGS_START = "SITE_SETTINGS_START";
export const SITE_SETTINGS_FAIL = "SITE_SETTINGS_FAIL";
export const GET_SITE_SETTINGS = "GET_SITE_SETTINGS";
export const GET_SITE_SETTINGS_SUCCESS = "GET_SITE_SETTINGS_SUCCESS";
export const GET_CURRENCY = "GET_CURRENCY";
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS";

export const GET_ACH_CAMPAIGN_SETTING = "GET_ACH_CAMPAIGN_SETTING";
export const GET_ACH_CAMPAIGN_SETTING_SUCCESS =
	"GET_ACH_CAMPAIGN_SETTING_SUCCESS";

export const GET_EQUITY_SHARE_CERTIFICATES = "GET_EQUITY_SHARE_CERTIFICATES";
export const GET_EQUITY_SHARE_CERTIFICATES_SUCCESS =
	"GET_EQUITY_SHARE_CERTIFICATES_SUCCESS";

/**
 * Sliders
 */

export const SLIDERS_START = "SLIDERS_START";
export const SLIDERS_FAIL = "SLIDERS_FAIL";
export const GET_SLIDERS = "GET_SLIDERS";
export const GET_SLIDERS_SUCCESS = "GET_SLIDERS_SUCCESS";

/**
 * Get Languages
 */

export const LANGUAGES_START = "LANGUAGES_START";
export const LANGUAGES_FAIL = "LANGUAGES_FAIL";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_DEFAULT_LANGUAGE = "GET_DEFAULT_LANGUAGE";

export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_CURRENT_LANGUAGE = "GET_CURRENT_LANGUAGE";
/**
 * GET reCaptcha
 */
export const GET_RECAPTCHA = "GET_RECAPTCHA";
export const GET_RECAPTCHA_SUCCESS = "GET_RECAPTCHA_SUCCESS";

export const GET_CAMPAIGN_SETTINGS_START = "GET_CAMPAIGN_SETTINGS_START";
export const GET_CAMPAIGN_SETTINGS = "GET_CAMPAIGN_SETTINGS";
export const GET_CAMPAIGN_SETTINGS_SUCCESS = "GET_CAMPAIGN_SETTINGS_SUCCESS";
export const GET_CAMPAIGN_SETTINGS_FAIL = "GET_CAMPAIGN_SETTINGS_FAIL";

/**
 * Social Signup / Login
 */

export const AUTH0_DATA_POST = "[Auth0] Post Data from User";

export const GET_SOCIAL_SETTING = "GET_SOCIAL_SETTING";
export const GET_SOCIAL_SETTING_START = "GET_SOCIAL_SETTING_START";
export const GET_SOCIAL_SETTING_FAIL = "GET_SOCIAL_SETTING_FAIL";
export const GET_SOCIAL_GOOGLE_SETTING_SUCCESS =
	"GET_SOCIAL_GOOGLE_SETTING_SUCCESS";
export const GET_SOCIAL_FACEBOOK_SETTING_SUCCESS =
	"GET_SOCIAL_FACEBOOK_SETTING_SUCCESS";
export const GET_SOCIAL_LINKEDIN_SETTING_SUCCESS =
	"GET_SOCIAL_LINKEDIN_SETTING_SUCCESS";
export const GET_SOCIAL_TWITTER_SETTING_SUCCESS =
	"GET_SOCIAL_TWITTER_SETTING_SUCCESS";

/**
 * User Type
 */
export const USER_TYPE = "USER_TYPE";
export const USER_TYPE_SUCCESS = "USER_TYPE_SUCCESS";

export const CLEAR_SUBSCRIBE_NEWSLETTER = "CLEAR_SUBSCRIBE_NEWSLETTER";
export const SUBSCRIBE_NEWSLETTER = "SUBSCRIBE_NEWSLETTER";
export const SUBSCRIBE_NEWSLETTER_SUCCESS = "SUBSCRIBE_NEWSLETTER_SUCCESS";
// Resend Verification Email
export const CLEAR_RESEND_EMAIL_VERIFICATION_LINK =
	"CLEAR_RESEND_EMAIL_VERIFICATION_LINK";
export const RESEND_EMAIL_VERIFICATION_LINK = "RESEND_EMAIL_VERIFICATION_LINK";
export const RESEND_EMAIL_VERIFICATION_LINK_SUCCESS =
	"RESEND_EMAIL_VERIFICATION_LINK_SUCCESS";
export const RESEND_EMAIL_VERIFICATION_LINK_FAIL =
	"RESEND_EMAIL_VERIFICATION_LINK_FAIL";

// Twitter Login
export const CLEAR_TWITTER_LOGIN_TOKEN = "CLEAR_TWITTER_LOGIN_TOKEN";
export const GET_TWITTER_LOGIN_TOKEN = "GET_TWITTER_LOGIN_TOKEN";
export const GET_TWITTER_LOGIN_TOKEN_SUCCESS =
	"GET_TWITTER_LOGIN_TOKEN_SUCCESS";

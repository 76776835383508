import { put, call, delay } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import { COMMENTS_API } from "../../actions/apiCollections";
import {
	commentsStart,
	commentsFail,
	getCommentsSuccess,
	getAllCommentsSuccess,
	createCommentSuccess,
	getAllSubCommentsSuccess,
	setCommentsFields
} from "../../actions/comments/commentsAction";
/**
 * @description Shows all active comments
 * @param {object} action
 */
export function* getComments(action) {
	yield put(commentsStart());
	try {
		const response = yield axios.get(COMMENTS_API + "all/" + action.id);
		yield put(getCommentsSuccess(response.data.data.data.docs));
	} catch (error) {
		yield put(commentsFail(error));
	}
}

export function* createComment(action) {
	yield put(commentsStart());
	try {
		const response = yield axios.post(
			COMMENTS_API + action.commentData.id,
			action.commentData
		);
		const obj = response.data.data.data;
		obj.postType = "comment";
		yield put(createCommentSuccess(obj));
		yield delay(300)
		yield call(getComments, action.commentData);
	} catch (error) {
		yield put(commentsFail(error));
	}
}

/**
 * @description Shows all comments to the project owner
 * @param {object} action
 */
export function* getAllComments(action) {
	yield put(commentsStart());
	yield put(setCommentsFields("allCommentLoader", true));
	try {
		const response = yield axios.get(COMMENTS_API + action.id);
		yield put(getAllCommentsSuccess(response.data.data.data.docs));
	} catch (error) {
		yield put(commentsFail(error));
	}
}

export function* approveComment(action) {
	yield put(commentsStart());
	try {
		yield axios.patch(COMMENTS_API + action.commentId, action);
		yield call(getAllComments, action);
	} catch (error) {
		yield put(commentsFail(error));
	}
}

export function* approveSubComment(action) {
	try {
		yield axios.patch(COMMENTS_API + action.commentId, action);
		yield call(getAllSubCommentsSaga, action);
	} catch (error) {
		yield put(commentsFail(error));
	}
}

export function* declineComment(action) {
	yield put(commentsStart());
	try {
		yield axios.patch(COMMENTS_API + action.commentId, action);
		yield call(getAllComments, action);
	} catch (error) {
		yield put(commentsFail(error));
	}
}

export function* declineSubComment(action) {
	yield put(commentsStart());
	try {
		yield axios.patch(COMMENTS_API + action.commentId, action);
		yield call(getAllSubCommentsSaga, action);
	} catch (error) {
		yield put(commentsFail(error));
	}
}

export function* deleteCommentSaga(action) {
	yield put(commentsStart());
	try {
		yield axios.delete(COMMENTS_API + action.commentId);
		const obj = {
			parentCommentId: action.parentCommentId,
		};
		yield call(getAllSubCommentsSaga, obj);
		yield call(getAllComments, action);
	} catch (error) {
		yield put(commentsFail(error));
	}
}

export function* createSubCommentSaga(action) {
	yield put(commentsStart());
	try {
		const response = yield axios.post(
			COMMENTS_API + action.commentData.id,
			action.commentData
		);
		const responseObj = response.data.data.data;
		responseObj.postType = "reply";
		yield put(createCommentSuccess(responseObj));
		const obj = {
			parentCommentId: action.commentData.parentId,
		};
		yield call(getAllSubCommentsSaga, obj);
		//yield call(getComments, action.commentData);
	} catch (error) {
		yield put(commentsFail(error));
	}
}

export function* getAllSubCommentsSaga(action) {
	yield put(setCommentsFields("allSubCommentsLoader", true));
	try {
		const response = yield axios.get(
			COMMENTS_API + "parent-comments/" + action.parentCommentId
		);
		yield put(
			getAllSubCommentsSuccess(response.data.data.data.subCommentsData)
		);
	} catch (error) {
		yield put(commentsFail(error));
	}
}

import * as actionTypes from "../../actions/mangopay/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
	displayLoadMore: false,
	error: [],
	isLoading: false,
	isSpinning: false,
	mangoPaySelectedCurrency: null,
};

const setMangoPaySelectedCurrency = (state, action) => {
	return updateObject(state, {
		mangoPaySelectedCurrency: action.currency,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_MANGOPAY_SELECTED_CURRENCY:
			return setMangoPaySelectedCurrency(state, action);
		default:
			return state;
	}
};

export default reducer;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Container,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
  Card,
} from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import useSWR from "swr";
import { toast } from "react-toastify";
import AppPaths from "../../../../routes/AppPaths";
import { getCurrentUser, getRevenue } from "../../../../store/actions/index";
import * as actions from "../../../../store/actions/index";
import "./Header.scss";
import {
  getLanguageId,
  setLanguageId,
  getUserTypeWiseDisplay,
} from "../../../../helpers/helpers";
import { getBaseImageURL } from "../../../../helpers/url";
import profilePhoto from "../../assets/images/placeholder-avatar.webp";
import defaultFavicon from "../../assets/images/favicon.png";
import {
  preloadDashboardData,
  preloadWalletData,
  preloadUserProfileData,
  preloadMyinvestmentData,
  preloadMangoPayData,
  preloadEquityShareCertificateData,
} from "../../../../helpers/swrPreloadHelper";
import { decryptedData } from "../../../../helpers/encryptDecryptData";
import { toastifyConstant } from "../../../../helpers";
import { getLanguageReplaceText } from "../../../../helpers/helpers";
import { secondaryMarketConstants } from "../../../../constants";
import { GET_SECONDARY_MARKET_TRADES_API } from "../../../../store/actions/apiCollections";
import { secondaryMarketDataFetcher } from "../../../../swrHelpers/swrSecondaryMarket";

function Header(props) {
  const dispatch = useDispatch();
  // const socket = useContext(SocketContext);
  const userTypeId = localStorage.getItem("userType");
  const globalImagePath = useRef(getBaseImageURL());
  const isSavedInvestmentEnable = useRef(
    process.env.REACT_APP_TOGGLE_SAVED_INVESTMENT === "true"
  );
  const isEncryted = useRef(
    process.env.REACT_APP_DATABASE_ENCRYPTION === "true"
  );

  useEffect(() => {
    localStorage.getItem("token") && dispatch(getCurrentUser());
    dispatch(getRevenue());
    dispatch(actions.getCurrentLanguage());
    if (
      userTypeId !== null &&
      userTypeId !== undefined &&
      userTypeId !== "undefined"
    ) {
      dispatch(actions.userTypes(userTypeId));
    } else {
      dispatch(actions.userTypes(""));
    }
  }, [dispatch, userTypeId]);

  const { siteSettings } = props;
  const { currentLang } = useSelector((state) => state.languages);
  const { equityShareCertificates } = useSelector(
    (state) => state.siteSettings
  );
  const { userTypes } = useSelector((state) => state.userRegistration);
  const { walletSelectedCurrency } = useSelector((state) => state.wallet);
  const { userNotifications } = useSelector((state) => state.userProfile);

  const [displayData, setDisplayData] = useState("");
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [logo, setLogo] = useState();

  useEffect(() => {
    if (siteSettings?.siteLogo) {
      setLogo(siteSettings?.siteLogo);
    }
  }, [siteSettings?.siteLogo]);

  useEffect(() => {
    if (userTypes) {
      getUserTypeWiseDisplay(userTypes, siteSettings?.onlyAdminCreateCampaign);
      setDisplayData(JSON.parse(localStorage.getItem("displayData")));
    }
  }, [userTypes]);

  useEffect(() => {
    if (
      getLanguageId() === null &&
      currentLang !== null &&
      currentLang !== undefined
    ) {
      setLanguageId(currentLang);
    }
  }, [currentLang]);

  // Extracting response from API Call

  const { currentUser } = useSelector(
    (state) => state.getCurrentUser,
    shallowEqual
  );
  const { header } = useSelector((state) => state.menu, shallowEqual);
  const { meta } = useSelector((state) => state.meta);
  const { taxonomy } = useSelector((state) => state?.taxonomy);
  const { isAuth, t } = props;
  const { firstName, favIcon } = siteSettings;

  const [displaySecondaryMarketMenu, setDisplaySecondaryMarketMenu] =
    useState(false);

  const faviconPath = useMemo(() => {
    return siteSettings?.favIcon
      ? `${globalImagePath?.current}${siteSettings?.favIcon}`
      : defaultFavicon;
  }, [siteSettings?.favIcon, globalImagePath?.current]);

  useEffect(() => {
    const fav = document.getElementById("favicon");
    if (fav && fav.href !== faviconPath) {
      fav.href = faviconPath;
    }
  }, [faviconPath]);

  const { data: BuyTradeList, isValidating: buyTradeValidating } = useSWR(
    isAuth && siteSettings?.enableSecondaryMarket === "YES"
      ? `${GET_SECONDARY_MARKET_TRADES_API}?limit=10&sellingRequestType=BUY`
      : "",
    secondaryMarketDataFetcher
  );

  const { data: SellTradeList, isValidating: sellTradeValidating } = useSWR(
    isAuth && siteSettings?.enableSecondaryMarket === "YES"
      ? `${GET_SECONDARY_MARKET_TRADES_API}?limit=10&sellingRequestType=SELL`
      : "",
    secondaryMarketDataFetcher
  );

  useEffect(() => {
    if (
      (BuyTradeList?.totalCount > 0 && !buyTradeValidating) ||
      (SellTradeList?.totalCount > 0 && !sellTradeValidating)
    ) {
      setDisplaySecondaryMarketMenu(true);
    }
  }, [BuyTradeList, buyTradeValidating, SellTradeList, sellTradeValidating]);

  // Set the Website title Dynamically
  if (
    !document.location.href.includes("/campaign-detail-page/") &&
    !document.location.href.includes("/campaign-dashboard/") &&
    !document.location.href.includes("/content-page/")
  ) {
    const title = meta && meta?.siteName;
    document.title =
      title === undefined ? i18n.t("header.welcomeTitle") : title;
    // document
    // 	.querySelector('meta[name="description"]')
    // 	.setAttribute("content", meta?.metaDescription);
  }

  /**
   * Use socket.io to emit the current user ID to all connected sockets
   * when the current user changes. This allows other connected clients to update
   * based on the current user.
   */
  // useEffect(() => {
  // 	if (currentUser?.id) {
  // 		const userData = {
  // 			userId: currentUser?.id,
  // 			userType: "front",
  // 		};
  // 		socket?.emit("globalSockets", userData);
  // 	}
  // }, [currentUser, socket]);

  /**
   * Set up a socket.io listener to show browser notifications
   * when a new notification is received from the server.
   *
   * Check if notifications are allowed, and request permission if needed.
   * Show the notification if permissions are granted.
   */
  // useEffect(() => {
  // 	socket.on(
  // 		`${process.env.REACT_APP_SOCKET_MAIN_ID}`,
  // 		async (notification) => {
  // 			if (Notification.permission === "granted") {
  // 				showNotification(notification);
  // 				dispatch(actions.getUserNotifications(1));
  // 			} else {
  // 				const permission = await Notification.requestPermission();
  // 				if (permission === "granted") {
  // 					showNotification(notification);
  // 				}
  // 			}
  // 		}
  // 	);
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  /**
   * Shows a browser notification using the Notification API.
   *
   * @param {Object} notification - The notification object containing the notification message.
   */
  // const showNotification = (notification) => {
  // 	new Notification(meta?.siteName, {
  // 		body: getLanguageReplaceText(
  // 			taxonomy,
  // 			notification?.languageText,
  // 			notification?.textToReplace,
  // 			notification?.text
  // 		),
  // 		icon: `${globalImagePath.current}${siteSettings?.favIcon}`,
  // 		image:
  // 			notification?.thumbnail && notification?.thumbnail !== "undefined"
  // 				? `${globalImagePath.current}${notification?.thumbnail}`
  // 				: "",
  // 	});
  // };

  // Set the Menus
  const menus =
    header.length > 0
      ? header.map((menu, key) =>
          menu.linkType === "custom" ? (
            <a
              key={key}
              className="nav-link"
              href={menu?.customUrl}
              target={menu?.urlTarget}
            >
              {menu?.title}
            </a>
          ) : (
            <Link
              key={key}
              className="nav-link"
              to={`/content-page/${menu?.contentPage?.slug}`}
              target={menu?.urlTarget}
            >
              {menu?.title}
            </Link>
          )
        )
      : null;

  return (
    <>
      <Navbar
        id="mainSiteHeader"
        expand="lg"
        className="ec-navbar"
        data-test="component-header"
      >
        <Container fluid>
          <Link
            id="headerLogoHomePath"
            to={AppPaths.HOME_PATH}
            data-test="component-header-logo"
            onMouseEnter={() => {
              setLogo(siteSettings?.darkLogo);
            }}
            onMouseLeave={() => {
              setLogo(siteSettings?.siteLogo);
            }}
          >
            <img
              className="primary-site-logo"
              src={`${globalImagePath.current}${logo}`}
              alt=""
            />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {isAuth ? (
                <>
                  <Link
                    id="headerMenuBrowse"
                    className="nav-link"
                    to={AppPaths.BROWSE_PATH}
                  >
                    {t("menu.browse")}
                  </Link>
                  <Link
                    id="headerCreateCampaign"
                    className="nav-link"
                    to={
                      siteSettings?.plaidDwollaModule === "yes"
                        ? currentUser?.plaidDwollaCustomerId &&
                          currentUser?.plaidDwollaKYCStatus === "verified"
                          ? AppPaths.PROJECT_GUIDELINES
                          : `${AppPaths.HOME_PATH}?notify=completeYourKYC`
                        : AppPaths.PROJECT_GUIDELINES
                    }
                  >
                    {t("header.createCampaign")}
                  </Link>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltipNotifications">
                        {t("header.notifications")}
                      </Tooltip>
                    }
                  >
                    <Link
                      id="headerNotificationDesktop"
                      className="nav-link top-notification ms-3 unread-number"
                      to={AppPaths.USER_NOTIFICATION_PATH}
                      onClick={() => dispatch(actions.postUserNotifications())}
                    >
                      <FontAwesomeIcon icon={["fas", "bell"]} />
                      {userNotifications?.unreadCount > 0 ? (
                        <span className="top-notification-count">
                          {userNotifications?.unreadCount}
                        </span>
                      ) : null}
                    </Link>
                  </OverlayTrigger>

                  <Card.Body className="nav-link nav-user-dropdown-wrapper ms-3">
                    <Card.Body
                      className="nav-user-info"
                      data-test="component-header-placeholder-avatar"
                      onClick={() => setShowSubMenu(!showSubMenu)}
                    >
                      <img
                        src={
                          currentUser?.photo
                            ? currentUser?.photo?.includes("users/user") ||
                              currentUser?.photo?.includes("admins/admin")
                              ? `${globalImagePath.current}${currentUser?.photo}`
                              : `${currentUser?.photo}`
                            : profilePhoto
                        }
                        className="nav-user-img"
                        alt={firstName}
                        onMouseEnter={() => setShowSubMenu(true)}
                      />
                      <span className="nav-user-name">
                        {isEncryted.current
                          ? decryptedData(currentUser?.firstName)
                          : currentUser?.firstName}{" "}
                        {isEncryted.current
                          ? decryptedData(currentUser?.lastName)
                          : currentUser?.lastName}
                      </span>
                      <FontAwesomeIcon
                        className="nav-user-arrow"
                        icon={[
                          "fas",
                          showSubMenu ? "chevron-up" : "chevron-down",
                        ]}
                      />
                    </Card.Body>
                    <Card.Body
                      className="nav-user-dropdown"
                      data-test="component-header-user-dropdown"
                    >
                      {showSubMenu ? (
                        <Card.Body className="nav-user-dropdown-inner">
                          {displayData?.investorDisplay ? (
                            <Link
                              id="headerInvestments"
                              className="nav-user-dropdown-item"
                              to={AppPaths.INVESTMENTS_PATH}
                              onClick={() => setShowSubMenu(false)}
                              onMouseEnter={() => preloadMyinvestmentData()}
                            >
                              {t("header.investorDashboard")}
                            </Link>
                          ) : (
                            ""
                          )}

                          {equityShareCertificates?.length > 0 &&
                          siteSettings?.enableShareCertificate === "yes" ? (
                            <Link
                              id="headerShareCertificates"
                              className="nav-user-dropdown-item"
                              to={AppPaths.SHARE_CERTIFICATES_PATH}
                              onClick={() => setShowSubMenu(false)}
                              onMouseEnter={() =>
                                preloadEquityShareCertificateData()
                              }
                            >
                              {t("header.shareCertificate")}
                            </Link>
                          ) : (
                            ""
                          )}

                          {siteSettings?.enableSecondaryMarket ===
                            secondaryMarketConstants.ENABLE &&
                            displaySecondaryMarketMenu && (
                              <Link
                                id="headerSecondaryMarket"
                                to={
                                  AppPaths.SECONDARY_MARKET_SELLER_DASHBOARD_PATH
                                }
                                className="nav-user-dropdown-item"
                              >
                                {t("header.secondaryMarket")}
                              </Link>
                            )}

                          {displayData?.campaignOwnerDisplay ? (
                            <Link
                              id="headerDashboard"
                              className="nav-user-dropdown-item"
                              to={AppPaths.DASHBOARD_PATH}
                              onClick={() => setShowSubMenu(false)}
                              onMouseEnter={() => preloadDashboardData()}
                            >
                              {t("header.campaignDashboard", {
                                value: taxonomy?.projectSingular,
                              })}
                            </Link>
                          ) : (
                            ""
                          )}
                          <Link
                            id="headerProfilePage"
                            className="nav-user-dropdown-item"
                            to={`/profile/${localStorage.getItem("slug")}`}
                            onClick={() => setShowSubMenu(false)}
                            onMouseEnter={() =>
                              preloadUserProfileData(
                                localStorage.getItem("slug"),
                                props?.currentUser?.id
                              )
                            }
                          >
                            {t("header.myProfile")}
                          </Link>
                          <Link
                            id="headerSavedCampaign"
                            className="nav-user-dropdown-item"
                            to={AppPaths.SAVED_CAMPAIGN_PATH}
                            onClick={() => setShowSubMenu(false)}
                          >
                            {t("header.savedCampaign", {
                              value: taxonomy?.projectPlural,
                            })}
                          </Link>
                          {isSavedInvestmentEnable.current ? (
                            <Link
                              id="headerSavedInvestment"
                              className="nav-user-dropdown-item"
                              to={AppPaths.SAVED_INVESTMENT_PATH}
                              onClick={() => setShowSubMenu(false)}
                            >
                              {t("header.savedInvestment")}
                            </Link>
                          ) : null}
                          {/* Comment : this below message functionality is not used anywhere */}

                          {/* <Link
                        		id="headerInbox"
                        		className="nav-user-dropdown-item dropdown-item-messages"
                        		to={AppPaths.INBOX_PATH}
                        		onClick={() => setShowSubMenu(false)}
                        	>
                        		{t("header.messages")}
                        	</Link> */}
                          <Link
                            id="headerNotification"
                            className="nav-user-dropdown-item dropdown-item-notifications"
                            to={AppPaths.USER_NOTIFICATION_PATH}
                            onClick={() => {
                              dispatch(actions.postUserNotifications());
                              setShowSubMenu(false);
                            }}
                          >
                            {t("header.notifications")}
                          </Link>
                          <Link
                            id="headerInvite"
                            className="nav-user-dropdown-item"
                            to={AppPaths.INVITE_PATH}
                            onClick={() => setShowSubMenu(false)}
                          >
                            {t("header.inviteFriends")}
                          </Link>
                          {siteSettings?.walletModule === "yes" ? (
                            <Link
                              id="headerProfileSetting"
                              className="nav-user-dropdown-item"
                              to={AppPaths.WALLET_PATH}
                              onClick={() => setShowSubMenu(false)}
                              onMouseEnter={() =>
                                preloadWalletData(
                                  walletSelectedCurrency?.id ||
                                    siteSettings?.currencyId?.id
                                )
                              }
                            >
                              {t("wallet.title")}
                            </Link>
                          ) : null}
                          {siteSettings?.mangoPayModule === "yes" ? (
                            <Link
                              id="headerProfileSetting"
                              className="nav-user-dropdown-item"
                              to={AppPaths.MANGOPAY_PATH}
                              onClick={() => setShowSubMenu(false)}
                              onMouseEnter={() =>
                                preloadMangoPayData(
                                  siteSettings?.mangoPayCurrencyId?.id
                                )
                              }
                            >
                              {t("header.mangoPayWallet")}
                            </Link>
                          ) : null}
                          <Link
                            id="headerProfileSetting"
                            className="nav-user-dropdown-item"
                            to={AppPaths.PROFILE_SETTINGS_PATH}
                            onClick={() => setShowSubMenu(false)}
                          >
                            {t("header.settings")}
                          </Link>

                          <Link
                            id="headerLogout"
                            className="nav-user-dropdown-item"
                            data-test="component-header-logout"
                            to={AppPaths.LOGOUT_PATH}
                            onClick={() => setShowSubMenu(false)}
                          >
                            {t("header.logout")}
                          </Link>
                        </Card.Body>
                      ) : null}
                    </Card.Body>
                  </Card.Body>
                </>
              ) : (
                <>
                  {menus}

                  <Link
                    id="headerMenuBrowsePublic"
                    className="nav-link"
                    to={AppPaths.BROWSE_PATH}
                  >
                    {t("menu.browse")}
                  </Link>
                  <Link
                    id="headerLogin"
                    className="nav-link"
                    to={AppPaths.LOGIN_PATH}
                    data-test="component-header-login"
                  >
                    {t("menu.signIn")}
                  </Link>
                  <Link
                    id="headerSignup"
                    className="nav-link btn btn-primary"
                    to={AppPaths.SIGNUP_PATH}
                  >
                    {t("menu.signUp")}
                  </Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default compose(withTranslation())(Header);

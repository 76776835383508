import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { useParams } from "react-router-dom";

import axios from "../../config/AxiosConfig";
import { getLanguageCode, getLanguageId } from "../../helpers";
import {
  GET_SECONDARY_MARKET_BUY_TRADE_API,
  GET_SECONDARY_MARKET_PAYOUT_API,
  GET_SECONDARY_MARKET_SELL_TRADE_API,
  GET_SECONDARY_MARKET_TRADES_API,
} from "../../store/actions/apiCollections";

const queryParams = {
  params: {
    ...(getLanguageCode() != null && {
      lng: getLanguageCode(),
    }),
  },
};

/* get request data fetcher */
export const secondaryMarketDataFetcher = (url) =>
  axios
    .get(url, queryParams)
    .then((response) => response.data.data.data)
    .catch((error) => {
      throw error;
    });

/* get investment contract data fetcher */
export const getBuyerinvestmentContractFetcher = (url) =>
  axios
    .get(`${url}/secondary-market-buy-contract?language=${getLanguageId()}`)
    .then((response) => response.data.data.data[0]);

/* calculate take away estimate for sell request */
export const calculateTakeAwayFetcher = (url, { arg }) =>
  axios.post(url, arg, queryParams).then((response) => response.data.data.data);

/* calculate take away estimate for sell request */
export const payoutRequestFetcher = async (url, { arg }) => {
  try {
    return await axios
      .post(url + arg.sellTradeId, arg, queryParams)
      .then((response) => response.data.data.data);
  } catch (error) {
    throw error.response.data;
  }
};

/* create new sell request */
export const createSellRequest = (url, { arg }) =>
  axios
    .post(`${url}?language=${getLanguageId()}`, arg)
    .then((response) => response.data.data.data);

/* update existing sell request */
export const updateSellRequest = (url, { arg }) =>
  axios
    .patch(url + arg.tradeId + `?language=${getLanguageId()}`, arg)
    .then((response) => response.data.data.data);

/* create new buy request */
export const createBuyRequest = (url, { arg }) =>
  axios
    .post(url + arg.sellTradeId + `?language=${getLanguageId()}`, arg)
    .then((response) => response.data.data.data)
    .catch((error) => {
      throw error?.response?.data;
    });

/* update existing buy request */
export const updateBuyRequest = (url, { arg }) =>
  axios
    .patch(url + arg.buyTradeId + `?language=${getLanguageId()}`, arg)
    .then((response) => response.data.data.data)
    .catch((error) => {
      throw error?.response?.data;
    });

export const useCreateSellRequest = () => {
  const {
    data: createSellRequestData,
    trigger: createSellRequestTrigger,
    isMutating: createSellRequestMutating,
  } = useSWRMutation(GET_SECONDARY_MARKET_SELL_TRADE_API, createSellRequest);
  return {
    createSellRequestData,
    createSellRequestTrigger,
    createSellRequestMutating,
  };
};

export const useUpdateSellRequest = () => {
  const {
    data: updateSellRequestData,
    trigger: updateSellRequestTrigger,
    isMutating: updateSellRequestMutating,
  } = useSWRMutation(GET_SECONDARY_MARKET_SELL_TRADE_API, updateSellRequest);
  return {
    updateSellRequestData,
    updateSellRequestTrigger,
    updateSellRequestMutating,
  };
};

export const useCreateBuyRequest = () => {
  const {
    data: createBuyRequestData,
    trigger: createBuyRequestTrigger,
    isMutating: createBuyRequestMutating,
    error: createBuyRequestError,
  } = useSWRMutation(GET_SECONDARY_MARKET_BUY_TRADE_API, createBuyRequest);
  return {
    createBuyRequestData,
    createBuyRequestTrigger,
    createBuyRequestMutating,
    createBuyRequestError,
  };
};

export const useUpdateBuyRequest = () => {
  const {
    data: updateBuyRequestData,
    trigger: updateBuyRequestTrigger,
    isMutating: updateBuyRequestMutating,
    error: updateBuyRequestError,
  } = useSWRMutation(GET_SECONDARY_MARKET_BUY_TRADE_API, updateBuyRequest);
  return {
    updateBuyRequestData,
    updateBuyRequestTrigger,
    updateBuyRequestMutating,
    updateBuyRequestError,
  };
};

export const usePayoutRequest = (sellTradeId) => {
  const {
    data: payoutRequestData,
    trigger: payoutRequestTrigger,
    isMutating: payoutRequestMutating,
    error: payoutRequestError,
  } = useSWRMutation(GET_SECONDARY_MARKET_PAYOUT_API, payoutRequestFetcher);
  return {
    payoutRequestData,
    payoutRequestTrigger,
    payoutRequestMutating,
    payoutRequestError,
  };
};

export const useCurrentBuyTradeFetcher = () => {
  /* get sellTradeId from url */
  const { sellTradeId } = useParams();

  const { data, isValidating, mutate } = useSWR(
    sellTradeId ? GET_SECONDARY_MARKET_BUY_TRADE_API + sellTradeId : "",
    secondaryMarketDataFetcher,
    {
      keepPreviousData: false,
      revalidateOnFocus: true,
    }
  );

  return { data, isValidating, mutate };
};

export const usePurchasedTradeFetcher = () => {
  /* get buyTradeId from url */
  const { buyTradeId } = useParams();

  const { data, isValidating, mutate } = useSWR(
    buyTradeId ? GET_SECONDARY_MARKET_TRADES_API + buyTradeId : "",
    secondaryMarketDataFetcher,
    {
      keepPreviousData: false,
      revalidateOnFocus: true,
    }
  );

  return { data, isValidating, mutate };
};

export const useSwitchPurchasedTradeFetcher = (fetchType) => {
  /* get sellTradeId & buyTradeId from url */
  const { sellTradeId, buyTradeId } = useParams();

  const url =
    fetchType === "buy"
      ? sellTradeId
        ? GET_SECONDARY_MARKET_BUY_TRADE_API + sellTradeId
        : ""
      : buyTradeId
      ? GET_SECONDARY_MARKET_TRADES_API + buyTradeId
      : "";

  const { data, isValidating, mutate } = useSWR(
    url,
    secondaryMarketDataFetcher,
    {
      keepPreviousData: false,
      revalidateOnFocus: true,
    }
  );

  return { data, isValidating, mutate };
};

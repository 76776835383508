import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import "./Maintenance.scss";
import EquityMaintenance from "../../assets/images/maintenance_mode.gif";

const Maintenance = () => {
  return (
    <>
      <Container className="maintenance" fluid>
        <Row className="maintenance-row text-center">
          <Col className="maintenance-img-wrap">
            <img className="maintenance-img" src={EquityMaintenance} alt="" />
          </Col>
        </Row>
        <Row className="maintenance-text-wrap">
          <Card.Title as="h1" className="maintenance-heading">
            Sorry for the inconvenience.
          </Card.Title>
        </Row>
        <Row className="maintenance-text-wrap">
          <Card.Title as="h3">
            We’re performing some maintenance at the moment.
          </Card.Title>
        </Row>
        <Row className="maintenance-text-wrap">
          <Card.Title as="h3">we’ll be back up shortly!</Card.Title>
        </Row>
      </Container>
    </>
  );
};

export default Maintenance;

import useSWRMutation from "swr/mutation";

import axios from "../../config/AxiosConfig";
import { EQUITY_API } from "../../store/actions/apiCollections";

export const dashboardStatisticsFetcher = url =>
	axios.get(url).then(response => response.data.data.data);

export const dashboardCampaignFetcher = url =>
	axios.get(url).then(response => response.data.data.data);

export const deleteCampaignFetcher = (url, { arg }) =>
	axios.delete(`${url}${arg.id}`).then(response => response);

export const useDeleteCampaign = () => {
	const {
		data: deleteCampaignData,
		trigger: deleteCampaignTrigger,
		isMutating: deleteCampaignMutating
	} = useSWRMutation(EQUITY_API, deleteCampaignFetcher);

	return {
		deleteCampaignData,
		deleteCampaignTrigger,
		deleteCampaignMutating
	};
};

import * as actionTypes from "./actionTypes";

export const userProfileStart = () => {
	return {
		type: actionTypes.USER_PROFILE_START,
	};
};

export const userProfileFail = (error) => {
	return {
		type: actionTypes.USER_PROFILE_FAIL,
		error,
	};
};
// remove this action
export const getUserProfile = (slug) => {
	return {
		type: actionTypes.GET_PROFILE,
		slug,
	};
};
// remove this action
export const getUserProfileSuccess = (response) => {
	return {
		type: actionTypes.GET_PROFILE_SUCCESS,
		response,
	};
};

export const getFollowers = () => {
	return {
		type: actionTypes.GET_FOLLOWERS,
	};
};

export const getFollowersSuccess = (response) => {
	return {
		type: actionTypes.GET_FOLLOWERS_SUCCESS,
		response,
	};
};

export const getFollowing = () => {
	return {
		type: actionTypes.GET_FOLLOWING,
	};
};

export const getFollowingSuccess = (response) => {
	return {
		type: actionTypes.GET_FOLLOWING_SUCCESS,
		response,
	};
};

export const followUser = (id) => {
	return {
		type: actionTypes.FOLLOW_USER,
		followerUser: id,
	};
};

export const unfollowUser = (id) => {
	return {
		type: actionTypes.UNFOLLOW_USER,
		id,
	};
};

export const verifyEmail = (token) => {
	return {
		type: actionTypes.VERIFY_EMAIL,
		token,
	};
};

export const verifyEmailSuccess = (response) => {
	return {
		type: actionTypes.VERIFY_EMAIL_SUCCESS,
		response,
	};
};

export const getUserActivities = (pageLimit) => {
	return {
		type: actionTypes.GET_USER_ACTIVITIES,
		pageLimit,
	};
};

export const getUserActivitiesSuccess = (response) => {
	return {
		type: actionTypes.GET_USER_ACTIVITIES_SUCCESS,
		response,
	};
};

export const getUserStatistics = () => {
	return {
		type: actionTypes.GET_STATISTICS,
	};
};

export const getUserStatisticsSuccess = (response) => {
	return {
		type: actionTypes.GET_STATISTICS_SUCCESS,
		response,
	};
};

// User Invitation
export const userInvitationSendStart = () => {
	return {
		type: actionTypes.USER_INVITE_START,
	};
};

export const createInvitation = (inviteData) => {
	return {
		type: actionTypes.USER_INVITE,
		inviteData,
	};
};

export const createInvitationSuccess = (response) => {
	return {
		type: actionTypes.USER_INVITE_SUCCESS,
		response,
	};
};

export const createInvitationFail = (response) => {
	return {
		type: actionTypes.USER_INVITE_FAIL,
		response,
	};
};

// User Contact Us
export const userContactUsStart = () => {
	return {
		type: actionTypes.USER_CONTACT_US_START,
	};
};

export const userContactUs = (data) => {
	return {
		type: actionTypes.USER_CONTACT_US,
		data,
	};
};

export const userContactUsSuccess = (response) => {
	return {
		type: actionTypes.USER_CONTACT_US_SUCCESS,
		response,
	};
};

export const userContactUsFail = (response) => {
	return {
		type: actionTypes.USER_CONTACT_US_FAIL,
		response,
	};
};

// Account delete Request

export const userAccountDeleteRequestStart = () => {
	return {
		type: actionTypes.USER_ACCOUNT_DELETE_REQUEST_START,
	};
};

export const userAccountDeleteRequest = (data) => {
	return {
		type: actionTypes.USER_ACCOUNT_DELETE_REQUEST,
		data,
	};
};

export const userAccountDeleteRequestSuccess = (response) => {
	return {
		type: actionTypes.USER_ACCOUNT_DELETE_REQUEST_SUCCESS,
		response,
	};
};

export const userAccountDeleteRequestFail = (response) => {
	return {
		type: actionTypes.USER_ACCOUNT_DELETE_REQUEST_FAIL,
		response,
	};
};

export const userProfileIsSpinning = (response) => {
	return {
		type: actionTypes.IS_SPINNING,
		response,
	};
};

// User Notifications

export const getUserNotifications = (pageLimit) => {
	return {
		type: actionTypes.GET_USER_NOTIFICATIONS,
		pageLimit,
	};
};

export const postUserNotifications = () => {
	return {
		type: actionTypes.POST_USER_NOTIFICATIONS,
	};
};

export const getUserNotificationsSuccess = (response) => {
	return {
		type: actionTypes.GET_USER_NOTIFICATIONS_SUCCESS,
		response,
	};
};

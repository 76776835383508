export const globals = {
	TITLE: `${document.title}`,
	IPADDRESS: "https://api.db-ip.com/v2/free/self",
};

export const RTL_LANGUAGE_LIST = ["ar"];
export const isRTL = RTL_LANGUAGE_LIST.includes(
	localStorage.getItem("i18nextLng")
);

export const TermsConditionContentPageSlug = "terms-conditions";

export const EmptyImage =
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

import { takeEvery, takeLatest } from "redux-saga/effects";
import * as actionType from "../actions/actionTypes";
import * as menuActionType from "../actions/menu/actionTypes";
import * as campaignActionType from "../actions/createCampaign/actionTypes";
import * as contentActionType from "../actions/contentPage/actionTypes";
import * as metaActions from "../actions/meta/actionTypes";
import * as userAction from "../actions/userProfile/actionTypes";
import * as commentsAction from "../actions/comments/actionTypes";
import * as activateAction from "../actions/activateAccount/actionTypes";
import * as revenueAction from "../actions/revenue/actionTypes";
import * as myInvestmentsAction from "../actions/investments/actionTypes";
import * as campaignDashboardAction from "../actions/campaignDashboard/actionTypes";
import * as walletActionType from "../actions/wallet/actionTypes";

import {
	logoutSaga,
	checkAuthTimeoutSaga,
	authLoginSaga,
	adminLoginSaga,
	authCheckStateSaga,
	auth0LoginSaga,
	resendEmailVerificationSaga,
} from "./authSaga";
import {
	userRegistrationSaga,
	userTypeSaga,
	subscribeNewsletterSaga,
} from "./registrationSaga";
import { updateMyPasswordSaga } from "./updateMyPasswordSaga";
import { updateMyProfileSaga } from "./updateMyProfileSaga";
import { getCurrentUserSaga } from "./getCurrentUserSaga";
import {
	getUserEmailPreferencesSaga, // remove this
	createEmailPreferencesSaga, // remove this
	updateEmailPreferencesSaga, // remove this
} from "./emailPreferencesSaga";

import {
	getInvestorProfileSaga,
	getAccreditationContractSaga,
	getSignedAccreditationContractSaga,
	createInvestorProfileSaga,
	updateInvestorProfileSaga,
	getCountriesSaga,
} from "./investorProfileSaga";

import { forgotPasswordSaga, resetPasswordSaga } from "./resetPasswordSaga";
import {
	getSiteSettingsSaga,
	getCampaignSettingsSaga,
	getCurrencySaga,
	getACHCampaignSettingSaga,
	getSocialSettingSaga,
	getEquityShareCertificateSaga,
	getTwitterLoginTokenSaga,
} from "./siteSettingsSaga";
import { getSliders } from "./sliderSaga";
import { getLanguages, getDefaultLanguage } from "./languagesSaga";
import { getRecaptchaSaga } from "./recaptchaSaga";
import { getMenuSaga, getFooterFaqsSaga } from "./menu/menuSaga";
import {
	storeCampaignType,
	storeCategories,
	storeIndustries,
	storeTeamMemberType,
	getTeamMembers,
	createTeamMember,
	deleteTeamMember,
	getTeamMember,
	updateTeamMember,
	getCompanyData,
	createCompanyData,
	updateCompanyData,
	storeCampaignStage,
	storeInvestorRound,
	storeInvestorType,
	createFundRaising,
	getDealType,
	getCampaigns,
	deleteCampaign,
	createCampaignType,
	storeFundraising,
	storeTerms,
	updateFundRaising,
	createTermSaga,
	updateTermSaga,
	getStorySaga,
	createStory,
	updateStory,
	getExtras,
	createExtrasImage,
	getExtrasImages,
	deleteExtrasImage,
	getExtrasImage,
	updateExtrasImage,
	getExtrasDocuments,
	createDocument,
	deleteExtrasDocument,
	getExtrasDocument,
	updateExtrasDocument,
	createExtras,
	updateExtras,
	getFunding,
	createFunding,
	getEquities,
	getEquity,
	createEquitySaga,
	getFaqs,
	createFaqsSaga,
	getAnswerSaga,
	getEquitiyUpdateSaga,
	createEquityUpdateSaga,
	deleteEquityUpdateSaga,
	getEquityUpdateSaga,
	patchEquityUpdateSaga,
	getPublicEquities,
	getPublicSuccessEquities,
	getCampaignDetailPageSaga,
	getCampaignPublicUpdates,
	getCampaignPublicDocuments,
	getPublicFaqs,
	saveCampaign,
	getSavedCampaigns,
	getSavedCampaign,
	createInvestmentSaga,
	getInvestmentContractSaga,
	getSignedContractSaga,
	getOfflineBankSaga,
	getShuftiProKYCSaga,
	createPartyNorthKycSaga, //remove this
	updatePartyNorthKycSaga, //remove this
	uploadPartyDocumentSaga, //remove this
	getNorthKycStatusSaga, //remove this
	updateNorthKycStatusSaga, //remove this
	createOfflinePaymentSaga,
	walletInvestmentCompleteSaga,
	walletInvestmentPaymentSaga,
	getCurrentInvestSaga,
	getCurrentInvestByCampaignidSaga,
	updateInvestmentSaga,
	getReceiptSaga,
	stripePaymentSaga,
	getStripeResponseSaga,
	stripeUpdateSaga,
	getReservationsSaga,
	getInvestmentsSaga,
	getStripeSaga,
	searchCampaigns,
	searchSuccessCampaigns,
	getRepaymentSaga,
	createRepaymentSaga,
	updateRepaymentSaga,
	getSingleRepaymentSaga,
	stripeRepaymentSaga,
	updateRepaymentStripeSaga,
	unSaveCampaign,
	requestPayoutSaga,
	getLeadInvestorsSaga,
	createLeadInvestorSaga,
	deleteLeadInvestorSaga,
	getLeadInvestorSaga,
	updateLeadInvestorSaga,
	getExtrasVideo,
	getExtrasVideos,
	createExtrasVideo,
	deleteExtrasVideo,
	updateExtrasVideo,
	resendTeamMemberEmailSaga,
	uploadContractCopySaga,
	getCompletedCampaigns,
	getUsersWhoSavedCampaignSaga,
	getUserProfileInvestorsSaga,
	getUserProfileCampaignsSaga,
	getPayPalGenerateTokenSaga,
	getPayPalSettingSaga,
	confirmPayPalPaymentSaga,
	getUserStripeConnectionSaga,
	getStripeACHConnectedSaga,
	getStripeACHAllUserAccountsSaga,
	deleteStripeAccountACHSaga,
	getStripeACHPaymentTokenSaga,
	getACHSettingSaga,
	postStripeACHPaymentConfimationSaga,
	postStripeACHRePaymentConfimationSaga,
	checkCampaignACHStatusSaga,
} from "./createCampaignSaga/createCampaignSaga";
import {
	getContentPage,
	getContentPageEquity,
} from "./contentPage/contentPageSaga";
import {
	getMyInvestmentsSaga,
	getInvestmentsChartDataSaga,
	getInvestmentsColumnChartDataSaga,
	getLatestInvestmentsSaga,
	getInvestmentUniqueCurrenciesSaga,
	getMyInvestmentFullDetailSaga,
	getMyInvestmentDocumentDetailSaga,
} from "./investments/investmentsSaga";
import {
	getUserProfileSaga,
	getUserFollowersSaga,
	getUserFollowingSaga,
	verifyEmailSaga,
	getNotificationsSaga,
	followUser,
	unfollowUser,
	getUserStatisticsSaga,
	userInvitationSendSaga,
	userContactUsSendSaga,
	userAccountDeleteRequestSaga,
	getUserNotificationsSaga,
	postUserNotificationsSaga,
} from "./userProfile/userProfileSaga";
import { getMeta } from "./meta/metaSaga";
import {
	getComments,
	approveSubComment,
	createComment,
	getAllComments,
	approveComment,
	declineComment,
	deleteCommentSaga,
	createSubCommentSaga,
	getAllSubCommentsSaga,
	declineSubComment,
} from "./comments/commentsSaga";
import {
	activateAccountSaga,
	twoFactorUserActivateAccountSaga,
	twoFactorLoginSaga,
	getTwoFactorQrCodeSaga,
} from "./activateAccount/activateAccountSaga";
import { getRevenueSaga } from "./revenue/revenueSaga";
import {
	getImagesSaga,
	getVideosSaga,
	getTeamMembersSaga,
	getPublicLeadInvestorSaga,
	getCampaignInvestorsSaga,
} from "./campaignDashboard/campaignDashboardSaga";
import {
	addWalletTopUpSaga,
	createWalletOfflinePaymentSaga,
	getWalletDetailsSaga,
	getWalletTransactionsSaga,
	getWalletPreapprovalDetailsSaga,
	postWalletACHPaymentSaga,
	updateWalletTopupSaga,
	walletStripePaymentInitiateSaga,
	withdrawFromWalletSaga,
} from "./wallet/walletSaga";
import { getTaxonomy } from "./taxonomy";

export function* watchAuth() {
	yield takeLatest(actionType.AUTH_INITIATE_LOGOUT, logoutSaga);
	yield takeLatest(actionType.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga);
	yield takeLatest(actionType.AUTH_USER_LOGIN, authLoginSaga);
	yield takeLatest(actionType.APP_USER_AUTO_LOGIN, authLoginSaga);
	yield takeLatest(actionType.ADMIN_LOGIN, adminLoginSaga);
	yield takeLatest(actionType.AUTH_CHECK_STATE, authCheckStateSaga);
	yield takeEvery(activateAction.ACTIVATE_ACCOUNT, activateAccountSaga);
	yield takeEvery(
		activateAction.AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT,
		twoFactorUserActivateAccountSaga
	);
	yield takeEvery(activateAction.AUTH_TWO_FACTOR_LOGIN, twoFactorLoginSaga);
	yield takeEvery(actionType.AUTH0_DATA_POST, auth0LoginSaga);
	yield takeEvery(
		actionType.RESEND_EMAIL_VERIFICATION_LINK,
		resendEmailVerificationSaga
	);
	yield takeEvery(
		activateAction.GET_TWO_FACTOR_QR_CODE,
		getTwoFactorQrCodeSaga
	);
}
export function* watchUserRegistration() {
	yield takeEvery(actionType.USER_REGISTRATION, userRegistrationSaga);
	yield takeEvery(actionType.USER_TYPE, userTypeSaga);
	yield takeEvery(actionType.SUBSCRIBE_NEWSLETTER, subscribeNewsletterSaga);
}
export function* watchUpdateMyPassword() {
	yield takeEvery(actionType.UPDATE_MY_PASSWORD, updateMyPasswordSaga);
}
export function* watchGetCurrentUser() {
	yield takeEvery(actionType.GET_CURRENT_USER, getCurrentUserSaga);
}
export function* watchUpdateMyProfile() {
	yield takeEvery(actionType.UPDATE_MY_PROFILE, updateMyProfileSaga);
}
//  remove this
export function* watchEmailPreferencers() {
	//  remove this
	yield takeEvery(
		actionType.GET_USER_EMAIL_PREFERNECES,
		getUserEmailPreferencesSaga
	);
	//  remove this
	yield takeEvery(
		actionType.CREATE_EMAIL_PREFERENCES,
		createEmailPreferencesSaga
	);
	//  remove this
	yield takeEvery(
		actionType.UPDATE_EMAIL_PREFERENCES,
		updateEmailPreferencesSaga
	);
}

export function* watchInvestorProfile() {
	// remove this
	yield takeEvery(
		actionType.CREATE_INVESTOR_PROFILE,
		createInvestorProfileSaga
	);
	// remove this
	yield takeEvery(
		actionType.UPDATE_INVESTOR_PROFILE,
		updateInvestorProfileSaga
	);
	yield takeEvery(actionType.GET_COUNTRY, getCountriesSaga);
	yield takeEvery(myInvestmentsAction.GET_MY_INVESTMENTS, getMyInvestmentsSaga);
	yield takeEvery(
		myInvestmentsAction.GET_INVESTMENTS_CHART_DATA,
		getInvestmentsChartDataSaga
	);
	yield takeEvery(
		myInvestmentsAction.GET_INVESTMENTS_COLUMN_CHART_DATA,
		getInvestmentsColumnChartDataSaga
	);
	yield takeEvery(
		myInvestmentsAction.GET_LATEST_INVESTMENTS,
		getLatestInvestmentsSaga
	);
	yield takeEvery(
		myInvestmentsAction.GET_USER_INVESTMENT_UNIQUE_CURRENCIES,
		getInvestmentUniqueCurrenciesSaga
	);
	yield takeEvery(actionType.GET_INVESTOR_PROFILE, getInvestorProfileSaga);
	yield takeEvery(
		actionType.GET_ACCREDIATION_CONTRACT,
		getAccreditationContractSaga
	); //remove this
	yield takeEvery(
		actionType.GET_SIGNED_ACCREDIATION_CONTRACT,
		getSignedAccreditationContractSaga
	);
	yield takeEvery(
		myInvestmentsAction.GET_USER_INVESTMENT_FULL_DETAIL,
		getMyInvestmentFullDetailSaga
	);
	yield takeEvery(
		myInvestmentsAction.GET_USER_INVESTMENT_DOCUMENT_DETAIL,
		getMyInvestmentDocumentDetailSaga
	);
}

export function* watchResetPassword() {
	yield takeEvery(actionType.RESET_PASSWORD, resetPasswordSaga);
	yield takeEvery(actionType.FORGOT_PASSWORD, forgotPasswordSaga);
}

export function* watchSiteSettings() {
	yield takeEvery(actionType.GET_SITE_SETTINGS, getSiteSettingsSaga);
	yield takeEvery(actionType.GET_CAMPAIGN_SETTINGS, getCampaignSettingsSaga);
	yield takeEvery(actionType.GET_CURRENCY, getCurrencySaga);
	yield takeEvery(
		actionType.GET_ACH_CAMPAIGN_SETTING,
		getACHCampaignSettingSaga
	);
	yield takeEvery(actionType.GET_SOCIAL_SETTING, getSocialSettingSaga);
	yield takeEvery(
		actionType.GET_EQUITY_SHARE_CERTIFICATES,
		getEquityShareCertificateSaga
	);
	yield takeEvery(actionType.GET_TWITTER_LOGIN_TOKEN, getTwitterLoginTokenSaga);
}

export function* watchSliders() {
	yield takeEvery(actionType.GET_SLIDERS, getSliders);
}

export function* watchLanguages() {
	yield takeEvery(actionType.GET_LANGUAGES, getLanguages);
	yield takeEvery(actionType.GET_DEFAULT_LANGUAGE, getDefaultLanguage);
	yield takeEvery(actionType.GET_RECAPTCHA, getRecaptchaSaga);
	yield takeEvery(menuActionType.GET_MENU_ITEM, getMenuSaga);
	yield takeEvery(menuActionType.GET_FOOTER_FAQS, getFooterFaqsSaga);
}

export function* watchCampaign() {
	yield takeEvery(
		campaignActionType.UPLOAD_CONTRACT_COPY,
		uploadContractCopySaga
	);
	yield takeEvery(
		campaignActionType.UPDATE_LEAD_INVESTOR,
		updateLeadInvestorSaga
	);
	yield takeEvery(campaignActionType.GET_LEAD_INVESTOR, getLeadInvestorSaga);
	yield takeEvery(
		campaignActionType.DELETE_LEAD_INVESTOR,
		deleteLeadInvestorSaga
	);
	yield takeEvery(campaignActionType.GET_LEAD_INVESTORS, getLeadInvestorsSaga);
	yield takeEvery(
		campaignActionType.CREATE_LEAD_INVESTOR,
		createLeadInvestorSaga
	);
	yield takeEvery(campaignActionType.REQUEST_PAYOUT, requestPayoutSaga);
	yield takeEvery(campaignActionType.GET_TYPE, storeCampaignType);
	yield takeEvery(campaignActionType.SEARCH_CAMPAIGNS, searchCampaigns);
	yield takeEvery(
		campaignActionType.SEARCH_SUCCESS_CAMPAIGNS,
		searchSuccessCampaigns
	);
	yield takeEvery(campaignActionType.GET_CATEGORIES, storeCategories);
	yield takeEvery(campaignActionType.GET_INDUSTRIES, storeIndustries);
	yield takeEvery(campaignActionType.GET_TEAM_MEMBER_TYPE, storeTeamMemberType);
	yield takeEvery(campaignActionType.GET_TEAM_MEMBERS, getTeamMembers);
	yield takeEvery(campaignActionType.CREATE_TEAM_MEMBER, createTeamMember);
	yield takeEvery(campaignActionType.DELETE_TEAM_MEMBER, deleteTeamMember);
	yield takeEvery(campaignActionType.GET_TEAM_MEMBER, getTeamMember);
	yield takeEvery(campaignActionType.UPDATE_TEAM_MEMBER, updateTeamMember);
	yield takeEvery(
		campaignActionType.GET_BASIC_COMPANY_INFORMATION,
		getCompanyData
	);
	yield takeEvery(
		campaignActionType.CREATE_BASIC_COMPANY_INFORMATION,
		createCompanyData
	);
	yield takeEvery(
		campaignActionType.UPDATE_COMPANY_INFORMATION,
		updateCompanyData
	);
	yield takeEvery(campaignActionType.GET_CAMPAIGN_STAGE, storeCampaignStage);
	yield takeEvery(campaignActionType.GET_INVESTING_ROUND, storeInvestorRound);
	yield takeEvery(campaignActionType.GET_INVESTOR, storeInvestorType);
	yield takeEvery(campaignActionType.GET_DEAL_TYPES, getDealType);
	yield takeEvery(campaignActionType.CREATE_FUNDRAISING, createFundRaising);
	yield takeEvery(campaignActionType.GET_CAMPAIGNS, getCampaigns);
	yield takeEvery(campaignActionType.DELETE_CAMPAIGN, deleteCampaign);
	yield takeEvery(campaignActionType.CREATE_CAMPAIGN_TYPE, createCampaignType);
	yield takeEvery(campaignActionType.GET_FUNDRAISING, storeFundraising);
	yield takeEvery(campaignActionType.GET_TERM, storeTerms);
	yield takeEvery(campaignActionType.UPDATE_FUNDRAISING, updateFundRaising);
	yield takeEvery(campaignActionType.CREATE_TERM, createTermSaga);
	yield takeEvery(campaignActionType.UPDATE_TERM, updateTermSaga);
	yield takeEvery(campaignActionType.GET_STORY, getStorySaga);
	yield takeEvery(campaignActionType.CREATE_STORY, createStory);
	yield takeEvery(campaignActionType.UPDATE_STORY, updateStory);
	yield takeEvery(campaignActionType.GET_EXTRAS, getExtras);
	yield takeEvery(campaignActionType.CREATE_EXTRAS_IMAGE, createExtrasImage);
	yield takeEvery(campaignActionType.GET_EXTRAS_IMAGES, getExtrasImages);
	yield takeEvery(campaignActionType.DELETE_EXTRAS_IMAGE, deleteExtrasImage);
	yield takeEvery(campaignActionType.GET_EXTRAS_IMAGE, getExtrasImage);
	yield takeEvery(campaignActionType.UPDATE_EXTRAS_IMAGE, updateExtrasImage);
	yield takeEvery(campaignActionType.GET_EXTRAS_DOCUMENTS, getExtrasDocuments);
	yield takeEvery(campaignActionType.CREATE_EXTRAS_DOCUMENT, createDocument);
	yield takeEvery(
		campaignActionType.DELETE_EXTRAS_DOCUMENT,
		deleteExtrasDocument
	);
	yield takeEvery(campaignActionType.GET_EXTRAS_DOCUMENT, getExtrasDocument);
	yield takeEvery(
		campaignActionType.UPDATE_EXTRAS_DOCUMENT,
		updateExtrasDocument
	);
	yield takeEvery(campaignActionType.GET_EXTRAS_VIDEO, getExtrasVideo);
	yield takeEvery(campaignActionType.GET_EXTRAS_VIDEOS, getExtrasVideos);
	yield takeEvery(campaignActionType.CREATE_EXTRAS_VIDEO, createExtrasVideo);
	yield takeEvery(campaignActionType.DELETE_EXTRAS_VIDEO, deleteExtrasVideo);
	yield takeEvery(campaignActionType.UPDATE_EXTRAS_VIDEO, updateExtrasVideo);
	yield takeEvery(campaignActionType.CREATE_EXTRAS, createExtras);
	yield takeEvery(campaignActionType.UPDATE_EXTRAS, updateExtras);
	yield takeEvery(campaignActionType.GET_FUNDING, getFunding);
	yield takeEvery(campaignActionType.CREATE_FUNDING, createFunding);
	yield takeEvery(campaignActionType.GET_EQUTY, getEquity);
	yield takeEvery(campaignActionType.GET_EQUITIES, getEquities);
	yield takeEvery(campaignActionType.CREATE_EQUITY, createEquitySaga);
	yield takeEvery(campaignActionType.GET_FAQS, getFaqs);
	yield takeEvery(campaignActionType.CREATE_FAQs, createFaqsSaga);
	yield takeEvery(campaignActionType.GET_ANSWER, getAnswerSaga);
	yield takeEvery(campaignActionType.GET_EQUITY_UPDATES, getEquitiyUpdateSaga);
	yield takeEvery(
		campaignActionType.CREATE_EQUITY_UPDATE,
		createEquityUpdateSaga
	);
	yield takeEvery(
		campaignActionType.DELETE_EQUITY_UPDATE,
		deleteEquityUpdateSaga
	);
	yield takeEvery(campaignActionType.GET_EQUITY_UPDATE, getEquityUpdateSaga);
	yield takeEvery(
		campaignActionType.PATCH_EQUITY_UPDATE,
		patchEquityUpdateSaga
	);
	yield takeEvery(campaignActionType.GET_PUBLIC_EQUITIES, getPublicEquities);
	yield takeEvery(
		campaignActionType.GET_PUBLIC_SUCCESS_EQUITIES,
		getPublicSuccessEquities
	);
	yield takeEvery(
		campaignActionType.GET_CAMPAIGN_PAGE,
		getCampaignDetailPageSaga
	);
	yield takeEvery(
		campaignActionType.GET_PUBLIC_UPDATES,
		getCampaignPublicUpdates
	);
	yield takeEvery(
		campaignActionType.GET_PUBLIC_DOCUMENTS,
		getCampaignPublicDocuments
	);
	yield takeEvery(campaignActionType.GET_PUBLIC_FAQS, getPublicFaqs);
	yield takeEvery(campaignActionType.SAVE_CAMPAIGN, saveCampaign);
	yield takeEvery(campaignActionType.GET_SAVE_CAMPAIGN, getSavedCampaigns);
	yield takeEvery(campaignActionType.GET_SAVED_CAMPAIGN, getSavedCampaign);
	yield takeEvery(campaignActionType.CREATE_INVESTMENT, createInvestmentSaga);
	yield takeEvery(
		campaignActionType.GET_INVESTMENT_CONTRACT,
		getInvestmentContractSaga
	);
	yield takeEvery(
		campaignActionType.GET_SIGNED_CONTRACT,
		getSignedContractSaga
	);
	yield takeEvery(campaignActionType.GET_OFFLINE, getOfflineBankSaga);
	yield takeEvery(campaignActionType.GET_SHUFTI_PRO_KYC, getShuftiProKYCSaga);
	// remove this
	yield takeEvery(
		campaignActionType.CREATE_PARTY_NORTH_KYC,
		createPartyNorthKycSaga
	);
	// remove this
	yield takeEvery(
		campaignActionType.UPDATE_PARTY_NORTH_KYC,
		updatePartyNorthKycSaga
	);
	// remove this
	yield takeEvery(
		campaignActionType.UPLOAD_PARTY_DOCUMENT,
		uploadPartyDocumentSaga
	);
	// remove this
	yield takeEvery(
		campaignActionType.GET_NORTH_KYC_STATUS,
		getNorthKycStatusSaga
	);
	// remove this
	yield takeEvery(
		campaignActionType.UPDATE_NORTH_KYC_STATUS,
		updateNorthKycStatusSaga
	);

	yield takeEvery(
		campaignActionType.CREATE_OFFLINE_PAYMENT,
		createOfflinePaymentSaga
	);
	yield takeEvery(
		campaignActionType.WALLET_INVESTMENT_COMPLETE,
		walletInvestmentCompleteSaga
	);
	yield takeEvery(
		campaignActionType.WALLET_INVESTMENT_PAYMENT,
		walletInvestmentPaymentSaga
	);
	yield takeEvery(campaignActionType.GET_CURRENT_INVEST, getCurrentInvestSaga);
	yield takeEvery(
		campaignActionType.GET_CURRENT_INVEST_BY_CAMPAIGNID,
		getCurrentInvestByCampaignidSaga
	);
	yield takeEvery(campaignActionType.UPDATE_INVESTMENT, updateInvestmentSaga);
	yield takeEvery(campaignActionType.GET_RECIEPT, getReceiptSaga);
	yield takeEvery(campaignActionType.STRIPE_PAYMENT, stripePaymentSaga);
	yield takeEvery(
		campaignActionType.GET_STRIPE_RESPONSE,
		getStripeResponseSaga
	);
	yield takeEvery(campaignActionType.GET_PAYPAL_SETTING, getPayPalSettingSaga);
	yield takeEvery(
		campaignActionType.GET_PAYPAL_CONFIRM_PAYMENT,
		confirmPayPalPaymentSaga
	);
	yield takeEvery(campaignActionType.STRIPE_UPDATE, stripeUpdateSaga);
	yield takeEvery(
		campaignActionType.GET_TOTAL_RESERVARTIONS,
		getReservationsSaga
	);
	yield takeEvery(campaignActionType.GET_INVESTMENTS, getInvestmentsSaga);
	yield takeEvery(campaignActionType.GET_STRIPE, getStripeSaga);
	yield takeEvery(campaignActionType.GET_REPAYMENT, getRepaymentSaga);
	yield takeEvery(campaignActionType.CREATE_REPAYMENT, createRepaymentSaga);
	yield takeEvery(campaignActionType.UPDATE_REPAYMENT, updateRepaymentSaga);
	yield takeEvery(
		campaignActionType.GET_SINGLE_REPAYMENT,
		getSingleRepaymentSaga
	);
	yield takeEvery(campaignActionType.REPAYMENT_STRIPE, stripeRepaymentSaga);
	yield takeEvery(
		campaignActionType.UPDATE_REPAYMENT_STRIPE,
		updateRepaymentStripeSaga
	);
	yield takeEvery(campaignActionType.UNSAVE_CAMPAIGN, unSaveCampaign);
	yield takeEvery(campaignActionType.RESEND_EMAIL, resendTeamMemberEmailSaga);
	yield takeEvery(
		campaignActionType.GET_CAMPAIGN_COMPLETED,
		getCompletedCampaigns
	);
	yield takeEvery(
		campaignActionType.GET_FOLLOWERS_CAMPAIGN,
		getUsersWhoSavedCampaignSaga
	);
	// remove this watcher
	yield takeEvery(
		campaignActionType.GET_USER_PROFILE_INVESTORS_LIST,
		getUserProfileInvestorsSaga
	);
	// remove this watcher
	yield takeEvery(
		campaignActionType.GET_USER_PROFILE_CAMPAIGNS_LIST,
		getUserProfileCampaignsSaga
	);
	yield takeEvery(
		campaignActionType.GET_PAYPAL_GENERATE_TOKEN,
		getPayPalGenerateTokenSaga
	);
	yield takeEvery(
		campaignActionType.GET_STRIPE_ACH_CONNECTION_URL,
		getUserStripeConnectionSaga
	);
	yield takeEvery(
		campaignActionType.POST_STRIPE_ACH_CONNECT,
		getStripeACHConnectedSaga
	);
	yield takeEvery(
		campaignActionType.GET_STRIPE_ACH_ALL_USER_ACCOUNTS,
		getStripeACHAllUserAccountsSaga
	);
	yield takeEvery(
		campaignActionType.STRIPE_ACH_DISCONNECT_ACCOUNT,
		deleteStripeAccountACHSaga
	);
	yield takeEvery(
		campaignActionType.GET_STRIPE_ACH_PAYMENT_TOKEN,
		getStripeACHPaymentTokenSaga
	);

	yield takeEvery(campaignActionType.GET_ACH_SETTING, getACHSettingSaga);
	yield takeEvery(
		campaignActionType.POST_STRIPE_ACH_PAYMENT_CONFIMATION,
		postStripeACHPaymentConfimationSaga
	);
	yield takeEvery(
		campaignActionType.POST_STRIPE_ACH_RE_PAYMENT_CONFIMATION,
		postStripeACHRePaymentConfimationSaga
	);
	yield takeEvery(
		campaignActionType.CHECK_CAMPAIGN_STRIPE_ACH_RE_PAYMENT,
		checkCampaignACHStatusSaga
	);
}

export function* watchUserProfile() {
	// remove this below first watcher only
	yield takeEvery(userAction.GET_PROFILE, getUserProfileSaga);
	yield takeEvery(userAction.GET_FOLLOWING, getUserFollowingSaga);
	yield takeEvery(userAction.GET_FOLLOWERS, getUserFollowersSaga);
	yield takeEvery(userAction.VERIFY_EMAIL, verifyEmailSaga);
	yield takeEvery(userAction.GET_USER_ACTIVITIES, getNotificationsSaga);
	yield takeEvery(userAction.FOLLOW_USER, followUser);
	yield takeEvery(userAction.UNFOLLOW_USER, unfollowUser);
	yield takeEvery(userAction.GET_STATISTICS, getUserStatisticsSaga);
	yield takeEvery(userAction.USER_INVITE, userInvitationSendSaga);
	yield takeEvery(userAction.USER_CONTACT_US, userContactUsSendSaga);
	yield takeEvery(
		userAction.USER_ACCOUNT_DELETE_REQUEST,
		userAccountDeleteRequestSaga
	);
	yield takeEvery(userAction.GET_USER_NOTIFICATIONS, getUserNotificationsSaga);
	yield takeEvery(
		userAction.POST_USER_NOTIFICATIONS,
		postUserNotificationsSaga
	);
}

export function* watchContentPage() {
	yield takeEvery(contentActionType.GET_CONTENT_PAGE, getContentPage);
	yield takeEvery(
		contentActionType.GET_CONTENT_PAGE_EQUITY,
		getContentPageEquity
	);
}

export function* watchMeta() {
	yield takeEvery(metaActions.GET_META, getMeta);
	yield takeEvery(revenueAction.GET_REVENUE, getRevenueSaga);
}

export function* watchComments() {
	yield takeEvery(commentsAction.GET_COMMENTS, getComments);
	yield takeEvery(commentsAction.CREATE_COMMENT, createComment);
	yield takeEvery(commentsAction.GET_ALL_COMMENTS, getAllComments);
	yield takeEvery(commentsAction.APPROVE_COMMENT, approveComment);
	yield takeEvery(commentsAction.DECLINE_COMMENT, declineComment);
	yield takeEvery(commentsAction.DELETE_COMMENT, deleteCommentSaga);
	yield takeEvery(commentsAction.CREATE_SUB_COMMENT, createSubCommentSaga);
	yield takeEvery(commentsAction.GET_ALL_SUB_COMMENTS, getAllSubCommentsSaga);
	yield takeEvery(commentsAction.APPROVE_SUB_COMMENT, approveSubComment);
	yield takeEvery(commentsAction.DECLINE_SUB_COMMENT, declineSubComment);
}

export function* watchCampaignDashboard() {
	yield takeEvery(campaignDashboardAction.GALLERY_GET_IMAGES, getImagesSaga);
	yield takeEvery(campaignDashboardAction.GALLERY_GET_VIDEOS, getVideosSaga);
	yield takeEvery(
		campaignDashboardAction.GET_CAMPAIGN_INVESTORS,
		getCampaignInvestorsSaga
	);
	yield takeEvery(campaignDashboardAction.GET_TEAM_MEMBERS, getTeamMembersSaga);
	yield takeEvery(
		campaignDashboardAction.GET_LEAD_INVESTOR,
		getPublicLeadInvestorSaga
	);
}

export function* watchWallet() {
	yield takeEvery(walletActionType.ADD_WALLET_TOP_UP, addWalletTopUpSaga);
	yield takeEvery(
		walletActionType.CREATE_WALLET_OFFLINE_PAYMENT,
		createWalletOfflinePaymentSaga
	);
	yield takeEvery(
		walletActionType.GET_WALLET_TRANSACTIONS,
		getWalletTransactionsSaga
	);
	yield takeEvery(walletActionType.GET_WALLET_DETAILS, getWalletDetailsSaga);
	yield takeEvery(
		walletActionType.GET_WALLET_PREAPPROVAL_DETAILS,
		getWalletPreapprovalDetailsSaga
	);
	yield takeEvery(
		walletActionType.POST_WALLET_ACH_PAYMENT,
		postWalletACHPaymentSaga
	);
	yield takeEvery(walletActionType.UPDATE_WALLET_TOPUP, updateWalletTopupSaga);
	yield takeEvery(
		walletActionType.WALLET_STRIPE_PAYMENT_INITIATE,
		walletStripePaymentInitiateSaga
	);
	yield takeEvery(
		walletActionType.WITHDRAW_FROM_WALLET,
		withdrawFromWalletSaga
	);
}

export function* watchTaxonomy() {
	yield takeEvery(actionType.GET_TAXONOMY, getTaxonomy);
}

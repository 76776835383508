export const SSN_FORMAT = /^(\d{3}-?\d{2}-?\d{4})$/;

export const EMAIL_FORMAT =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_FORMAT = /^[0-9]{8,13}$/; // Limit 8 to 13 digits

export const PHONE_MASK = /^\/|\/$/g;

export const ONLY_NUMBER = /^[0-9]*$/;

export const ONLY_ALPHABET = /^([a-zA-Z]+?)([-\s'][a-zA-Z]+)*?$/;

export const ALPHABET_WITH_BRACKETS = /^[-a-zA-Z-()]+(\s+[-a-zA-Z-()]+)*$/;

/* eslint-disable no-unused-vars */
import { put, delay, call } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
	LOGIN_API,
	SOCIAL_LOGIN,
	RESEND_EMAIL_VERIFICATION_API,
	APP_AUTO_LOGIN,
} from "../actions/apiCollections";
import {
	logoutSucceed,
	logout,
	authStart,
	authSuccess,
	adminLoginSuccess,
	authFail,
	checkAuthTimeout,
	setAlert,
	resendEmailVerificationSuccess,
	resendEmailVerificationFail,
	twoFactorAuthSuccess,
	isSpinning,
} from "../actions/index";
import { getCurrentUserSaga } from "./getCurrentUserSaga";
import { getCurrentUserSuccess } from "../actions/index";
import {
	getCampaignSettingsSaga,
	getSiteSettingsSaga,
} from "./siteSettingsSaga";
import { clearNorthKycFiled } from "../actions/index";
import { clearInvestmentsData } from "../actions/investments/investmentsActions";
import {
	getLanguageCode,
	removeAppUserId,
	setAppUserId,
} from "../../helpers/helpers";

export function* logoutSaga(action) {
	yield put(authStart());
	yield removeAppUserId(); // removed when logged in using app webview	
	yield localStorage.removeItem("token");
	yield localStorage.removeItem("expirationDate");
	yield localStorage.removeItem("userType");
	yield localStorage.removeItem("email");
	yield localStorage.removeItem("slug");
	yield localStorage.setItem("persist:root", {});
	yield put(logoutSucceed());
	yield put(getCurrentUserSuccess({}));
	yield put(clearInvestmentsData());
	yield put(clearNorthKycFiled("northKycPartyWarnings"));
}

export function* checkAuthTimeoutSaga(action) {
	yield delay(action.expirationTime * 6000);
	yield put(logout());
	yield call(logoutSaga);
}

export function* authLoginSaga(action) {
	yield put(authStart());
	yield put(isSpinning(true));

	const search = window.location.search;
	const params = new URLSearchParams(search);
	const appUserIdQueryParam = params.get("appUserId"); // get on app webview

	let authData;
	if (!appUserIdQueryParam) {
		// if not then normal login
		authData = {
			email: action.email,
			password: action.password,
			createdAt: Date.now(),
		};
	} else {
		// else consider as app web view login
		authData = {
			id: action.appUserId,
		};
	}
	try {
		const response = yield axios.post(
			appUserIdQueryParam ? `${APP_AUTO_LOGIN}` : `${LOGIN_API}`,
			authData,
			{
				params: {
					...(getLanguageCode() !== null && {
						lng: getLanguageCode(),
					}),
					active: 1,
				},
			}
		);

		if (response.data.message.enableTwoFactorAuth === "yes") {
			yield put(isSpinning(false));
			yield put(
				twoFactorAuthSuccess(response.data.message.enableTwoFactorAuth)
			);

			yield localStorage.setItem(
				"twoFactorLoginStatus",
				response.data.message.enableTwoFactorAuth
			);
			yield localStorage.setItem(
				"twoFactorLoginQRLink",
				response.data.message.authData.url
			);
			yield localStorage.setItem(
				"twoFactorLoginUserEmail",
				response.data.message.authData.email
			);
		} else {
			const expirationTokenTime = 10800;
			const expirationDate = yield new Date(
				new Date().getTime() + expirationTokenTime * 1000
			);
			if (appUserIdQueryParam) yield setAppUserId(appUserIdQueryParam); // setting mobile app logged in user id
			yield localStorage.setItem("token", response.data.token);
			yield localStorage.setItem("expirationDate", expirationDate);
			yield localStorage.setItem("email", action.email);
			yield localStorage.setItem("slug", response.data.data.user.slug);
			yield localStorage.setItem("userType", response.data.data.user.userType);
			yield localStorage.setItem(
				"twoFactorLoginStatus",
				response.data.message.enableTwoFactorAuth
			);
			yield call(getCurrentUserSaga);
			yield call(getCampaignSettingsSaga);
			yield call(getSiteSettingsSaga);
			yield put(authSuccess(response.data.token));
			yield put(checkAuthTimeout(expirationTokenTime));
			yield delay(500);
			yield put(setAlert(true));
			yield delay(8000);
			yield put(setAlert(false));
			yield put(isSpinning(false));
		}
	} catch (error) {
		if (appUserIdQueryParam) console.log("app-auto-login-error"); // app web view login error
		yield put(isSpinning(false));
		yield put(authFail(error.response.data.message));
	}
}

export function* adminLoginSaga(action) {
	try {
		const expirationTokenTime = 10800;
		const expirationDate = yield new Date(
			new Date().getTime() + expirationTokenTime * 1000
		);
		yield localStorage.setItem("token", action.credentials.token);
		yield localStorage.setItem("expirationDate", expirationDate);
		yield localStorage.setItem("email", action.credentials.email);
		yield localStorage.setItem("id", action.credentials.id);
		yield localStorage.setItem("slug", action.credentials.slug);
		yield localStorage.setItem("userType", action.credentials.userType);
		yield call(getCurrentUserSaga);
		yield put(authSuccess(action.credentials.token));
		yield put(checkAuthTimeout(expirationTokenTime));
		yield put(adminLoginSuccess(action.credentials));
	} catch (error) {
		yield put(authFail(error.response.data.message));
	}
}

export function* auth0LoginSaga(action) {
	yield put(authStart());
	try {
		const response = yield axios.post(`${SOCIAL_LOGIN}`, action.data, {
			params: {
				...(getLanguageCode() !== null && {
					lng: getLanguageCode(),
				}),
			},
		});
		const expirationTokenTime = 10800;
		const expirationDate = yield new Date(
			new Date().getTime() + expirationTokenTime * 1000
		);
		yield localStorage.setItem("token", response.data.token);
		yield localStorage.setItem("expirationDate", expirationDate);
		yield localStorage.setItem("email", response.data.data.user.email);
		yield localStorage.setItem("slug", response.data.data.user.slug);
		yield localStorage.setItem("userType", response.data.data.user.userType);
		yield put(authSuccess(response.data.token));
		yield put(checkAuthTimeout(expirationTokenTime));
		yield call(getCurrentUserSaga);
		yield call(getCampaignSettingsSaga);
		yield call(getSiteSettingsSaga);

		yield delay(500);
		yield put(setAlert(true));
		yield delay(8000);
		yield put(setAlert(false));
	} catch (error) {
		yield put(authFail(error.response.data.message));
	}
}

export function* authCheckStateSaga(action) {
	yield put(authStart());
	const token = yield localStorage.getItem("token");
	if (!token) {
		yield call(logoutSaga);
	} else {
		const expirationDate = yield new Date(
			localStorage.getItem("expirationDate")
		);
		if (expirationDate <= new Date()) {
			yield call(logoutSaga);
		} else {
			yield put(authSuccess(token));
			yield put(
				checkAuthTimeout(
					(expirationDate.getTime() - new Date().getTime()) / 1000
				)
			);
		}
	}
}

export function* resendEmailVerificationSaga(action) {
	yield put(authStart());
	try {
		const response = yield axios.post(
			`${RESEND_EMAIL_VERIFICATION_API}`,
			{
				email: action.email,
			},
			{
				params: {
					...(getLanguageCode() !== null && {
						lng: getLanguageCode(),
					}),
				},
			}
		);
		yield put(resendEmailVerificationSuccess(response.data.data.data));
	} catch (error) {
		yield put(resendEmailVerificationFail(error.response.data.message));
	}
}

import * as actionTypes from "./actionTypes";

/**
 * Returns AUTH_REGISTRATION_START action
 * @function authRegistrationStart
 */
export const authRegistrationStart = (isCleaning) => {
	return {
		type: actionTypes.AUTH_REGISTRATION_START,
		isCleaning,
	};
};

/**
 * Returns authRegistrationSuccess function in reducer
 * @function authRegistrationSuccess
 * @param {string} token - authRegistrationSuccess.
 * @returns {function} - Reducer function.
 */
export const authRegistrationSuccess = (successMessage) => {
	return {
		type: actionTypes.AUTH_REGISTRATION_SUCCESS,
		successMessage: successMessage,
	};
};

/**
 * Returns authRegistrationFail function in reducer
 * @function authRegistrationFail
 * @param {string} error - authRegistrationFail.
 * @returns {function} - Reducer function.
 */
export const authRegistrationFail = (errorMessage) => {
	return {
		type: actionTypes.AUTH_REGISTRATION_FAIL,
		errorMessage: errorMessage,
	};
};

/**
 * Returns Redux Saga function that dispatches USER_REGISTRATION action
 * and (conditionally) USER_REGISTRATION action
 * @function userRegistration
 * @param {string} userData - userRegistration.
 * @returns {function} - Redux Saga function.
 */
export const userRegistration = (userData) => {
	return {
		type: actionTypes.USER_REGISTRATION,
		userData: userData,
	};
};

export const userTypes = (filterData) => {
	return {
		type: actionTypes.USER_TYPE,
		filterData: filterData,
	};
};

export const userTypesSuccess = (typesData) => {
	return {
		type: actionTypes.USER_TYPE_SUCCESS,
		typesData: typesData,
	};
};

export const clearSubscribeNewsletterMessage = () => {
	return {
		type: actionTypes.CLEAR_SUBSCRIBE_NEWSLETTER,
	};
};

export const subscribeNewsletter = (postData) => {
	return {
		type: actionTypes.SUBSCRIBE_NEWSLETTER,
		postData: postData,
	};
};

export const subscribeNewsletterSuccess = (successData) => {
	return {
		type: actionTypes.SUBSCRIBE_NEWSLETTER_SUCCESS,
		successData: successData,
	};
};

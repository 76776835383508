import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import axios from "../../../config/AxiosConfig";
import { getLanguageCode } from "../../../helpers";
import {
	USER_EMAIL_PREFERNECES_API,
	USER_EMAIL_PREFERNECES_API_UPDATE
} from "../../../store/actions/apiCollections";
const queryParams = {
	params: {
		...(getLanguageCode() != null && {
			lng: getLanguageCode()
		})
	}
};
/* Fetchers */
export const getUserEmailPreferenceFetcher = url =>
	axios.get(url, queryParams).then(response => response.data.data.data);

export const updateUserEmailPreferenceFetcher = (url, { arg }) =>
	axios.patch(url, arg, queryParams).then(response => response.data);

/* Custom hooks */
export function useGetUserEmailPreference() {
	const {
		data: emailPreferenceData,
		isLoading: emailPreferenceLoading,
		mutate: emailPreferenceMutate
	} = useSWR(USER_EMAIL_PREFERNECES_API, getUserEmailPreferenceFetcher, {
		keepPreviousData: true
	});
	return {
		emailPreferenceData,
		emailPreferenceLoading,
		emailPreferenceMutate
	};
}

export function useUpdateUserEmailPreference(emailPrefId) {
	const {
		data: updatedEmailPreferenceData,
		trigger: updatedEmailPreferenceTrigger,
		isMutating: updatedEmailPreferenceMutating
	} = useSWRMutation(
		emailPrefId ? `${USER_EMAIL_PREFERNECES_API_UPDATE}${emailPrefId}` : "",
		updateUserEmailPreferenceFetcher
	);
	return {
		updatedEmailPreferenceData,
		updatedEmailPreferenceTrigger,
		updatedEmailPreferenceMutating
	};
}

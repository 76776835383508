import * as actionTypes from "./actionTypes";

export const setPaymentReceipt = (receiptData, gatewayType) => {
	return {
		type: actionTypes.SET_PAYMENT_RECEIPT,
		receiptData,
		gatewayType,
	};
};

export const setPreapprovalDetails = (preapprovalDetails) => {
	return {
		type: actionTypes.SET_PREAPPROVAL_DETAILS,
		preapprovalDetails,
	};
};

export const clearPreapprovalDetails = () => {
	return {
		type: actionTypes.CLEAR_PREAPPROVAL_DETAILS,
	};
};

export const setStripePaymentInitialResponse = (stripeData) => {
	return {
		type: actionTypes.SET_STRIPE_PAYMENT_INITIAL_RESPONSE,
		stripeData,
	};
};

export const resetPaymentData = (fieldName, value) => {
	return {
		type: actionTypes.RESET_PAYMENT_DATA,
		fieldName,
		value,
	};
};

export const setPaymentErrors = (error) => {
	return {
		type: actionTypes.SET_PAYMENT_ERRORS,
		error,
	};
};

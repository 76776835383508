import { put, call, delay } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import { UPDATE_MY_PROFILE_API } from "../actions/apiCollections";
import {
	updateMyProfilFail,
	updateMyProfileStart,
	updateMyProfileSuccess
} from "../actions/updateMyProfileAction";
import { getCurrentUserSaga } from "./getCurrentUserSaga";
import { getLanguageCode } from "../../helpers/helpers";

export function* updateMyProfileSaga(action) {
	try {
		const response = yield axios.patch(
			`${UPDATE_MY_PROFILE_API}?lng=${getLanguageCode()}`,
			action.userData
		);
		yield put(updateMyProfileSuccess(response.data.status));
		yield call(getCurrentUserSaga);
		yield delay(500);
		yield put(updateMyProfileStart());
	} catch (error) {
		yield put(updateMyProfilFail(error.response));
	}
}
